import React, {useState,useEffect} from 'react'

import TableCell from '@material-ui/core/TableCell';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogActions, makeStyles } from '@material-ui/core';
import {Table,TableBody,TableHead,TableRow,TablePagination,Box,IconButton,Button,FormControlLabel,Switch,TextField,Grid,Tooltip} from '@material-ui/core';
import CardBox from 'components/CardBox';
import MsgPopover from 'components/MsgPopover';
import {SwapHoriz,PhonelinkLock,LockOutlined} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon  from '@material-ui/icons/Clear';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import VideoLabel from '@material-ui/icons/VideoLabel'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings'
import DeleteSweep from '@material-ui/icons/DeleteSweep';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import StarIcon from '@material-ui/icons/Star';  
import CheckCircleIcon from '@material-ui/icons/CheckCircle';  

import SearchBox from './SearchBox';
import LocalParkingIcon from '@material-ui/icons/LocalParking'
import { Accessibility } from '@material-ui/icons';
import ToolTipIcon from 'components/ToolTipIcon';

const useStyles = makeStyles((theme) =>({
    root: {
        fontFamily:'Roboto'
    },
    header : {
        background : theme.palette.secondary.main,
        color:'white'
    },
    newItem : {
        backgroundColor:'rgb(2, 207, 137)',
        padding:'3px 10px',
        fontWeight:500,
        color:'rgb(255, 0, 0)'
    }
}))

const EZSwitch = (props) =>{
    const [active, setActive] = useState(props.active);
    return (
      <Switch
        color="primary"
        name="enabled"
        checked={active}
        onChange={(e) => props.changeStatus(props.id, e.target.checked, (status)=>{
            setActive(status)
        })}
      />
    );
}

const CustomTableCell = ({key, value, style}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const maxLength = 60; 
    const isTruncated = value.length > maxLength;
    const displayValue = isTruncated ? `${value.substring(0, maxLength)}...` : value;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <TableCell key={key} classes={{ head:classes.header,root:classes.root }} style={style} size={"small"}>
                {displayValue}
                {isTruncated && (
                    <Button style={{textDecoration: 'underline'}} size="small" onClick={handleOpen}>
                        More
                    </Button>
                )}
            </TableCell>
            {isTruncated &&
            <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{ style:{maxHeight: '75vh', maxWidth:'45vw'} }}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>
                    <p>{value}</p>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Close
                    </Button>
                    </Box>
                </DialogActions>
            </Dialog>}
        </>
    );
}

const TableList =(props)=> {
    console.log('tableList-props',props)
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tBody,setTBody] = useState(props.table.body);

    useEffect(()=>{
        setTBody(props.table.body); 
    },[props.table])
    
    //filter table according to search
    const handleSearch = (searchValue)=>{

        if(searchValue){
            var filter = [];
            props.table.body.map(o=>{
                for(var key in o){
                    if(typeof o[key].value == 'string'){
                        if(o[key].hidden===false && o[key].value !=null && !Array.isArray(o[key].value) && o[key].value?.toLowerCase().includes(searchValue.toLowerCase()) ){
                            filter.push(o);
                            break;
                        }
                    }
                    else if(typeof o[key].value == 'object'){
                        if(key !== 'images' && o[key].hidden===false && o[key].value != null && o[key].value && !Array.isArray(o[key].value.title) && o[key].value?.title.toLowerCase().includes(searchValue.toLowerCase()) ){
                            filter.push(o);
                            break;
                        }
                    }
                }
             });
            setTBody(filter);
        }else{
            setTBody(props.table.body);
        }
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    const getHeaders = () => {
        return (
        <TableHead >
            <TableRow key={props.tableHeading.replace(' ','')+'table-head'}>
                {props.table.headers.map((n,index) => {
                    return <TableCell key={n+index} classes={{ head:classes.header,root:classes.root }} size="small">{n}</TableCell>
                })}
            </TableRow>
        </TableHead>
        )
    }
    const getTableBody = () =>{
        return(
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? tBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tBody
                        ).map((n,index) => {

                    return (
                        <TableRow key={props.tableHeading.replace(' ','')+'table-row-'+index}>
                            {Object.keys(n).filter(key=> n[key].hidden==false).map((item, i) => (
                                (item === 'images' ?
                                <TableCell key={item+i}>
                                    <div>
                                    <Button onClick={() => {props.handleImageDialog(n)}}  variant="contained" className="jr-btn bg-light-green text-white">
                                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                        <span>View</span>
                                    </Button>                                                            
                                    </div>
                                </TableCell> :
                                item === 'location_id' ?
                                <TableCell key={item+i} classes={{ head:classes.header,root:classes.root }} style={n[item].style} size="small">{n[item].value?.title}</TableCell> :
                                props.tableHeading === 'Console Users' ?
                                <CustomTableCell key={item+i} style={n[item].style} value={n[item].value} /> :
                                <TableCell key={item+i} classes={{ head:classes.header,root:classes.root }} style={n[item].style} size="small">{n[item].value}</TableCell>
                            )))}
                            {props.actions &&
                            <TableCell align="left">
                                {props.onEdit && 
                                    <ToolTipIcon
                                        title="Edit"
                                        ariaLabel="edit"
                                        icon={<EditIcon />}
                                        onClick={() => props.onEdit(n)}
                                    />
                                } 
                                {props.onDelete && 
                                    <ToolTipIcon
                                        title="Delete"
                                        ariaLabel="delete"
                                        icon={<DeleteIcon />}
                                        onClick={() => props.onDelete(n)}
                                    />
                                } 
                                {props.verifyUser && n?.verified_by?.value === null &&  n?.is_self_doc_uploaded?.value == 1  &&
                                    <ToolTipIcon
                                        title="Verify Documents"
                                        ariaLabel="verify documents"
                                        icon={<VerifiedUser />}
                                        onClick={() => props.verifyUser(n)}
                                    />
                                }
                                {props.statusUpdate && n?.client_accept?.value === '0' &&
                                    <ToolTipIcon
                                        title="Approve Client"
                                        ariaLabel="approve client"
                                        icon={<VerifiedUser />}
                                        onClick={() => props.statusUpdate({'accepted' : 1, applied_job_id : n.applied_job_id?.value})}
                                    />
                                }
                                {props.statusUpdate && n?.client_accept?.value === '1' && n?.started_on?.value === '0' &&
                                    <ToolTipIcon
                                        title="Start Job"
                                        ariaLabel="start job"
                                        icon={<StarIcon />}
                                        onClick={() => props.statusUpdate({'started' : 1, applied_job_id : n.applied_job_id?.value})}
                                    />
                                }
                                {props.statusUpdate && n?.client_accept?.value === '1' && n?.completed_on?.value === '0' && n?.started_on?.value !== '0' &&
                                    <ToolTipIcon
                                        title="Complete Job"
                                        ariaLabel="complete job"
                                        icon={<CheckCircleIcon />}
                                        onClick={() => props.statusUpdate({'completed' : 1, applied_job_id : n.applied_job_id?.value})}
                                    />
                                }
                                {props.statusUpdate && n?.client_accept?.value === '1' && n?.completed_on?.value !== '0' && n?.started_on?.value !== '0' &&
                                    <TableCell style={{border:'none'}}>-</TableCell>
                                }
                                {props.uploadSelfDoc &&  n?.is_self_doc_uploaded?.value === 0  &&
                                    <ToolTipIcon
                                        title="Upload Document"
                                        ariaLabel="Upload Document"
                                        icon={<CloudUploadIcon />}
                                        onClick={() => props.uploadSelfDoc(n)}
                                    />
                                } 
                                {props.staffStatus && 
                                    <ToolTipIcon
                                        title={(n.active.value==1)?'Deactivate':'Activate'}
                                        ariaLabel="staffStatus"
                                        icon={<LockOutlined style={{color:(n.active.value==1)?'green':'red'}} />}
                                        onClick={() => props.staffStatus(n)}
                                    />
                                }
                                {props.resetMobile && 
                                    <ToolTipIcon
                                        title="Reset Mobile"
                                        ariaLabel="resetMobile"
                                        icon={<PhonelinkLock style={{}} />}
                                        onClick={() => props.resetMobile(n)}
                                    />
                                } 
                                {props.onEnable &&                        
                                    <FormControlLabel
                                        control={<Switch color="primary" name="enabled" 
                                        checked={parseInt(n.hidden_status)>0} onClick={(e) => props.onEnable(n, e.target.checked)}/>}
                                
                                    />
                                }
                                {props.editLed &&
                                    <ToolTipIcon
                                        title="Main Controller Config"
                                        ariaLabel="main-controller-config"
                                        icon={<VideoLabel style={{}} />}
                                        onClick={() => props.editLed(n)}
                                    />

                                }
                                {props.rateModel &&
                                    <ToolTipIcon
                                        title="Rate Model"
                                        ariaLabel="rate-model"
                                        icon={<AttachMoneyIcon />}
                                        onClick={() => props.rateModel(n)}
                                    />

                                }
                                {props.subLocation &&
                                    <ToolTipIcon
                                        title="Sublocations"
                                        ariaLabel="sub-location"
                                        icon={<SettingsIcon />}
                                        onClick={() => props.subLocation(n)}
                                    />

                                }
                                {props.onSettings &&
                                    <ToolTipIcon
                                        title="Configuration"
                                        ariaLabel="Configuration"
                                        icon={<SettingsIcon />}
                                        onClick={() => props.onSettings(n)}
                                    />
                                }
                                {
                                props.onPurgeSqs &&
                                    <ToolTipIcon
                                        title="Purge SQS Messages"
                                        ariaLabel="purge-sqs-msg"
                                        icon={<DeleteSweep />}
                                        onClick={() => props.onPurgeSqs(n)}
                                    />
                                }
                                {props.serviceLocation &&
                                    <ToolTipIcon
                                        title="Valet Service Locations"
                                        ariaLabel="valet-service-loc"
                                        icon={<SettingsIcon />}
                                        onClick={() => props.serviceLocation(n)}
                                    />
                                }
                                {props.parkingLocation &&
                                    <ToolTipIcon
                                        title="Valet Parking Locations"
                                        ariaLabel="valet-parking-loc"
                                        icon={<LocalParkingIcon />}
                                        onClick={() => props.parkingLocation(n)}
                                    />
                                }
                                {props.updateStatus &&                        
                                    <EZSwitch 
                                        active={n.active.value} 
                                        id={n.id.value}
                                        changeStatus={props.updateStatus}
                                        key={`switch-${n.id.value}`}
                                    />
                                }
                                {props.attachGuard &&
                                    <ToolTipIcon
                                        title="Barrier Opening Guard"
                                        ariaLabel="barrier-opening-guard"
                                        icon={<Accessibility />}
                                        onClick={() => props.attachGuard(n)}
                                    />
                                }
                            </TableCell>
                            }
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }
    const getDineInTable = () =>{
        return(
            <TableBody>
                 {
                    (rowsPerPage > 0
                        ? props.table.body.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : props.table.body
                        ).map((n,index) => {
                    return (
                        <TableRow  key={props.tableHeading.replace(' ','')+'table-row-'+index}>
                            {Object.keys(n).map((item, i) => (
                                item.includes("hidden") ?
                                null
                                : (item === 'status' ? <TableCell style={{color:(n[item] == 'pending' || n[item] == 'new'  ? 'rgb(255, 0, 0)' : (n[item] == 'accepted' ? '#00dd5e' : (n[item] == 'completed' ? '0092dd' : '#FF0000')))}} key={item+i} classes={{ head:classes.header,root:classes.root,body:classes.pending }} size="small">{n[item] == 'accepted' ? 'preparing' :(n[item] === 'completed' ? 'served' : n[item]) }</TableCell> :
                                <TableCell key={item+i} classes={{ head:classes.header,root:classes.root }} size="small">{n[item]}</TableCell>
                            )))}
                            <TableCell align="left">
                                {props.table.details.status == 'pending' && 
                                    <div>
                                        <ToolTipIcon
                                            title="Reject"
                                            ariaLabel="reject"
                                            icon={<ClearIcon />}
                                            onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}                       
                                        />
                                    </div>
                                }
                                {n['status'] == 'pending' && (props.table.details.status == 'active' || props.table.details.status == 'new items')  && 
                                    <div>
                                        <ToolTipIcon
                                            title="Accept"
                                            ariaLabel="accept"
                                            icon={<DoneIcon />}
                                            onClick={() => props.updateOrderStatus('accept_item',n,props.tableHeading)}                     
                                        />
                                        <ToolTipIcon
                                            title="Reject"
                                            ariaLabel="reject"
                                            icon={<ClearIcon />}
                                            onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}                     
                                        />
                                    </div>
                                }  
                                {(n['status'] == 'accepted') && 
                                    <div>
                                        <ToolTipIcon
                                            title={'Complete'}
                                            ariaLabel="complete"
                                            icon={<DoneAllIcon />}
                                            onClick={() => props.updateOrderStatus('complete',n,props.tableHeading)}                     
                                        />
                                        <ToolTipIcon
                                            title={'Reject'}
                                            ariaLabel="reject"
                                            icon={<ClearIcon />}
                                            onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}                     
                                        />
                                    </div>
                                }  
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }
    return(
        <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
            <React.Fragment>
                {props.table.title}
                <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box>
                </MsgPopover>
            </React.Fragment>}>
            {!props.disableSearch && <SearchBox handleSearch={handleSearch} />}
            <div className="table-responsive-material">
                <Table>
                    {getHeaders()}
                    {props.dineIn ? getDineInTable() : getTableBody()}
                </Table>
                <TablePagination 
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={tBody.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> 
            </div>
              
        </CardBox>
    )

}
export default TableList

