/*
 * File: LocationList.js
 * Project: parkezdashboard
 * File Created: Thursday, 21st May 2020 6:09:30 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Tuesday, 7th July 2020 1:05:00 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React, {useState,useEffect, Fragment} from 'react' 
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import SettingsIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Autorenew from '@material-ui/icons/Autorenew'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TablePagination from '@material-ui/core/TablePagination';
import SearchBox from 'components/ActionsTable/SearchBox';
import ToolTipIcon from 'components/ToolTipIcon';
import LayersIcon from '@material-ui/icons/Layers';

const LocationList =(props)=> {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tBody,setTBody] = useState(props.locations.table);
    
    useEffect(()=>{
        setTBody(props.locations.table); 
    },[props.locations])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    //filter table according to search
    const handleSearch = (searchValue)=>{

        if(searchValue){
            var filter = [];
            props.locations.table.map(o=>{
                for(var key in o){
                    console.log(o[key]);
                    if( typeof o[key] !=='object' && typeof o[key] !=='boolean' && o[key] !=null && !Array.isArray(o[key]) && o[key].toLowerCase().includes(searchValue.toLowerCase()) ){
                        filter.push(o);
                        break;
                    }
                }
             });
            setTBody(filter);
        }else{
            setTBody(props.locations.table);
        }
    }
    return(
        <Fragment>
            <SearchBox style={{marginBottom:10}} handleSearch={handleSearch}/>
            <div className="table-responsive-material">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Location Name</TableCell>
                            <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Print Name</TableCell>
                            <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Locality</TableCell>
                            <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>City</TableCell>
                            <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    { (rowsPerPage > 0
                        ? tBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tBody
                        ).map(loc => {
                        return (
                        <TableRow key={loc.id}>
                            <TableCell>{loc.identifier}</TableCell>
                            <TableCell>{loc.name}</TableCell>
                            <TableCell>{loc.locality}</TableCell>
                            <TableCell>{loc.city.name}</TableCell>                        
                            <TableCell align="center">
                                <ToolTipIcon
                                    title="Configuration"
                                    ariaLabel="Configuration"
                                    icon={ <SettingsIcon /> }
                                    onClick={() => props.onSettings(loc)}
                                />
                                <ToolTipIcon
                                    title="Rate Model"
                                    ariaLabel="rateModel"
                                    icon={ <AttachMoneyIcon /> }
                                    onClick={() => props.rateModel(loc)}
                                />
                                <ToolTipIcon
                                    title="Command"
                                    ariaLabel="command"
                                    icon={ <Autorenew /> }
                                    onClick={() => props.sqsCommand(loc)}
                                />
                                <ToolTipIcon
                                    title="Parking Slots"
                                    ariaLabel="parking slots"
                                    icon={ <LayersIcon /> }
                                    onClick={() => props.parkingSlots(loc)}
                                />
                                <ToolTipIcon
                                    title="Edit"
                                    ariaLabel="edit"
                                    icon={ <EditIcon /> }
                                    onClick={() => props.onEdit(loc)}
                                />
                                <FormControlLabel
                                    control={<Switch color="primary" name="enabled" 
                                    checked={parseInt(loc.active)>0} onClick={(e) => props.onEnable(loc, e.target.checked)}/>}
                                />                        
                            </TableCell>
                        </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
                <TablePagination 
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={props.locations.table.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Fragment>
    )
}

export default LocationList