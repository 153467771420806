import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import API from "util/Api";
import _ from "lodash";
import ContainerHeader from "components/ContainerHeader";
import SnackBar from "components/SnackBar";
import ActionsTable from "components/ActionsTable/TableList";
import EZForm, { getFieldValues } from "components/EZForm";

const ConsoleClients = (props) => {
    const [fields, setFields] = useState();
    const [values, setValues] = useState();
    const [table, setTable] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    const validations = Yup.object().shape({
        fname: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        location: Yup.object().required("Required"),
        client: Yup.object().required("Required"),
    });

    const handleSubmit = (data, form) => {
        API.post("parkingConsoleClients", JSON.stringify(data))
            .then((res) => {
                setTable(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleEdit = (user) => {
        const fieldValues = getFieldValues(Object.values(user));
        console.log('fieldValues', fieldValues)
        setValues(fieldValues);
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    }

    const handleChange = (formik) => {
        if (formik.values.location?.clients && !_.isEqual(formik.values.location.clients, fields.client.options)) {
            if (fields.client.options.length > 0) {
                formik.setFieldValue('client', '');
            }
            let fieldsClone = _.cloneDeep(fields);
            fieldsClone.client.options = formik.values.location.clients;
            setFields(fieldsClone);
        }
    }

    useEffect(() => {
        API.get("parkingConsoleClients")
            .then((res) => {
                setFields(res.data.data.config?.fields);
                setTable(res.data.data.table);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    return (
        <>
            <ContainerHeader title={"Parking Console Clients"} match={props.match} />
            {fields && <EZForm
                fields={Object.values(fields)}
                values={values}
                handleChange={handleChange}
                onSubmit={handleSubmit}
                validationSchema={validations}
            />}
            {table && <ActionsTable
                tableHeading={table?.title}
                table={table}
                onEdit={handleEdit}
                actions={true}
            />}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )
}
export default ConsoleClients;