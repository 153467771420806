import React,{useEffect,useState} from 'react';
import { Button,InputLabel,Box,Select,MenuItem } from '@material-ui/core';
import API from 'util/Api';
import _ from "lodash";

const UploadSubscription = (props) => {
    console.log('upload-subsc-props',props)
    const [uploadType,setUploadType]=useState(null);
    const [file,setFile] = useState(undefined);
    const [value,setValue] = useState('');
    useEffect(()=>{
        setFile(undefined);
        setValue(null);
        setUploadType(null)
    },[props.selectedLoc])
    const uploadExcel = ()=>{
        
        if(file){
            const data = new FormData();
            data.append('file', file);
            data.append('location_id', props.selectedLoc.id);
            data.append('subscription_type',uploadType);
            API.post('subscriptionsCreate',data)
            .then((res) => {
                // received data        
                console.log('rcvd data',res.data)
                props.renderSnack({open:true,msg:res.data.msg,type:'success'});
                setFile(undefined);
                setValue(null);
                setUploadType(null)
            })
            .catch((err) => {
                try{
                    props.renderSnack({open:true,msg:err.response.data.msg,type:'error'})
                }catch{
                    props.renderSnack({open:true,msg:"Something went wrong",type:'error'})
                }
                // got an error
                console.log('err',err.response)
            })
        }else{
            props.renderSnack({open:true,msg:"Choose File",type:'error'})
        }
        console.log("uploadExcel",file)
    }
    return(
          <>
            <div className="row">
                <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_clients')}}> Download Client Sample  </Button></Box>
                {props.selectedLoc?.clients?.length>0 && 
                <>
                    <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_client_subscription')}}> Download Client Subscription Sample  </Button></Box>
                    <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_user_client')}}> Download Client's User Sample  </Button></Box>
                    <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_user_subscription')}}> Download User Subscription Sample  </Button></Box>
                    <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_vehicle_client')}}> Download Client's Vehicle Sample </Button></Box>
                    <Box mr="10px" pb="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{props.dwnloadSample('ev_client_code')}}> Download Client Code  </Button></Box>
                </>
                }
            </div>
            <div className="row" style={{marginTop:20}}>
                <div className={'col-md-3 col-12 '}  >
                <InputLabel id="demo-simple-select-helper-label">Select Upload Type</InputLabel>
                <Select
                    value={uploadType}
                    onChange={(e)=>{
                        setUploadType(e.target.value);
                        setFile(null);
                        setValue('');
                    }}
                    displayEmpty
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={"ev_clients"}>{"New Clients"}</MenuItem>
                        {props.selectedLoc?.clients?.length>0 && <MenuItem value={"ev_client_subscription"}>{"Clients Subscription"}</MenuItem>}
                        {props.selectedLoc?.clients?.length>0 && <MenuItem value={"ev_user_client"}>{"Client's User"}</MenuItem>}
                        {props.selectedLoc?.clients?.length>0 && <MenuItem value={"ev_vehicle_client"}>{"Client's Vehicle"}</MenuItem>}
                        {props.selectedLoc?.clients?.length>0 && <MenuItem value={"ev_user_subscription"}>{"User Subscription"}</MenuItem>}
                </Select>
                </div>
            </div>
            {
                uploadType &&
                <div className="row" style={{marginTop:20}}>
                    <div className={'col-md-3 col-12'} >
                        <input style={{display:'flex'}} value={value} type="file" accept=".xls,.xlsx" name="myfile" onChange={e=>{setValue(e.target.value);setFile(e.target.files[0])}}/>
                    </div>
                    <div className={'col-md-3 col-12'} >
                        <Button variant="contained" color="primary" mr="5px" onClick={()=>{uploadExcel()}}> Upload</Button>
                    </div>
                </div>
            }
            
        </> 
    )
}

export default UploadSubscription;