import React from "react";
import { Route, Switch } from "react-router-dom";

import ListUsers from "./routes/ListUsers";
import Payouts from "./routes/Payouts";
import Documents from "./routes/Documents";

const Users = ({ match }) => (
    <div className="app-wrapper">
      <Switch>
          <Route path={`${match.url}/list-users`} component={ListUsers}/>
          <Route path={`${match.url}/payouts`} component={Payouts}/>
          <Route path={`${match.url}/documents`} component={Documents}/>
      </Switch>
    </div>
  );
  
  export default Users;