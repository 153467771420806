import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Dashboard from "./dashboard"
import Analytics from './Analytics'
import Historical from './Historical'
import Resources from './Resources'
import EZcredits from './EZcredits'
import ManageOrders from './ManageOrders'
import FreeRequests from './FreeRequests'
import Appointments from './Appointments'
import Subscriptions from './Subscriptions'
import Attendants from './Attendants'
import Valet from './Valet'
import ValetV2 from './ValetV2'
import TermsAndConditions from './TermsAndConditions'
import Logs from './Logs'
import EZcharge from './EZcharge'
import Access from './Access'
import EZAds from './EZAds'
import Users from './Users';
import Jobs from './Jobs';

const Routes = ({match}) =>
  <Switch>    
    <Route path={`${match.url}/reports`} component={Dashboard}/>
    <Route path={`${match.url}/analytics`} component={Analytics}/>
    <Route path={`${match.url}/historical`} component={Historical}/>
    <Route path={`${match.url}/resources`} component={Resources}/>
    <Route path={`${match.url}/EZcredits`} component={EZcredits}/>
    <Route path={`${match.url}/ManageOrders`} component={ManageOrders}/>
    <Route path={`${match.url}/FreeRequests`} component={FreeRequests}/>
    <Route path={`${match.url}/Appointments`} component={Appointments}/>
    <Route path={`${match.url}/subscriptions`} component={Subscriptions}/>
    <Route path={`${match.url}/Valet`} component={Valet}/>
    <Route path={`${match.url}/valet-v2`} component={ValetV2}/>
    <Route path={`${match.url}/Attendants`} component={Attendants}/>
    <Route path={`${match.url}/Terms-and-conditions`} component={TermsAndConditions}/>
    <Route path={`${match.url}/logs`} component={Logs}/>
    <Route path={`${match.url}/ezcharge`} component={EZcharge}/>
    <Route path={`${match.url}/access`} component={Access}/>
    <Route path={`${match.url}/parkez-ads`} component={EZAds}/>
    <Route path={`${match.url}/user`} component={Users}/>
    <Route path={`${match.url}/job`} component={Jobs}/>
  </Switch>;


export default withRouter(Routes);
