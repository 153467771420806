let data = [];

function getRandomNumber() {
    return Math.floor(Math.random() * 100) + 1;
}

for (let index = 0; index < 100; index++) {
    data.push({
        name: `P-${index + 1}`,
        data: [
            {
                x: 'Sun',
                y: getRandomNumber()
            }, {
                x: 'Mon',
                y: getRandomNumber()
            }, {
                x: 'Tue',
                y: getRandomNumber()
            }, {
                x: 'Wed',
                y: getRandomNumber()
            }, {
                x: 'Thu',
                y: getRandomNumber()
            }, {
                x: 'Fri',
                y: getRandomNumber()
            }, {
                x: 'Sat',
                y: getRandomNumber()
            }
        ]
    })

}
export default data;