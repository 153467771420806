import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { toggleCollapsedNav } from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import API from 'util/Api';
import CustomScrollbars from 'util/CustomScrollbars';
import Badge from '@material-ui/core/Badge';
import logov from 'assets/images/logo_v.png';

const Index = (props) => {

  const dispatch = useDispatch();
  const { drawerType } = useSelector(({ settings }) => settings);
  // const [langSwitcher, setLangSwitcher] = useState(false);
  // const [mailNotification, setMailNotification] = useState(false);
  const [appNotification, setAppNotification] = useState(false);
  // const [searchBox, setSearchBox] = useState(false);
  // const [apps, setApps] = useState(false);
  // const [searchText, setSearchText] = useState('');
  const [unRead, setUnRead] = useState(0);
  const [logo, setLogo] = useState(logov);
  const localLogo = localStorage.getItem('logo');

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification)
  };

  // const onMailNotificationSelect = () => {
  //   setMailNotification(!mailNotification)
  // };
  // const onLangSwitcherSelect = () => {
  //   setLangSwitcher(!langSwitcher);
  // };

  // const onSearchBoxSelect = () => {
  //   setSearchBox(!searchBox)
  // };
  // const onAppsSelect = () => {
  //   setApps(!apps)
  // };

  // const handleRequestClose = () => {
  //   setSearchBox(false);
  //   setLangSwitcher(false);
  //   setMailNotification(false);
  //   setSearchBox(false);
  //   setApps(false);
  // };

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  useEffect(() => {
    if (localLogo) {
      setLogo(localLogo)
    }
  }, [localLogo])


  // const Apps = () => {
  //   return (
  //     <ul className="jr-list jr-list-half">
  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/app/calendar/basic">
  //           <i className="zmdi zmdi-calendar zmdi-hc-fw" />
  //           <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic" /></span>
  //         </Link>
  //       </li>

  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/app/to-do">
  //           <i className="zmdi zmdi-check-square zmdi-hc-fw" />
  //           <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo" /></span>
  //         </Link>
  //       </li>

  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/app/mail">
  //           <i className="zmdi zmdi-email zmdi-hc-fw" />
  //           <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail" /></span>
  //         </Link>
  //       </li>

  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/app/chat">
  //           <i className="zmdi zmdi-comment zmdi-hc-fw" />
  //           <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat" /></span>
  //         </Link>
  //       </li>

  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/app/contact">
  //           <i className="zmdi zmdi-account-box zmdi-hc-fw" />
  //           <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact" /></span>
  //         </Link>
  //       </li>

  //       <li className="jr-list-item">
  //         <Link className="jr-list-link" to="/">
  //           <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
  //           <span className="jr-list-text">Add New</span>
  //         </Link>
  //       </li>
  //     </ul>);
  // };


  // const updateSearchText = (evt) => {
  //   setSearchText(evt.target.value);
  // };

  // const onSwitchLanguage = (lang) => {
  //   dispatch(switchLanguage(lang))
  // };

  const AppNotification = () => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
      let unmounted = false;
      API.get('notifications').then((res) => {
        if (!unmounted) {
          setNotifications(res.data.data.notifications)
          setUnRead(res.data.data.unread)
        }
      })
        .catch((err) => {
          // got an error
          console.log(err)
        })

      return () => { unmounted = true };
    }, [setNotifications])

    return (
      <CustomScrollbars className="messages-list scrollbar" style={{ height: 280 }}>
        <ul className="list-unstyled">
          {notifications.map((notification, index) => <NotificationItem key={index} notification={notification} />)
          }
        </ul>
      </CustomScrollbars>
    )
  }

  const NotificationItem = ({ notification }) => {
    const { message, created_at } = notification;
    return (
      <li className="media">
        <div className="media-body align-self-center">
          <p className="sub-heading mb-0">{message}</p>
          <span className="meta-date"><small>{created_at}</small></span>
        </div>
      </li>
    );
  };

  const postNotifications = () => {
    let params = {
      read: 1
    }
    API.post('notifications', JSON.stringify(params)).then((res) => {
      console.log('notifications', res)
    })
      .catch((err) => {
        // got an error
        console.log(err)
      })
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>

        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
          onClick={onToggleCollapsedNav}>
          <span className="menu-icon" />
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block" to="/">
          <img src={logo} alt="ValetEZ" title="ValetEZ" />
        </Link>


        {/* <SearchBox styleName="d-none d-lg-block" placeholder=""
                   onChange={updateSearchText}
                   value={searchText}/> */}

        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item app-tour">
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}>

              <DropdownMenu end>
                <CardHeader styleName="align-items-center"
                  heading={<IntlMessages id="appNotification.title" />} />
                <AppNotification />

              </DropdownMenu>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">

                <IconButton className="icon-btn" onClick={postNotifications} >
                  <i className="zmdi zmdi-notifications-none" />
                  <Badge
                    badgeContent={unRead} color="error"
                    style={{ top: "-10px", right: "-2px" }}
                  >
                  </Badge>
                </IconButton>
              </DropdownToggle>

            </Dropdown>

          </li>
          {/* <li className="list-inline-item app-tour">
            <Link className="jr-list-link quick-menu"  style={{ textDecoration: 'none' }} to="/app/help">
            <IconButton className="icon-btn">
                <i className="zmdi zmdi-help zmdi-hc-fw"/>
                </IconButton>
                
            </Link>
          </li> */}
        </ul>

        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};


export default withRouter(Index);
