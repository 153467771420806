import React, {useState,useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles,useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress';
import QrReader from 'react-qr-reader'
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import ReactToPrint from "react-to-print";
import PrintReceipt from './PrintReceipt';
import ToolTipIcon from 'components/ToolTipIcon';


var crypto = require('crypto');

const useStyles = makeStyles( (theme) =>(
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight:'1em'
          },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        right10: {
            right:'10%'
        },  
        text: {
            color: theme.palette.secondary.main
          },
        label:{
            color: theme.palette.primary.main,
        },
        button:{
            backgroundColor:theme.palette.secondary.light,
            color:'white'
        },
        underline:{
            display:'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop:6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon:{
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15:{marginBottom:"15px"},
        md20:{marginBottom:"20px"},
        md30:{marginBottom:"30px"},
        dispBlock:{display:'block'},
        acceptColor:{background:'green',color:'white'},
        rejectColor:{background:'red',color:'white'},
        pickupColor:{background:'orange',color:'white'},
        completeColor:{background:'green',color:'white'},
        feedBack:{marginTop:"20px"}

      }

))
const numFormatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 6 })
const amountFormatter = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2,maximumFractionDigits: 2 })

const OrderDetails =(props)=> {
    let componentRef = React.useRef();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const [otp,setOTP] = useState(0)
    const [error,setErrorMessage] = useState(props.errorMessage)
    const [scanResult,setScanResult] = useState(props.errorMessage)
    const [isReject, setReject] = useState(false)
    const [paymentType, setpaymentType] = React.useState('cash');

    const handleInputChange = (e,val) => {                
        setOTP(e.target.value)
    }
    const handleChange = (e,val) => {                
        setpaymentType(e.target.value)
    }
    const completeOrder = () => {
        if(otp === props.orderDetails.otp)
            props.handleOrderStatus('complete_order',props.orderDetails.order_id,props.orderDetails.vendorID,otp)
        else
            setErrorMessage('Invalid OTP') 
    }
    const handleClose = () => {
        setErrorMessage('');
        props.handleClose()
    }

    const handleCloseReject = () => {
        setReject(false)
    }
    const handleScan = (data) => {
        if (data) {
            var decryptedData = decrypt(data);
            var order = decryptedData.split('/');
            if(props.dineIn && props.orderDetails.orderID === order[0]){
                props.completeOrder('app',props.orderDetails.orderID)
            }
            else if(order[0] == props.orderDetails.order_id && order[1] == parseInt(props.orderDetails.order_total) && order[2] == props.orderDetails.vendorID && order[3] == props.orderDetails.user_id){
                props.handleOrderStatus('complete_order',props.orderDetails.order_id,props.orderDetails.vendorID,props.orderDetails.otp)
            } else {
                setErrorMessage('Invalid QR')
            }
        }
    }
    const handleError = (err) => {
        setErrorMessage(err) 

    }
    const decrypt = (text) => {
        const ivText = "0fcab864f6b182d538f918876e0fda92";
        const key = "e0b1c3e518efc6ba8bbb0d920f40c547";
        const cipherAlgo = "AES-128-CBC";
        const hashKey = "iiDB8ceNBDdBTXLhAPlF1HQW6u13";
        var binaryIV = new Buffer(ivText, "hex");
        var binaryEncryptionKey = new Buffer(key, "hex");
        var decipher = crypto.createDecipheriv(cipherAlgo, binaryEncryptionKey, binaryIV);
        var a = decipher.update(text, "base64", "binary");
        var b = decipher.final("binary");
        var decryptedInput = a + b;//Buffer.concat([a,b]);
        //console.log(decryptedInput.toString());

        return decryptedInput.toString();
    }

    const rejectOrder = () => {
        console.log("reject clicked")
        setReject(true)
    }
    const getPickupDetails = () =>{
        return(
            <div>
                <div className="row">
                    <div className="col-md-8 sm-12">     
                        <Typography variant="overline" className={classes.caption}>Order Details</Typography>                                        
                        <Typography  variant="overline" color="secondary" className={classes.dispBlock}>Order Id: {props.orderDetails.order_id}</Typography>
                        <Typography  variant="overline" className={classes.dispBlock} color="secondary">Order Placed At: {props.orderDetails.placed_at}</Typography>
                        {props.orderDetails.confirmed_at != '' && <Typography  variant="overline" className={classes.dispBlock} color="secondary">Order Confirmed At: {props.orderDetails.confirmed_at}</Typography>}
                        {props.orderDetails.cancelled_at != '' && <Typography  variant="overline" className={classes.dispBlock} color="secondary">Order Cancelled At: {props.orderDetails.cancelled_at}</Typography>}
                    </div>
                    <div className="col-md-4 sm-12">     
                        <Typography variant="overline" className={classes.caption}>Customer Details</Typography>                                        
                        <Typography  variant="overline" className={classes.dispBlock} color="secondary">Name: {props.orderDetails.name}</Typography>
                        <Typography  variant="overline" className={classes.dispBlock} color="secondary">Mobile: {props.orderDetails.mobile}</Typography>
                    </div>
                </div>
                <div className="table-responsive-material" style={{width:'100%'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { props.orderDetails.products.map((product, index)  => {
                                return (
                                <TableRow key={index}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.quantity}</TableCell>
                                    <TableCell>{amountFormatter.format(product.amount/product.quantity)}</TableCell>
                                    <TableCell>{amountFormatter.format(product.amount)}</TableCell>
                                </TableRow>
                                );
                                })}
                                <TableRow>
                                <TableCell colSpan={3}>Total</TableCell>
                                <TableCell >{amountFormatter.format(props.orderDetails.order_total)}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </div> 
                <div>
                <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{error}</Typography>
                {props.errorMessage && <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{props.errorMessage}</Typography>}
                <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{props.message}</Typography>
                <div>{props.loading && <LinearProgress size={24}  />}</div>

                {!props.loading && props.orderDetails.status==='ready for pickup' && 
                <div>
                    <div>
                        <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                        resolution={600}
                        />
                    </div>
                    <div className="col-md-4 col-12">
                        <InputLabel shrink={true} htmlFor="name" className={classes.label}>OTP</InputLabel>
                        <TextField name="otp" margin="none" onChange={handleInputChange}  fullWidth/>
                    </div>                
                </div>}
            </div>
            <div style={{display:'none'}} >
                <PrintReceipt ref={el => (componentRef = el)} orderDetails={props.orderDetails} />
            </div> 
        </div>                
        )
    }
    const getDineInDetails = () =>{
        return(
            <div>
                
                <div className="table-responsive-material" style={{width:'100%'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { props.orderDetails.products.map((product, index)  => {
                                return (
                                <TableRow key={index}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.quantity}</TableCell>
                                    <TableCell>{amountFormatter.format(product.price_hidden/product.quantity)}</TableCell>
                                    <TableCell>{amountFormatter.format(product.price_hidden)}</TableCell>
                                </TableRow>
                                );
                                })}
                                {
                                    ('serviceCharge' in props.orderDetails) ?
                                    <TableRow>
                                        <TableCell colSpan={3} align="right">Service Charge</TableCell>
                                        <TableCell >{props.orderDetails.serviceCharge}</TableCell>
                                    </TableRow> :''
                                }
                                <TableRow>
                                    <TableCell colSpan={3} align="right">GST</TableCell>
                                    <TableCell >{amountFormatter.format(props.orderDetails.tax)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} align="right">Total</TableCell>
                                    <TableCell >{amountFormatter.format(props.orderDetails.amount)}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </div> 
                <div>
                <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{error}</Typography>
                {props.errorMessage && <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{props.errorMessage}</Typography>}
                <Typography paragraph={true} variant="h6" className={classes.md20} color="secondary">{props.message}</Typography>
                <div>{props.loading && <LinearProgress size={24}  />}</div>

                {!props.loading && props.orderDetails.status==='payment complete' && 
                <div>
                    <div>
                        <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                        resolution={600}
                        />
                    </div>               
                </div>}
               
                {!props.loading && props.orderDetails.status!=='payment complete' && 
                <div className="col-md-4 col-12">
                        <InputLabel shrink={true} htmlFor="payment-type" className={classes.label}>Payment Mode</InputLabel>
                        <Select
                            labelId="payment-type"
                            id="payment-type"
                            value={paymentType}
                            onChange={handleChange}
                            fullWidth>
                            <MenuItem value={'cash'}>Cash</MenuItem>
                            <MenuItem value={'online'}>Online</MenuItem>
                        </Select>
                    </div>  }
            </div> 
            </div>                
        )
    }
    const getDineInReportDetails= () =>{
        return(
            <div>
                
                <div className="table-responsive-material" style={{width:'100%'}}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Item</TableCell>
                                <TableCell align={"right"}>Quantity</TableCell>
                                <TableCell align={"right"}>Price</TableCell>
                                <TableCell align={"right"}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { props.orderDetails.products.map((product, index)  => {
                                return (
                                <TableRow key={index}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell align={"right"}>{product.quantity}</TableCell>
                                    <TableCell align={"right"}>{amountFormatter.format(product.price/product.quantity)}</TableCell>
                                    <TableCell align={"right"}>{amountFormatter.format(product.price)}</TableCell>
                                </TableRow>
                                ); 
                                })} 
                                <TableRow>
                                    <TableCell colSpan={3} align={"right"}>GST</TableCell>
                                    <TableCell align={"right"}>{amountFormatter.format(props.orderDetails.tax)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} align={"right"}>Total</TableCell>
                                    <TableCell align={"right"} >{amountFormatter.format(props.orderDetails.amount)}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                
                </div> 
                {props.orderDetails.rating && 
                    <Box component="fieldset" mb={3} borderColor="transparent" className={classes.feedBack}>
                        <Typography component="legend">User Feedback</Typography>
                        <Typography component="legend">{props.orderDetails.comments}</Typography>
                        <Rating name="read-only" value={props.orderDetails.rating} readOnly />
                    </Box>
                }               
            </div>                
        )
    }
    return(
        <div>
        { props.isShowing && 
        <Dialog open={props.isShowing} onClose={props.handleClose} 
            fullWidth={true}
            fullScreen={fullScreen}
        >
        <DialogTitle color="secondary"> 
            <Box>
                {props.heading ? props.heading : 'Order Details'}

                <ToolTipIcon
                    title="Close"
                    ariaLabel="Close"
                    className={classes.closeButton}
                    icon={ <CloseIcon /> }
                    onClick={handleClose}
                />
            </Box>                   
        </DialogTitle>
        <DialogContent>
            {props.dineInReports ? getDineInReportDetails() : props.dineIn ? getDineInDetails() : getPickupDetails()}                               
        </DialogContent>
            
            <DialogActions>
            
            {!props.loading && props.orderDetails.status==='placed' &&
                <div>
                    <Box display="inline-block">
                        <Button className={classes.right10} variant="contained" color="primary" onClick={() => props.handleOrderStatus('process_order',props.orderDetails.order_id,props.orderDetails.vendorID)}>
                            Accept
                        </Button>                                     
                    </Box>
                    <Box display="inline-block">
                        <Button onClick={rejectOrder}  variant="outlined"  className={classes.button}>
                            Reject
                        </Button>                                     
                    </Box>
                </div>    
            }

            {isReject &&
                <Dialog open={isReject} onClose={handleCloseReject}>
                    <DialogContent color="secondary">
                            <Typography variant={"body1"}> 
                                Are you sure you want to Reject the order ?
                            </Typography>                                                                                    
                    </DialogContent>
                    <DialogActions>
                        <Box display="inline-block">
                            <Button onClick={() => {setReject(false) ;props.handleOrderStatus('cancel_order',props.orderDetails.order_id,props.orderDetails.vendorID)}}  variant="contained"  className={classes.right10} color="primary">
                                Yes
                            </Button>                                     
                        </Box>
                        <Box display="inline-block">
                            <Button onClick={handleCloseReject} variant="outlined"  className={classes.button}>
                                No
                            </Button>                                     
                        </Box>
                    </DialogActions>
                </Dialog>    
            }
        {!props.loading && props.orderDetails.status==='confirmed' &&
            <Box display="inline-block">
                <Button  variant="contained" onClick={ () =>props.handleOrderStatus('order_processed',props.orderDetails.order_id,props.orderDetails.vendorID)} className={classes.pickupColor}>
                    Ready for pickup
                </Button>                                     
        </Box>
        }
        {!props.loading && props.orderDetails.status==='ready for pickup' &&
            <Box display="inline-block">
                <Button onClick={completeOrder} variant="contained" className={classes.right10} color="primary">
                    Complete
                </Button>                                     
            </Box>
        }
        {!props.loading && props.dineIn && props.orderDetails.status!=='payment complete' && 
            <Box display="inline-block">
                <Button  variant="contained" onClick={ () =>props.completeOrder(paymentType,props.orderDetails.orderID)} className={classes.pickupColor}>
                    Complete
                </Button>                                     
        </Box>
        }
        {!props.loading && props.orderDetails.status==='completed' && 
            <Box display="inline-block">
                <ReactToPrint
                trigger={() => <a href="#">Print this out!</a>}
                content={() => componentRef}
                />                                     
            </Box>
        }
            </DialogActions>
        </Dialog>
    }
    </div>
    )

}
export default OrderDetails

