/*
 * File: Imgield.jsx
 * Project: parkezdashboard
 * File Created: Tuesday, 23rd January 2025 4:19:13 pm
 * Author: Ajay (ajay@iglulabs.com)
 * -----
 * Last Modified: Tuesday, 25rd January 2025 4:20:04 pm
 * Modified By: Ajay (ajay@iglulabs.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2025 , ValetEZ Services Pvt Ltd
 */

import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, FormControl, Button, IconButton } from '@material-ui/core'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Field, connect } from 'formik'
import _ from 'lodash'
import { Add, Delete, Restore } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ToolTipIcon from 'components/ToolTipIcon';

const useStyles = makeStyles({
    errMsg: {
        color: '#f44336',
        fontSize: '0.75rem'
    },
    subHeader: {
        position: 'relative'
    },
    dialogTitle: {
        textAlign: "center"
    }
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.light,
        color: 'rgba(255, 255, 255, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        maxWidth: 200,
        textAlign: "center",
    },
    popper: {
        zIndex: 1100
    }
}))(Tooltip);

const DialogBox = (props) => {
    const classes = useStyles();
    
    let currentImages = props.field.current_images ;
    
    console.log("Dialog_box____existingImg", props.deletedImages, props);

    const removeImage  = (index) => {
        const updatedImages = props.images.filter((_, i) => i !== index); // Remove it

        props.setImages(updatedImages);
        props.formik.setFieldValue("images", updatedImages);

        props.setSelectedImages((prevItems) => prevItems.filter((_, i) => i !== index));
    }

    const handleDeleteImage = (img) => {
        const updatedDeletedImages = [...props.deletedImages, img.id];
        props.setDeletedImages(updatedDeletedImages); // Update local state
        props.formik.setFieldValue('img_del', updatedDeletedImages); // Update Formik field        
    };

    const handleRestoreImage = (img) => {
        const updatedDeletedImages  =  props.deletedImages.filter((image) => image !== img.id);
        props.setDeletedImages(updatedDeletedImages); // Update local state
        props.formik.setFieldValue('img_del', updatedDeletedImages); // Update Formik field
    };
    

    const handleAddImage = (event) => {
        if (event.currentTarget?.files?.length) {
            const files = Array.from(event.target.files);
            
            const imagePreviews = files.map((file) => URL.createObjectURL(file));
            const updatedImages = [...props.images, ...files];

            props.setImages(updatedImages);
            props.setSelectedImages([...props.selectedImages, ...imagePreviews]);
            props.formik.setFieldValue('images', updatedImages);
        }
    }
    

    return(
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'md'}
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>{ props.header }</DialogTitle>
            <DialogContent>                
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))", gap: "16px", padding: "16px" }} >
                    {currentImages.length > 0 && currentImages.map((image, index) => (
                        <div key={index} style={{ position: "relative", width:'100%', height:'100%'}}>
                            <img
                                src={image.url}
                                alt={`Preview ${index + 1}`}
                                style={{
                                    width: "100%",
                                    height:'200px',
                                    // maxHeight:'200px',
                                    borderRadius: "8px",
                                    opacity: props.deletedImages.some((img) => img === image.id) ? 0.5 : 1,
                                    border: "1px solid #ccc",
                                }}
                            />
                                <ToolTipIcon
                                    title={props.deletedImages.some((img) => img === image.id) ? 'Restore' : 'Delete' }
                                    ariaLabel="delete-restore-imgs"
                                    style={{position:'absolute', 
                                        top: "2px",
                                        right: "2px",
                                        backgroundColor: "rgba(31, 31, 31, 0.57)",
                                        color: "white",
                                    }}
                                    icon={ props.deletedImages.some((img) => img === image.id) ? <Restore fontSize="small" /> : <Delete fontSize="small" /> }
                                    onClick={() => { props.deletedImages.some((img) => img === image.id)
                                        ? handleRestoreImage(image) : handleDeleteImage(image);
                                    }}
                                />
                        </div>
                    ))}
                    {props.selectedImages.map((image, index) => {
                    
                    console.log("deletedImages______", props.deletedImages)
                    return(
                        <div key={index} style={{ position: "relative", width:'100%', height:'100%'}}>
                            <img
                                src={image}
                                alt={`Preview ${index + 1}`}
                                style={{
                                    width: "100%",
                                    height:'200px',
                                    // maxHeight:'200px',
                                    borderRadius: "8px",
                                    border: "1px solid #ccc"
                                }}
                            />
                             <ToolTipIcon
                                title="Delete"
                                ariaLabel="delete"
                                style={{position:'absolute', 
                                    top: "2px",
                                    right: "2px",
                                    backgroundColor: "rgba(31, 31, 31, 0.57)",
                                    color: "white",
                                }}
                                icon={ <Delete fontSize="small" /> }
                                onClick={(e) => {
                                    removeImage(index);
                                }}
                            />
                        </div>
                    )})}
                   
                    <div style={{height:'100%',}}>
                      <button
                            style={{
                                position: "relative",
                                minHeight:"200px",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(71, 71, 71, 0.5)",
                                color: "white",
                                fontSize: "24px",
                                border: "none",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => document.getElementById(props.field.field.name).click()}
                        >
                            <Add style={{ fontSize: "40px" }} />  
                            <input id={props.field.field.name} type="file" accept={props.field.accept} multiple={props.field.multiple} {...props.field.value === '' ? { value: '' } : {}} style={{ display: 'none' }} onChange={handleAddImage} />                  
                        </button>
                    </div>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button variant="outlined"   color="secondary" onClick={props.onClose}>
                    {'Close'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const ImgFiles = connect((props) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [dialog, setDialog] = useState({open: false, header:'ADD IMAGES', images:[]})

    useEffect(() =>{
        setDeletedImages([]);
        setImages([]);
        setSelectedImages([]);
    },[props.formik.values.id])


    const useStyles = makeStyles((theme) => ({
        button: {
            color: theme.palette.secondary.light,
            justifyContent: 'left',
            marginTop: '17px'
        }
    }))
    const classes = useStyles();

    const handleDialog = () => {
        setDialog((prevState) => ({ ...prevState, open: true }));
    };

    return( <>
        <Button
            variant="text"
            size='large'
            className={classes.button}
            onClick={() => handleDialog()}
            startIcon={<CloudUploadIcon color='primary' />}
        >
            <div>
                Add/View {props.label}
            </div>
        </Button>
        <DialogBox
            open={dialog.open} 
            header={"ADD IMAGES"}
            field={{...props}}
            formik={props.formik}
            deletedImages={deletedImages}
            setDeletedImages={setDeletedImages}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            images={images}
            setImages={setImages}
            onClose={() => { setDialog({ open: false }) }} 
        />
        
    </> )
});


const ImgField = connect((props) => {
    const field = _.cloneDeep(props.field)

    let component = ImgFiles;

    return (
        <LightTooltip title={(field.help ? field.help : "")} >
            <FormControl className="w-100">
                <Field
                    component={component}
                    name={field.name || field.key}
                    type={''}
                    label={field.title}
                    disabled={"disabled" in field ? field.disabled : false}
                    // helperText={field.helperText}
                    {...{current_images: field.current_images}}
                    InputProps={{}}
                    {...(field.type === 'file' ? { accept: field.accept, multiple: field.multiple } : {})}
                />
            </FormControl>
        </LightTooltip>
    )
});

export default ImgField