import React, { useEffect, useState } from "react";
import _ from "lodash";
import * as Yup from "yup";
import SnackBar from "components/SnackBar";
import ContainerHeader from "components/ContainerHeader";
import EZForm from "components/EZForm";
import API from "util/Api";
import HeatMap from 'components/HeatMap'

const ParkingHeatmap = (props) => {
    const [fields, setFields] = useState();
    const [data, setData] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const validations = Yup.object().shape({
        location: Yup.object().required("Required"),
        floor_id: Yup.string().required("Required"),
        start_date: Yup.string().required("Required"),
        end_date: Yup.string().required("Required"),
    });

    const handleSubmit = (data, { setSubmitting }) => {
        setData(undefined);
        API.post("parkingHeatmap", JSON.stringify(data))
            .then((res) => {
                setSubmitting(false);
                setData(res.data.data);
            })
            .catch((err) => {
                setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleChange = (formik) => {
        console.log('handleChange - ', formik)
        const values = formik.values;
        if (values.location && !_.isEqual(values.location.floors, fields.floor.options)) {
            formik.setFieldValue('floor_id', '');
            const config = _.cloneDeep(fields);
            config.floor.options = values.location.floors;
            setFields(config);
        }
    }

    useEffect(() => {
        API.get("parkingHeatmap")
            .then((res) => {
                setFields(res.data.data.fields);
            })
            .catch((err) => {
                console.error("error", err);
            });
    }, []);

    return (
        <>
            <ContainerHeader title={`Parking Heat Map`} match={props.match} />
            {fields && <EZForm
                fields={Object.values(fields)}
                validationSchema={validations}
                handleChange={handleChange}
                onSubmit={handleSubmit}
            />}
            {data?.hourly && <HeatMap
                title={'Parking Slots Utilization (Hourly)'}
                data={data.hourly?.data}
                min={data.hourly?.min}
                max={data.hourly?.max}
            />
            }
            {data?.daily && <HeatMap
                title={'Parking Slots Utilization (Daily)'}
                data={data.daily?.data}
                min={data.daily?.min}
                max={data.daily?.max}
            />}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>)
}
export default ParkingHeatmap;