import React, { useState, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader'
import TerminalBox from 'components/TerminalBox'
import { Typography, LinearProgress } from '@material-ui/core'
import API from '../../../../util/Api'
import _ from 'lodash'
import Form from './Form'
import moment from "moment"


const LiteController = (props) => {

    const [response, setResponse] = useState(undefined)
    const [showProgress, setShowProgress] = useState(false)
    const [logs, setLogs] = useState({})
    const [fetchOlderlogs, setFetchOlderlogs] = useState(false)
    const [startTime, setStartTime] = useState({})
    const [endTime, setEndTime] = useState({})
    const [form, setForm] = useState(undefined)
    const [olderlogs, setOlderlogs] = useState({
        shouldFetch: false,
        startDateTime: '',
        endDateTime: ''
    })

    useEffect(() => {
        API.get("liteController").then(response => {
            console.log('response.data.data', response.data.data)
            setResponse(response.data.data)
        }).catch(error => {
        })

    }, [])

    const handleReset = () => {
        setLogs({})
    }
    const fetchlogs = (macAddress, url = {}, reset = false) => {
        let macAddressArr = [];
        let isSubmitted = false;
        if (Array.isArray(macAddress)) {
            macAddressArr = macAddress;
            isSubmitted = true;
        } else {
            macAddressArr = [macAddress];
        }
        const data = { type: 'lite-controller', filter: macAddressArr, ...url };
        API.post('findLedlogs' , JSON.stringify(data)).then(response => {
            setShowProgress(false)
            console.log("findlogsResponse", response.data.data)
            let serverLogs = response.data.data.data
            if (!reset && logs && Object.keys(logs).length > 0 && !isSubmitted) {
                let newData = _.unionBy(serverLogs[macAddress], logs[macAddress], 'timeStamp');
                newData = _.orderBy(newData, ['timeStamp'], ['asc']);
                serverLogs = { [macAddress]: newData };
            }

            if(isSubmitted){
                Object.entries(serverLogs).forEach(([macAddress, logData], index) => {
                    const min_obj = _.minBy(logData, function (o) { return o.timeStamp; });
                    const max_obj = _.maxBy(logData, function (o) { return o.timeStamp; });
                    if (min_obj?.timeStamp) {
                        setStartTime((prev) => ({ ...prev, [macAddress]: min_obj.timeStamp }));
                    }
                    if (max_obj?.timeStamp) {
                        setEndTime((prev) => ({ ...prev, [macAddress]: max_obj.timeStamp }));
                    }
                })
                setLogs(serverLogs)
            } else {
                const min_obj = _.minBy(serverLogs[macAddress], function (o) { return o.timeStamp; });
                const max_obj = _.maxBy(serverLogs[macAddress], function (o) { return o.timeStamp; });
                //setting start time to fetch latest logs
                if (min_obj?.timeStamp) {
                    setStartTime((prev) => ({ ...prev, [macAddress]: min_obj.timeStamp }));
                }
                if (max_obj?.timeStamp) {
                    setEndTime((prev) => ({ ...prev, [macAddress]: max_obj.timeStamp }));
                }                
                setLogs(prevLogs => ({ ...prevLogs, [macAddress]: serverLogs[macAddress] }));
            }

        }).catch(error => {
            setShowProgress(false)
        })
    }
    const getMacAddress = (controllers) => {
        const macAddressess = [];
        controllers.forEach(element => {
            macAddressess.push(element.mac_address);              
        });
        return macAddressess;
    }
    const handleSubmit = (from, data) => {
        setLogs({});
        if (data.controller) {
            let url = {};
            if (data.startDateTime && data.endDateTime) { // if date range is selected
                let dateRangeStartTime = moment(data.startDateTime).format("X")
                let dateRamgeEndTime = moment(data.endDateTime).format("X")
                url = {startTime: dateRangeStartTime, endTime: dateRamgeEndTime}
            }
            setForm(data)
            setShowProgress(true)
            const macAddressess = getMacAddress(data.controller);
            fetchlogs(macAddressess, url, true)
            console.log("handleSubmit2", url, from, data)

        } else {
            console.log("please select led")
        }

    }
    const handleRefresh = (mac_address) => {
        console.log('handleRefresh')
        setLogs(prevLogs => ({ ...prevLogs, [mac_address]: ['Refreshing...'] }));
        fetchlogs(mac_address, '', true);
    }
    const getOlderLogs = (mac_address) => {
        let start = startTime[mac_address] - (30 * 60)//taking 30 min before from start time
        const url = {startTime: start, endTime:startTime[mac_address]};
        setFetchOlderlogs(true)
        fetchlogs(mac_address, url)
    }
    useEffect(() => {
        // if date range is selected, don't allow auto update
        if (Object.keys(logs).length > 0 && !(form?.startDateTime && form?.endDateTime)) {
            console.log("setIntervalll", logs)
            const timer = setInterval(() => {
                const macAddressArr = form.controller.map((controller) => controller.mac_address);
                if (macAddressArr.length > 0)
                    fetchlogs(macAddressArr);
            }, 1000 * 30);
            return () => clearInterval(timer);
        }

    }, [logs])
    console.log("LiteController", logs, form)
    return (
        <>
            <ContainerHeader title={'Lite Controller Logs'} match={props.match} />
            {
                response ?
                    <Form
                        parkingLoc={response.parkingLoc}
                        chargingLoc={response.chargingLoc}
                        accessLoc={response.accessLoc}
                        controllersList={response.controllers}
                        handleReset={handleReset}
                        handleSubmit={handleSubmit}
                    /> : <></>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary" />
                    <LinearProgress color="primary" />
                </div>
            }
            {
            (form && logs && Object.keys(logs).length > 0) &&
            Object.entries(logs).map(([macAddress, logData], index ) => {
                return (
                logData.length > 0 ?
                <TerminalBox
                    key={macAddress}
                    logs={logData}
                    type={'Lite Controller'}
                    name={`${form.location.name} - ${form.controller[index].name} (${form.controller[index].mac_address})`}
                    handleRefresh={() => {
                        if (!(form?.startDateTime && form?.endDateTime)) {
                            handleRefresh(macAddress);
                        }
                    }} // if date range is selected then hide refresh
                    getOlderLogs={() => getOlderLogs(macAddress)}
                    allowScroll={!fetchOlderlogs}
                /> :
                <Typography style={{ padding: 15 }} >{`${'No logs found'} for ${form.location.name} - ${form.controller[index].name} (${form.controller[index].mac_address})`}</Typography>
            )})
            }

        </>
    )
}
export default LiteController