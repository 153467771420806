import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import ContainerHeader from "components/ContainerHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import API from "util/Api";
import { Box, AccordionActions, Button } from "@material-ui/core";
import { Formik, Form, useFormikContext } from "formik";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import FormField from "components/FormField";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    section: {
        flexBasis: "100%",
        flexShrink: 0,
        flexWrap: "wrap",
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.2)",
        borderBottomStyle: "solid",
        paddingBottom: 15,
        paddingTop: 15,
        "&:nth-last-child(1)": {
            borderBottomWidth: 0,
        },
    },
    field: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    panelHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.secondary.main,
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    panelSubheading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        textAlign: "right",
    },
    expanded: {
        backgroundColor: "#efefef",
    },
    panelRoot: {
        flexWrap: "wrap",
        display: "block!important",
    },
    panelAction: {
        flex: 1,
        justifyContent: "flex-end",
    },
    addButton: {
        margin: "10px 0",
    },
}));

const Config = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { isSubmitting } = useFormikContext();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [errorMessage, setErrorMessage] = useState("");
    const [isShowing, setisShowing] = useState(false);
    const [loading, setisLoading] = useState(false);

    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
    });

    const saveConfig = (e, { setSubmitting }) => {
        setSubmitting(true);
        setisLoading(true);

        API.post("accessSubLocations", {
            ...e,
            ...{ location_id: props.locationId },
        })
            .then((res) => {
                props.setSubLocations(res.data.data.subLocations);
                setErrorMessage("Configuration saved");
                setisShowing(true);
                setSubmitting(false);
                setisLoading(false);
            })
            .catch((err) => {
                setErrorMessage("Request Failed. Please Try Again!!");
                setisShowing(true);
                setSubmitting(false);
                setisLoading(false);
            });
    };

    const handleClose = () => {
        setisShowing(false);
        setErrorMessage("");
    };

    const handleCloseReject = () => {
        setisShowing(false);
        setErrorMessage("");
    };

    return (
        <>
            {loading && (
                <div>
                    <LinearProgress color="primary" />
                    <LinearProgress color="primary" />
                </div>
            )}
            {!loading && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ expanded: classes.expanded }}
                    >
                        <Typography className={classes.panelHeading}>
                            {props.title ? props.title : "New Sub Location"}
                        </Typography>
                        <Typography className={classes.panelSubheading}></Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.panelRoot }}>
                        <Formik
                            initialValues={props.subLocation}
                            validationSchema={validations}
                            onSubmit={saveConfig}
                        >
                            <Form>
                                <div className="row">
                                    {props.config.fields.map((field) => (
                                        <div className={`${classes.field} col-sm-4 ${(field.size === "lg" ? "col-lg-4" : "col-lg-3 col-xl-2")}`}>
                                            <FormField field={{ ...field, ...{ name: field.key } }} />
                                        </div>
                                    ))}
                                </div>
                                <div className={classes.panelAction}>
                                    <AccordionActions>
                                        <Button size="small" type="reset" disabled={isSubmitting}>Cancel</Button>
                                        <Button size="small" color="primary" type="submit" disabled={isSubmitting}>Save</Button>
                                    </AccordionActions>
                                </div>
                            </Form>
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            )}
            {errorMessage !== "" && (
                <Dialog
                    open={isShowing}
                    onClose={handleClose}
                    fullWidth={true}
                    fullScreen={fullScreen}
                >
                    <DialogContent color="secondary">
                        <Typography variant={"body1"}>{errorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Box display="inline-block">
                            <Button
                                onClick={handleCloseReject}
                                variant="outlined"
                                className={classes.button}
                            >
                                OK
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

const SubLocations = (props) => {
    const [config, setConfig] = useState();
    const [subLocations, setSubLocations] = useState();
    const classes = useStyles();

    useEffect(() => {
        API.get("accessSubLocations", {
            params: {
                location_id: props.location.state.id.value,
            },
        })
            .then((res) => {
                setSubLocations(res.data.data.subLocations);
                setConfig(res.data.data.config);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props.location.state.id]);

    const addConfig = () => {
        let newField = {};
        for (var index in config.fields){
            newField[config.fields[index].key] = config.fields[index].value;
        }
        setSubLocations([...subLocations, {...newField}]);
    };

    return (
        <div>
            <ContainerHeader
                title={"Sub Locations - " + props.location.state.name.value}
                match={props.match}
            />
            <div className="row">
                {
                    <div className="col-12">
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.addButton}
                            startIcon={<PlusOneIcon />}
                            onClick={addConfig}
                        >
                            Sub Location
                        </Button>
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12">
                    {subLocations && config && subLocations.map((subLoc, index) => {
                        return (
                            <Config
                                key={`sub-loc-${index}`}
                                config={config}
                                index={index}
                                subLocation={subLoc}
                                locationId={props.location.state.id.value}
                                title={subLoc.name}
                                setSubLocations={setSubLocations}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default SubLocations;