import React from "react";
// import {getReports} from './data';
import ContainerHeader from "components/ContainerHeader";
import ReportTable from "components/ReportTable/index";
import ReportFilter from "components/ReportTable/ReportFilter";
import CardBox from "components/CardBox";
import { Box, TextField, Typography, Grid } from "@material-ui/core";
import API from "util/Api";
import { EZLineChart, PaymentModesPieChart } from "components/Chart"; 
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import ReportCounts from "components/Cards/ReportCounts";
import PopUpDialog from "components/PopUpDialog";
import SnackBar from "components/SnackBar";
import { saveAs } from "file-saver"
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import CallMadeIcon from '@material-ui/icons/CallMade';
import BasicReportCharts from "./Charts";

const DialogBox = (props) => {
  console.log("dialog-props", props);
  const [amount, setAmount] = React.useState({
    paidAmount: props.clicked.paid_hidden,
    payableAmount: props.clicked.payable_hidden,
  });
  const [dialogData, setDialogData] = React.useState({
    parkingId: props.clicked.parking_id_hidden,
  });

  React.useEffect(() => {
    props.setDialogData(dialogData);
  }, [dialogData]);

  return (
    <Grid container>
      <Grid item xs={5}>
        <TextField
          id="standard-basic"
          label="Entry Amount"
          name="paidAmount"
          value={amount.paidAmount}
          onChange={(e) => {
            console.log("dialog-form", e);
            setAmount({ ...amount, ["paidAmount"]: e.target.value });
            setDialogData({ ...dialogData, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          id="standard-basic"
          label="Exit Amount"
          disabled={props.clicked.active_hidden == 1 ? true : false}
          name="payableAmount"
          value={amount.payableAmount}
          onChange={(e) => {
            console.log("dialog-form", e);
            setAmount({ ...amount, ["payableAmount"]: e.target.value });
            setDialogData({ ...dialogData, [e.target.name]: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
};

class BasicReport extends React.Component {
  constructor(props) {
    super(props);
    console.log("BasicReport-props ---", this.props);
    this.state = {
      match: props.match,
      table: {},
      locations: {},
      vendors: {},
      charginglocations: [],
      accessLocations: [],
      period: ["Daily", "Monthly", "Annual"],
      reportTypes: ["Pay and park", "Subscription", "Both"],
      appState: "loading",
      reportFlags:
        props.reportFlags != undefined
          ? _.find(props.reportFlags, { name: this.props.match.params.report })
            .flags
          : localStorage.removeItem("token"),
      requestStatus: { msg: "", success: true },
      tags: [{ id: 0, name: "Clear", tags: null }],
      errorMessage: "",
      summaryBoxes: [],
      valetServiceAreas: [],
      valetLocations: [],
      subscription_type:
        props.match.params.report === "subscription"
          ? ["subscription", "revenue", "active-subscription-revenue"]
          : ["Active", "InActive", "Both"],
      dialog: {
        open: false,
        content: "",
        header: "",
        agreeBtnTxt: "",
        disAgreeBtnTxt: "",
        reset: null,
      },
      dialogData: {},
      snackBar: { open: false, msg: "", type: "error" },
      tableClass: props.match.params.report === "access" || "access-detail" || "daily-access" ? "px-4" : "",
      charts: {},
      valetBookingSummary:{},
    };
    this.getData = this.getData.bind(this);
    this.sendReportData = this.sendReportData.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.updateParkingAmount = this.updateParkingAmount.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.setDialogData = this.setDialogData.bind(this);
    this.updateClicked = this.updateClicked.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.downloadReceipt = this.downloadReceipt.bind(this);
    this.closeBooking = this.closeBooking.bind(this);
    this.closeParking = this.closeParking.bind(this);
  }

  getLocations() {
    API.get("location", {
      params:
        this.props.match.params.report === "access"
          ? { loc_type: "access" }
          : {},
    })
      .then((res) => {
        this.setState({
          locations: res.data.data ? res.data.data : [],
        });
        this.allLocations = res.data.data ? res.data.data : [];
        API.get("tags")
          .then((res) => {
            let tags = this.state.tags;
            for (var i = 0; i < res.data.data.Tags.table.length; i++) {
              tags.push(res.data.data.Tags.table[i]);
            }
            this.setState({ tags: tags, errorMessage: "" });
            API.get("vendors")
              .then((res) => {
                this.setState({
                  vendors: res.data.data ? res.data.data : [],
                });
                API.get("valetServiceAreas")
                  .then((res) => {
                    this.setState({
                      valetServiceAreas: res.data.data ? res.data.data : [],
                    });
                    API.get("valetLocations")
                      .then((res) => {
                        let valetLoc = [];
                        res.data.data.table.body.map((loc)=> {
                          let tempLoc={id:loc.id.value, name:loc.name.value};
                          valetLoc.push(tempLoc);
                        })
                        this.setState({
                          valetLocations: valetLoc.length>0 ? valetLoc : []
                        })
                      })
                      .catch((err) => {
                        this.setState({ errorMessage: "Please try again" });
                      });
                  })
                  .catch((err) => {
                    this.setState({ errorMessage: "Please try again" });
                  });
              })
              .catch((err) => {
                this.setState({ errorMessage: "Please try again" });
              });
            //charging locations
            API.get("chargingLocations")
              .then((res) => {
                console.log("chargingLocations-apiResponse", res);
                this.setState({
                  charginglocations: res.data.data ? res.data.data : [],
                });
                API.get("access_location")
                  .then((res) => {
                    this.setState({
                      accessLocations: res.data.data ? res.data.data.access_locations : [],
                      errorMessage: "",
                      appState: "active",
                    });
                  })
                  .catch((err) => {
                    // got an error
                    this.setState({ errorMessage: "Please try again" });
                  });
              })
              .catch((err) => {
                // got an error
                this.setState({ errorMessage: "Please try again" });
              });

          })
          .catch((err) => {
            this.setState({ errorMessage: "Please try again" });
          });
      })
      .catch((err) => {
        // got an error
        this.setState({ errorMessage: "Please try again" });
      });
  }

  componentDidMount() {
    this.getLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * this is the initial render
     * without a previous prop change
     */
    if (prevProps === undefined) {
      let report = _.find(this.props.reportFlags, {
        name: this.props.match.params.report,
      });

      //Setting subscription type depending on report
      var subscription_type =
        this.props.match.params.report === "subscription"
          ? ["subscription", "revenue", "active-subscription-revenue"]
          : ["Active", "InActive", "Both"];

      this.setState({
        reportFlags: report.flags,
        subscription_type: subscription_type,
      });
      console.log("flags ", report.flags);
      return false;
    }

    /**
     * new report?
     */

    if (prevProps.match.params.report !== this.props.match.params.report) {
      let report = _.find(this.props.reportFlags, {
        name: this.props.match.params.report,
      });
      //Setting subscription type depending on report
      var subscription_type =
        this.props.match.params.report === "subscription"
          ? ["subscription", "revenue", "active-subscription-revenue"]
          : ["Active", "InActive", "Both"];

      this.setState({
        appState: "active",
        table: {},
        charts:{},
        valetBookingSummary:{},
        errorMessage: "",
        reportFlags: report.flags,
        requestStatus: { msg: "" },
        subscription_type: subscription_type,
        tableClass: this.props.match.params.report === "access" ? "px-4" : "",
      });
    }
  }

  getData(params, cb) {
    this.setState({ appState: "waiting" });
    params.type = this.props.match.params.report;

    Object.keys(params).map(function (item, i) {
      if (item === "from" || item === "to")
        params[item] = new Date(params[item]).toISOString();
    });
    if(params.type === 'valet-bookings-v2'){
      API.get("reportChartsV2?data=" + JSON.stringify(params)).then((res) => {
        this.setState({
          charts: res.data.data.charts,
          valetBookingSummary: res.data.data.summary.length > 0 ? res.data.data.summary[0] : {}
        });        
      })
    }
    API.get("reportDetails?data=" + JSON.stringify(params))
      .then((res) => {
        // rcvd reports data
        var summaryBoxes = [];
        if (res.data.data.boxes) {
          // For reports where data is less we are showing cards for data
          summaryBoxes = _.cloneDeep(res.data.data.boxes.boxes);
          delete res.data.data.boxes;
        }
        this.setState({
          table: res.data.data,
          appState: "active",
          errorMessage: "",
          summaryBoxes: summaryBoxes,
        });
        cb()
      })
      .catch((err) => {
        this.setState({ errorMessage: "Please Try Again" });
        cb()
      });
  }

  sendReportData(params, cb) {
    params.type = this.props.match.params.report;

    API.get("report?data=" + JSON.stringify(params))
      .then((res) => {
        // rcvd reports data
        this.setState({ requestStatus: { msg: res.data.msg, success: true } });
        cb()
      })
      .catch((err) => {
        console.log('error', err)
        this.setState({
          requestStatus: {
            msg: err?.response?.data?.msg
              ? err.response.data.msg
              : "Unable to register your request,\n please try again in some time",
            success: false,
            status: err?.response?.data?.data?.status ? err.response.data.data.status : false
          },
        });
        cb()
      });
  }

  renderState(state) {
    switch (state) {
      case "loading":
      default:
        return (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" size={50} />
          </div>
        );
      case "active":
      case "waiting":
        return <div> {this.renderPage()} </div>;
    }
  }
  handleTagChange(e) {
    const value = e.target.value;
    if (
      value === 0 &&
      !_.isEqual(_.sortBy(this.state.locations), _.sortBy(this.allLocations))
    ) {
      this.setState({ locations: this.allLocations });
    } else {
      let locations = [];
      for (let i = 0; i < this.allLocations.length; i++) {
        if (
          this.allLocations[i].tag != null &&
          this.allLocations[i].tag.split(",").includes(value)
        )
          locations.push(this.allLocations[i]);
      }
      this.setState({ locations: locations });
    }
  }

  setDialogData(e) {
    console.log("setDialog-data", e);
    this.setState({ dialogData: e });
  }

  handleDialogClose() {
    this.setState({
      dialog: {
        open: false,
        content: "",
        header: "",
        agreeBtnTxt: "",
        disAgreeBtnTxt: "",
        reset: null,
      },
    });
  }
  updateParkingAmount(clicked) {
    this.setState({
      dialog: {
        clicked: clicked,
        open: true,
        content: (
          <DialogBox clicked={clicked} setDialogData={this.setDialogData} />
        ),
        header: "Update Amount",
        agreeBtnTxt: "Update",
        disAgreeBtnTxt: "Cancel",
        submit: this.updateClicked
      },
    });
  }
  downloadReceipt(clicked) {
    API.get("parkingReceipt", { params: clicked })
      .then((res) => {
        saveAs(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
 
  closeParking(clicked, disableBtn) {
    disableBtn(true);
    API.post("closeActiveParking", clicked)
      .then((res) => {
        this.handleDialogClose();
          if (res.data.msg) {
            this.setState({
              snackBar: { open: true, msg: res.data.msg, type: "success" },
            });
          }
      })
      .catch((err) => {
        console.log("error", err);
        this.handleDialogClose();
          this.setState({
            snackBar: {
              open: true,
              msg: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
              type: "error",
            },
          });
          disableBtn(false);
      });
  }

  closeBooking(clicked, disableBtn) {
    this.setState({
      dialog: {
        clicked: clicked,
        open: true,
        content: (
          <p>Are you sure, want to close this booking?</p>
        ),
        header: "Close Booking",
        agreeBtnTxt: "Yes",
        disAgreeBtnTxt: "No",
        submit: ()=>{ this.closeParking(clicked, disableBtn) }
      },
    });
  }
  updateClicked(e) {
    console.log("submit-data", this.state.dialogData);
    if (Object.keys(this.state.dialogData).length > 0) {
      API.put("parking", JSON.stringify(this.state.dialogData))
        .then((res) => {
          this.handleDialogClose();
          if (res.data.msg) {
            this.setState({
              snackBar: { open: true, msg: res.data.msg, type: "success" },
            });
          }
        })
        .catch((err) => {
          this.handleDialogClose();
          this.setState({
            snackBar: {
              open: true,
              msg: err?.response?.data?.msg
                ? err.response.data.msg
                : "Something went wrong",
              type: "error",
            },
          });
        });
    }
  }
  render() {
    return <div> {this.renderState(this.state.appState)} </div>;
  }

  renderPage() {
    let report = _.find(this.props.reportFlags, {
      name: this.props.match.params.report,
    });
    let title = report.title ? report.title : report.name;
    if (title === "free-exempted") {
      title = title.replace("-", "/");
    } else if (title === "payment-mode_v2") {
      title = "payment mode";
    } else if (title === "access-report") {
      title = "access";
    } else {
      title = title.replaceAll("-", " ");
    }

    return (
      <div>
        <ContainerHeader title={title + " Reports"} match={this.props.match} />
        <div className="row">
          <CardBox styleName="col-12">
            <ReportFilter
              locations={this.state.locations}
              valetLocations={this.state.valetLocations}
              period={this.state.period}
              reportType={this.state.reportTypes}
              getReportData={this.getData}
              sendReportData={this.sendReportData}
              requestStatus={this.state.requestStatus}
              onDialogClose={() => {
                this.setState({ requestStatus: { msg: "", success: true } });
              }}
              tags={this.state.tags}
              flags={
                _.find(this.props.reportFlags, {
                  name: this.props.match.params.report,
                }).flags
              }
              handleTagChange={this.handleTagChange}
              minFromDate={moment().subtract(39, "days").startOf("day")} // allowing 90 days to get data for now, need to revert to 40days, changed 40 to 20 days (by binod)
              maxToDate={moment(Date.now()).add(1, "days")}
              //minutesStep={15}
              vendors={this.state.vendors}
              charginglocations={this.state.charginglocations}
              subscription_type={this.state.subscription_type}
              valetServiceAreas={this.state.valetServiceAreas}
              accessLocations={this.state.accessLocations}
            />
          </CardBox>
        </div>
        {this.state.errorMessage !== "" && (
          <h3 className="row"> {this.state.errorMessage} </h3>
        )}
        {this.state.appState === "waiting" ? (
          <div>
            <LinearProgress color="primary" />
            <LinearProgress color="primary" />
          </div>
        ) : (
          <React.Fragment>
            {this.state.summaryBoxes.length > 0 && (
              <ReportCounts data={this.state.summaryBoxes} />
            )}
            {Object.keys(this.state.valetBookingSummary).length > 0 &&
              <BasicReportCharts charts={this.state.charts} headerSummary={this.state.valetBookingSummary} />
            }
            {Object.keys(this.state.table).length > 0 && (
              <ReportTable
                data={this.state.table}
                updateParkingAmount={this.updateParkingAmount}
                downloadReceipt={this.downloadReceipt}
                closeBooking={this.closeBooking}
                downloadCSVBtn={(this.props.match.params.report=='access-detail')}
                tableClass={this.state.tableClass}
              />
            )}
          </React.Fragment>
        )}
        <PopUpDialog
          fullWidth
          open={this.state.dialog.open}
          header={this.state.dialog.header}
          content={this.state.dialog.content}
          agreeBtnTxt={this.state.dialog.agreeBtnTxt}
          disAgreeBtnTxt={this.state.dialog.disAgreeBtnTxt}
          agreeClicked={this.state.dialog.submit}
          handleDialogClose={this.handleDialogClose}
        />
        <SnackBar
          open={this.state.snackBar.open}
          handleClose={() => {
            this.setState({
              snackBar: { open: false, msg: "" },
            });
          }}
          message={this.state.snackBar.msg}
          type={this.state.snackBar.type}
        />
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  try {
    return {
      reportFlags: _.concat(
        state.auth.authUser.reports,
        state.auth.authUser.subsc,
        state.auth.authUser.ezcharge,
        state.auth.authUser.valet,
        state.auth.authUser.valetv2,
        state.auth.authUser.access
      ),
      dashboardActions: state.auth.authUser.dashboardactions,
    };
  } catch (err) { }
};

export default connect(mapStateToProps)(BasicReport);
