import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton'
import CardMenu from '../CardMenu';
import ToolTipIcon from 'components/ToolTipIcon';

const CardHeader = (props) => {

  const [anchorE1, setAnchorE1] = useState(undefined);
  const [menuState, setMenuState] = useState(false);


  const onOptionMenuSelect = event => {
    setMenuState(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setMenuState(false);
  };

  const {heading, subHeading} = props;
  let {styleName} = props;

  return (
    <div className={`jr-card-header d-flex align-items-start ${styleName}`}>
      <div className="mr-auto">
        <h3 className="card-heading">{heading}</h3>
        {subHeading && <p className="sub-heading">{subHeading}</p>}
      </div>
      <ToolTipIcon
        title="Menu"
        ariaLabel="menu"
        className="icon-btn text-dark"
        icon={ <i className="zmdi zmdi-chevron-down"/> }
        onClick={onOptionMenuSelect}                                 
      />
      
    </div>
  )
};

export default CardHeader;
CardHeader.defaultProps = {
  styleName: '',
  subHeading: ''
};

