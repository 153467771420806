import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';

import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {

  componentDidMount() {

    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
        {this.props.reportData.reports ?     
          <li className="menu collapse-box" key="reports">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.report.title"/>
              </span>
            </Button>
            <ul  className="sub-menu">
                {this.props.reportData.reports.map((report) => {
                    return(<li key={report.name}>
                        <NavLink className="prepend-icon" to={"/app/reports/"+report.name}>
                          <span className="nav-text"><IntlMessages id={"dashboard.report."+report.name}/></span>
                        </NavLink>
                      </li>)
                })}
            </ul>
            </li> : ''
        }

          {this.props.reportData.historicaldata ?       
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.history.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
                {this.props.reportData.historicaldata.map((historical) => {
                    return(<li key={historical.name}>
                      <NavLink className="prepend-icon" to={"/app/historical/"+historical.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.history."+historical.name}/></span>
                      </NavLink>
                    </li>)
                })}             
            </ul>
          </li> : ''
          }
          {this.props.reportData.subsc ?     
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.subscriptions.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
                {this.props.reportData.subsc.map((subscriptions) => {
                    return(<li>
                      <NavLink className="prepend-icon" to={subscriptions.name === 'subscription' || subscriptions.name === 'subscription-revenue' ? "/app/reports/"+subscriptions.name : "/app/subscriptions/"+subscriptions.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.subscriptions."+subscriptions.name}/></span>
                      </NavLink>
                    </li>)
                })}             
            </ul>
          </li> : ''
          }
          {this.props.reportData.analytics ?     
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id={"dashboard.analytics"}/>
              </span>
            </Button>
            <ul className="sub-menu">
                {this.props.reportData.analytics.map((analytics) => {
                    return(<li>
                      <NavLink className="prepend-icon" to={"/app/Analytics/"+analytics.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.analytics."+analytics.name}/></span>
                      </NavLink>
                    </li>)
                })}             
            </ul>
          </li> : ''
          }
          {this.props.reportData.resources ?     
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.resources.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
                {this.props.reportData.resources.map((resources) => {
                    return(<li key={resources.name}>
                      <NavLink className="prepend-icon" to={"/app/resources/"+resources.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.resources."+resources.name}/></span>
                      </NavLink>
                    </li>)
                })}             
            </ul>
          </li> : ''
          }
          {this.props.reportData.ezcharge ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.EZcharge.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
            {this.props.reportData.ezcharge.map((ezcharge) => {
                    return(<li key={ezcharge.name}>
                      <NavLink className="prepend-icon" to={`/app/ezcharge/${ezcharge.name}`}>
                        <span className="nav-text"><IntlMessages id={"dashboard.EZcharge."+ezcharge.name}/></span>
                      </NavLink>
                    </li>)
                })}            
            </ul>
          </li> : ''
          }
          {this.props.reportData.ezcredits ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.EZCredits"/>
              </span>
            </Button>
            <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to={"/app/Ezcredits/bills"}>
                      <span className="nav-text"><IntlMessages id="dashboard.ApproveBills"/></span>
                    </NavLink>
                  </li>             
            </ul>
          </li> : ''
          }
          {this.props.reportData.manageorders ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.ManageOrders"/>
              </span>
            </Button>
            <ul className="sub-menu">
                {this.props.reportData.manageorders.map((orders) => {
                  return(<li key={orders.name}>
                    <NavLink className="prepend-icon" to={"/app/ManageOrders/"+orders.name}>
                      <span className="nav-text"><IntlMessages id={"dashboard.orders."+orders.name} /></span>
                    </NavLink>
                  </li>)
                }) 
                }
                             
            </ul>
          </li> : ''
          }
          {this.props.reportData.freerequests ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.freeRequests"/>
              </span>
            </Button>
            <ul className="sub-menu">
              {this.props.reportData.freerequests.map((free) => {
                    return(<li key={free.name}>
                      <NavLink className="prepend-icon" to={"/app/FreeRequests/"+free.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.freeRequests."+free.name}/></span>
                      </NavLink>
                    </li>)
                })}           
            </ul>
          </li> : ''
          }
          {this.props.reportData.appointments ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.appointments.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
            {this.props.reportData.appointments.map((appointments) => {
                    return(<li key={appointments.name}>
                      <NavLink className="prepend-icon" to={"/app/Appointments/"+appointments.name.replace(' ','')}>
                        <span className="nav-text"><IntlMessages id={"dashboard.appointments."+appointments.name.replace(' ','')}/></span>
                      </NavLink>
                    </li>)
                })} 
                        
            </ul>
          </li> : ''
          }
          {this.props.reportData.valetreimbursements ?
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="dashboard.valet.reimbursements.title"/>
              </span>
            </Button>
            <ul className="sub-menu">
            {this.props.reportData.valetreimbursements.map((reimbursements) => {
                    return(<li key={reimbursements.name}>
                      <NavLink className="prepend-icon" to={"/app/Valet/"+reimbursements.name.replace(' ','')}>
                        <span className="nav-text"><IntlMessages id={"dashboard.valet.reimbursements."+reimbursements.name.replace(' ','')}/></span>
                      </NavLink>
                    </li>)
                })} 
                        
            </ul>
          </li> : ''
          }
          {this.props.reportData.attendants ?
          <li key="attendants" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.attendants"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.attendants.map((attendant) => {
                    return(<li key={attendant.name}>
                      <NavLink className="prepend-icon" to={"/app/attendants/"+attendant.name.replace(' ','-')}>
                        <span className="nav-text"><IntlMessages id={"dashboard.attendants."+attendant.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  {/* <li key='attendantsStatus'>
                    <NavLink className="prepend-icon" to="/app/attendants">
                      <span className="nav-text"><IntlMessages id="dashboard.attendants.status"/></span>
                    </NavLink>
                  </li> */}
                  </ul>
          </li> : '' }
          {this.props.reportData.logs ?
          <li key="logs" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.logs"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.logs.map((log) => {
                    return(<li key={log.name}>
                      <NavLink className="prepend-icon" to={"/app/logs/"+log.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.logs."+log.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }
          {this.props.reportData.parkezads ?
          <li key="parkezAds" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.parkezAds"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.parkezads.map((parkezad) => {
                    return(<li key={parkezad.name}>
                      <NavLink className="prepend-icon" to={"/app/parkez-ads/"+parkezad.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.parkezAds."+parkezad.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }
          {this.props.reportData.valet ?
          <li key="valet" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.valet"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.valet.map((valet) => {
                    return(<li key={valet.name}>
                      <NavLink className="prepend-icon" to={valet.name==="valet-bookings"?"/app/reports/"+valet.name:"/app/valet/"+valet.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.valet."+valet.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }
          {this.props.reportData.valetv2 ?
          <li key="valetv2" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.valetv2"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.valetv2.map((valet) => {
                    return(<li key={valet.name}>
                      <NavLink className="prepend-icon" to={valet.name==="valet-bookings-v2"?"/app/reports/"+valet.name:"/app/valet-v2/"+valet.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.valetv2."+valet.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }
          {this.props.reportData.access ?
          <li key="valet" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.access"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.access.map((access) => {
                    return(<li key={access.name}>
                      <NavLink className="prepend-icon" to={"/app/access/"+access.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.access."+access.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }

          {this.props.reportData.users ?
          <li key="valet" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.users"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.users.map((user) => {
                    return(<li key={user.name}>
                      <NavLink className="prepend-icon" to={"/app/user/"+user.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.user."+user.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }

          {this.props.reportData.jobs ?
          <li key="valet" className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <span className="nav-text">
                    <IntlMessages id="dashboard.jobs"/>
                  </span>
                </Button>
                <ul className="sub-menu">
                {this.props.reportData.jobs.map((job) => {
                    return(<li key={job.name}>
                      <NavLink className="prepend-icon" to={"/app/job/"+job.name}>
                        <span className="nav-text"><IntlMessages id={"dashboard.job."+job.name}/></span>
                      </NavLink>
                    </li>)
                })}
                  </ul>
          </li> : '' }

          <li key="terms" className="menu">
                <Button>
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw"/>
                  <NavLink style={{display:"inline",padding:0}} to="/app/Terms-and-conditions">
                    <IntlMessages id="dashboard.terms"/>
                  </NavLink>
                </Button>
                
          </li>        
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
