import { Grid,Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import "../ParkingOverview/overview.css"
import { BarChartt, ChartSales, EZDoughnut, EZDoughnutSolid, HorizontalBarChart, StackedBarChart } from "components/Chart"; 
import CardBox from "components/CardBox";
import rupee from 'assets/images/rupee-sign.png'
import exit from 'assets/images/exit.png'
import entry from 'assets/images/entry.png'
import {FormControl,Tooltip, InputLabel} from '@material-ui/core'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import liveDevice from 'assets/images/live-devices.png'
import BeenhereIcon from '@material-ui/icons/Beenhere';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CheckIcon from '@material-ui/icons/Check';
import _ from 'lodash'

import { useEffect, useState } from "react";
const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            // margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:20
        },
        text:{
            // margin: 'auto',
            color: '#f9a825'
        },
        aurbisText:{
            color: '#000',
        }
    }
))
const summary = [
    {
        key : "entry",
        title : "Total Entry",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "exit",
        title : "Total Exit",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "active",
        title : "Total Active",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    }
]

const paymentMode = [
    {
        title:"Bike",
        key:"bike", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Car",
        key:"car", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Truck",
        key:"truck", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    }
]

const parkingAvailibity=[
    {
        client_name:"Doninos",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                totleSlot:"100",
                key:"car", 
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    },
    {
        client_name:"Trends",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                key:"car", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    }
] 
const ParkingAvailibity = (props)=>{
    
const formattedData = (data)=> { 
    return data.map((item) => {
        const availabilityData = item.avalibility.reduce((acc, vehicle) => {
          const [occupied, available] = vehicle.data.datasets[0].data;
          acc[`${vehicle.key}Total`] = parseInt(vehicle.totalSlots, 10);
          acc[`${vehicle.key}Available`] = available;
          acc[`${vehicle.key}Occupied`] = occupied;
          acc[`vehicleType`] = vehicle.title; // Add vehicle type

          return acc;
        }, {});
    
        return {
          clientname: item.name,
          ...availabilityData
        };
      });
}

    return(
        <CardBox  styleName="col-12 mt-5">
            <Grid container className="align-items-center mb-3" direction='row' >
                <Typography  variant={'h6'} style={{fontSize:'16px',marginBottom:15}}>Parking Availability</Typography>
                <StackedBarChart data={formattedData(props.clientSlots)} />
            </Grid>
        </CardBox>
    )
}
const PaymentModeCard =(props)=>{
    console.log("PaymentModeCard-",props,'aaa')

    const [selectedCarState,setSelectedCarState] = useState('hourly');
    const [selectedBikeState,setSelectedBikeState] = useState('hourly');
    const [availableStates,setAvailableStates] = useState(['hourly']);
    const [selectedState, setSelectedState] = useState();    
    const [vehicleTypes,setVehicleType] = useState() 
    const [lengthOfStayData, setLengthOfStayData] = useState({});


      useEffect(() => {
        // Compute unique vehicle types
        let computedVehicles = [];
        props.paymentMode?.paymentMode?.forEach((data) => {
            computedVehicles.push(data.title);
        });
        computedVehicles = [...new Set(computedVehicles)].filter(data => data !== 'Revenue');
        setVehicleType(computedVehicles);
        // Initialize lengthOfStayData based on computed vehicles
        const initialData = computedVehicles.reduce((acc, type) => {
            acc[type] = [];
            return acc;
        }, {});
        const vTypes = computedVehicles.reduce((acc, value) => {
            acc[value] = 'hourly'; // Add the value as both key and value
            return acc;
        }, {});
        console.log(vTypes,'vTypes')
        setSelectedState(vTypes)
        setLengthOfStayData(initialData);
        updateLengthOfStayData(computedVehicles)
    }, [props.lengthOfStay]);

    const handleStateChange = (event, type) => {
        const { value } = event.target;
        // Update the selected state dynamically based on the vehicle type
        setSelectedState((prevState) => ({
          ...prevState,
          [type]: value,
        }));
    };
    const [utilizationData, setUtilizationData] = useState({});

    const [hasRevenue,sethasRevenue] = useState();
    const labels = props.paymentMode?.paymentMode?.[0]?.data.labels;
    const data = props.paymentMode?.paymentMode?.[0]?.data.datasets[0].data;

    const getRevenue = (type => {
        const index = labels?.indexOf(type);
        return index !== -1 ? data[index] : null;

    })
    const getTotalSlot = (type)=>{
        
        let vehicleSlot = _.find(props.paymentMode.slotByVehicleType,{type:type})
        return vehicleSlot?.slots;
    }
    const getAvailableSlot = (type)=>{
        let vehicleSlot = _.find(props.paymentMode.slotByVehicleType,{type:type})
        let availableSlot = vehicleSlot?.slots-vehicleSlot?.occupied
        return availableSlot>0?availableSlot:0;
    }
    const colourPalette = [
        '#bdade4', // Light Yellow
        '#9ee9d1', // Soft Yellow
        '#f9c880', // Golden Yellow
        '#f399a3', // Dark Yellow
        '#ebdc78', // Amber Yellow
        '#FFCA28', // Mustard Yellow
        '#FFD54F', // Light Gold
        '#FF6F00', // Dark Gold
      ];
    useEffect(()=>{
        setutilizationData()
        sethasRevenue(checkPaymentMode(props.paymentMode?.paymentMode[0].data?.datasets[0]?.data))
    },[selectedCarState,selectedBikeState,props.utilizationSata])
    
    const setutilizationData = ()=>{

     const utilizationData = {};
        const stateData = props.utilizationSata || []; // Extract the data array
        console.log(stateData,'stateData')
        // Loop through each item in the state's data array
        stateData.forEach((item) => {
            // Loop through keys in the item
            Object.keys(item).forEach((key) => {
                if (key !== 'name' && key !== 'name_sort') { // Ignore non-type keys
                    utilizationData[key] = utilizationData[key] || []; // Initialize if not present

                    // Push formatted data into the correct type array (bike, car, etc.)
                    utilizationData[key].push({
                        type: key,
                        count: item[key] ? parseInt(item[key]) : 0,
                        name: item.name,
                        name_sort: item.name_sort,
                        state: 'hourly', // Include the state for context if needed
                    });
                }
            });
        });
    
        console.log(utilizationData,'welcome'); 
    
        setUtilizationData(utilizationData);
    }
      // Function to handle data merging for each mode
    const getMergedData = (modes) => {
        const mergedData = [];

        // Iterate over the labels and their corresponding values
        const labels = props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data.labels;
        const dataSets = props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data.datasets[0]?.data;
        
        console.log(labels,dataSets,'dataSets')
        if (labels && dataSets ) {
          labels.forEach((label, i) => {
            const value = dataSets[i];
            const color = colourPalette[i];
      
            // Check if the label already exists in mergedData
            const existingLabel = mergedData.find(item => item.label.toLowerCase() === label.toLowerCase());
      
            if (existingLabel) {
              // If label exists, add the new value to the existing one
              existingLabel.data += parseInt(value);
            } else {
              // If label does not exist, add the new label and its value
              mergedData.push({
                data: parseInt(value),
                label: label.toUpperCase(),
                color: color
              });
            }
          });
        }
        console.log(mergedData,'mergedData')
        return mergedData;

    };

    const updateLengthOfStayData = (computedVehicles)=> {
        const LSBikeData = props.lengthOfStay?.data || [];
        const newData = computedVehicles.reduce((acc, type) => {
          acc[type] = LSBikeData.map((item) => ({
            type,
            count: item[type] ? item[type] : 0,
            name: item.hour,
            color: props.lengthOfStay?.legends.find((vehicle) => vehicle.name === type)?.color || "#000",
          }));
          return acc;
        }, {});
    
        setLengthOfStayData(newData);
    }
    const checkPaymentMode = (data) => {
        console.log(data,'checkPaymentMode')
        if (data.length === 0)
            return false
        return data.some(num => num > 0);
    };
    const makePieChartData = (data)=>{
        let finalData = []
        data?.labels?.map((label,index)=>{
            let tempData = {
                data  : parseInt(data.datasets[0].data[index]),
                label : label,
                color : data.datasets[0].backgroundColor[index]
            }
              // Check if the label already exists in finalData
            const existingLabel = finalData.find(item => item.label.toLowerCase() === label.toLowerCase());
            if (existingLabel) {
                // If label exists, add the new data to the existing value
                existingLabel.data += tempData.data;
            } else {
                // If label does not exist, push the new tempData
                finalData.push(tempData);
            }
          })
          console.log(finalData,'finalData')
          return finalData
    }
    const cropDataToCurrentTime = (data) => {
        const timeSlots = [
            "12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", 
            "10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", 
            "8pm", "9pm", "10pm", "11pm"
          ];
        
          // Create a map of data by name for quick lookup
          const dataMap = data.reduce((acc, item) => {
            acc[item.name] = item;
            return acc;
          }, {});
        
          // Get the current time period (am/pm)
          const currentTime = new Date();
          const currentHour = currentTime.getHours();
          const currentPeriod = currentHour >= 12 ? "pm" : "am";
          const formattedCurrentHour = (currentHour % 12 === 0 ? 12 : currentHour % 12) + currentPeriod;
        
          // Fill in missing data and set count to 0 for missing times up to the current time
          const filledData = timeSlots.filter((timeSlot) => {
            const [hour, period] = timeSlot.split(/(?=[ap]m)/);
            const itemHour = parseInt(hour, 10);
            const item24Hour = period === "pm" && itemHour !== 12 ? itemHour + 12 : itemHour;
            // Add missing data if not present and only include data up to the current hour
            return item24Hour <= currentHour + 1;
          }).map((timeSlot) => {
            if (dataMap[timeSlot]) {
              return dataMap[timeSlot];  // Return existing data
            } else {
              return { type : data[0].type, count: 0, name: timeSlot, state: 'hourly' };  // Return missing data with count 0
            }
          });
        
          return filledData;
      };
    return(
        <>
            {/* <Typography variant={'h5'}>Payment Mode</Typography> */}
            <Grid container className=" col-12"   >
                {
                    // console.log("he",props.paymentMode.paymentMode) &&
                    props.paymentMode.paymentMode.map((modes,index)=>{
                        const type = modes.title.toLowerCase(); // Ensure matching keys with utilizationData
                        let data = utilizationData[type]?.filter((utData)=>{return utData.state == selectedState[type]}); 
                        if(data)
                            data = cropDataToCurrentTime(data) 
                        if (modes.title.toLowerCase() === "revenue") {
                            return null; // Skip this iteration
                          }
                        return (
                        <Grid item  className="jr-card  col-lg-12  col-md-12 p-0 mt-5  " style={{borderRadius:10}}  >
                        {/* BIKE PAYMENT MODE */}
                        <Grid container  direction="column" className="paymentModeContainer">
                            <Grid container className="paymentModeHeader" >
                                <Grid item style={{fontSize:'1.5rem'}} className="d-flex align-items-center">
                                    {
                                        (modes.title === 'bike') ? 
                                                <MotorcycleIcon className="icons" />:
                                            modes.title === 'car' ? 
                                                <DriveEtaIcon className="icons" />:
                                            modes.title === 'mini-bus' ? 
                                                <AirportShuttleIcon  className="icons"/> : 
                                            (modes.title === 'hcv') ?
                                                <LocalShippingIcon className="icons" /> : 
                                           ( modes.title === 'bus' || modes.title === 'lcv') ? 
                                                <DirectionsBusIcon className="icons"/> : 
                                        <LocalShippingIcon className="icons" />
                                    }
                                     {modes.title.toUpperCase()} 
                                </Grid>
                                <Grid container direction="row" className="paymentModeHeaderContent mt-1 col-12" spacing={2}>
                                    <Tooltip title="Total Slots">
                                        <Grid item xs={12} sm={6} md={2} >
                                            <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><LocalParkingIcon className="headericons"/><div  style={{padding:'10px 0px 6px 0px',fontSize:'1.5rem'}}>{getTotalSlot(modes.title)}</div></div> 
                                                <div> SLOTS </div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Avilable Slots">
                                        <Grid item xs={12} sm={6} md={2} >
                                            <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><CheckIcon className="headericons"/><div  style={{padding:'10px 0px 6px 0px',fontSize:'1.5rem'}}>{getAvailableSlot(modes.title)}</div></div> 
                                                <div> AVAILABLE </div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Total Entry">
                                        <Grid item xs={12} sm={6} md={2}>
                                            <div className="d-flex flex-column align-items-end" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><CallMadeIcon className="headericons"/><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[0]?.data?.find(vehicle => vehicle.type === modes.title)?.count ?? 0)}</span></div>
                                                <div> ENTRY</div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                    
                                    <Tooltip title="Total Exit">
                                        <Grid item xs={12} sm={6} md={2}>
                                            <div className="d-flex flex-column align-items-end" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><CallReceivedIcon className="headericons"/><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[1]?.data?.find(vehicle => vehicle.type === modes.title)?.count ?? 0)}</span></div>
                                                <div> EXIT</div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Active vehicle count">
                                        <Grid item xs={12} sm={6} md={2}>
                                            <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><BeenhereIcon  className="headericons" /><span style={{fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(props.paymentMode?.summary[2]?.data?.find(vehicle => vehicle.type === modes.title)?.count ?? 0)}</span></div>
                                                <div> ACTIVE</div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Total Revenue">
                                        <Grid item xs={12} sm={6} md={2} >
                                            <div className="d-flex align-items-end flex-column" style={{maxWidth: 'max-content'}}>
                                                <div className="d-flex align-items-center"><div className="rupeeIcon">₹</div><div  style={{padding:'10px 0px 6px 0px',fontSize:'1.5rem'}}>{new Intl.NumberFormat('en-IN').format(getRevenue(modes.title))}</div></div> 
                                                <div > REVENUE</div>
                                            </div>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {/* UTILIZATION */}
                            <Grid container className="paymentBody col-12 p-0">
                                <Grid item direction='column' style={{borderRight:'2px solid rgb(222, 226, 230)',padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center mb-4">
                                    <Grid container className="align-items-center mb-3" direction='row' >
                                    <Typography  variant={'h6'} style={{fontSize:'16px'}}>Utilization (%)</Typography>
                                    {
                                        selectedState && 
                                      <FormControl  FormControl className="col-md-2  stateSelection " style={{ marginLeft: "auto" }}>
                                            <InputLabel >PERIOD</InputLabel>
                                            <Select
                                                name={modes.title}
                                                value={selectedState[modes.title]}
                                                onChange={(e) => handleStateChange(e, modes.title)}
                                                input={<Input id="ageSimple1" />}
                                            >
                                                {availableStates.map(period => (
                                                    <MenuItem value={period}>
                                                        {period}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    </Grid>
                                    {
                                        data &&                                             
                                            <ChartSales data={data} height={hasRevenue? 250 :400}/>
                                    }
                                    
                                </Grid>
                                {
                                    hasRevenue ?
                                    <Grid container direction="column"  className="col-xl-6 col-lg-6 col-md-12 pb-3 d-flex align-items-center justify-content-center">
                                    
                                        <>
                                            <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18}}>Payment Modes</Typography>
                                            <Grid > 
                                                <EZDoughnutSolid type='bike' data={makePieChartData(props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data)}/>  
                                            </Grid>
                                            <Grid item style={{ width: '100%',marginTop:10 }}>
                                                <div className="d-flex flex-wrap justify-content-center w-100">
                                                    {/* {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data.labels.map((label, i) => (
                                                    <div key={i} className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                                        <div style={{ width: '12px', height: '12px', backgroundColor: colourPalette[i], marginRight: '5px', borderRadius: '50%' }} />
                                                        <span>{label.toUpperCase()} - {props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data.datasets[0].data[i]}</span>
                                                    </div>
                                                    ))} */}
                                                   {
                                                        // getMergedData(modes);
                                                        
                                                        getMergedData(modes).map((item, i) => {
                                                            return (
                                                                <>
                                                                 <div key={i} className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                                            <div 
                                                                style={{ width: '12px', height: '12px', backgroundColor: colourPalette[i], marginRight: '5px', borderRadius: '50%' }} 
                                                            />
                                                            <span>{item.label.toUpperCase()} - {new Intl.NumberFormat('en-IN').format(item.data)}</span>
                                                            </div></>
                                                            )
                                                           
                                                        })
                                                    }
                                                    <div className="d-flex align-items-center" style={{ margin: '0 6px 8px 0' }}>
                                                    <div style={{ width: '12px', height: '12px', backgroundColor: '#999999', marginRight: '5px', borderRadius: '50%' }} />
                                                    <span>Total - {new Intl.NumberFormat('en-IN').format(props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === modes.title)?.data.datasets[0].data.reduce((acc, val) => acc + parseInt(val), 0))}</span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </>
                                        </Grid>
                                        :
                                        <Grid item direction='column' style={{padding:'0 0 10px 0'}} className="col-lg-6 col-md-12 p-2 d-flex align-items-center justify-content-center">
                                            <Typography  variant={'h6'} style={{fontSize:'16px',alignSelf:'flex-start',marginTop:18,marginBottom:10}}>Length of Stay</Typography>
                                                
                                            <HorizontalBarChart data={lengthOfStayData[modes.title]} color={colourPalette[(index % 8)+1]}/>
                                        </Grid>
                                    }     
                                    {/* </Grid>  */}
                                    {/* // <BarChartt data={props.paymentMode?.paymentMode?.find(vehicle => vehicle.title === 'bike')?.data} style={{padding:4,alignSelf:'center'}}/> */}
                            </Grid>
                            
                        </Grid>
                        </Grid>
                        )
                    })
                }
             
            </Grid>
        </>
    )
}
const OverviewDetails = (props) =>{
    const classes = useStyles()
    console.log("OverviewDetails-",props.data)
    // console.log("OverviewDetails-",props.locationId)
    const isAurbis = props.data.theme === 'aurbis' ? false : false

    return (
        <>
            <div className="row row justify-content-center col-12 mt-5">
                {
                props.data?.summary?.map((item,index)=>(
                <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10 ">
                    <div style={{borderRadius:"10px"}} className={`jr-card p-0 overflow-hidden card-entire-box${index+1}`}>
                        <div className='card-designer'></div>
                        <div className={`row m-0 card-body box${index+1}`}>
                            <div className={`rounded-container-parent card-entire-box${index+1}`}>
                                <div className='rounded'>
                                        <img className='rounded-image' alt='' src={item.title === 'Total Active' ?  liveDevice : item.title === 'Total Entry' ?  entry :item.title === 'Total Exit' ?  exit :  rupee}/>
                                </div>
                            </div>
                            <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                <Typography variant='h6' className='card-header-title text-white'>{item.title.split(' ')[1]}</Typography>
                                <Typography variant='h5' className='text-white' style={{fontSize:'1.8rem'}}>{item.totalCount}</Typography>
                            </div>

                        </div>
                    </div>
                </div>   
                ))
                }
                {/* TOTAL REVENUE */}       
                <div className="col-xl-3 col-lg-6 col-md-10 col-sm-10">
                    <div style={{borderRadius:"10px"}} className={`jr-card p-0 overflow-hidden card-entire-box${3+1}`}>
                        <div className='card-designer'></div>
                        <div className={`row m-0 card-body box${3+1}`}>
                            <div className={`rounded-container-parent card-entire-box${3+1}`}>
                                <div className='rounded'>
                                        <img className='rounded-image' alt='' src={rupee}/>
                                </div>
                            </div>
                            <div className='d-flex align-items-flex-start justify-content-center flex-column w-50 ml-2'>
                                <Typography variant='h6' className='card-header-title text-white'>Revenue</Typography>
                                <Typography variant='h5' className='text-white' style={{fontSize:'1.8rem'}}>{new Intl.NumberFormat('en-IN').format(props.data?.paymentMode[0].data?.datasets[0]?.data.reduce((acc, val) => acc + val, 0))}</Typography>
                            </div>

                        </div>
                    </div>
                </div>         
            </div>
            
            {/* { checkPaymentMode(props.data?.paymentMode[0].data?.datasets[0]?.data) && <PaymentModeCard paymentMode={props.data} ></PaymentModeCard> } */}
             <PaymentModeCard paymentMode={props.data} utilizationSata={props.utilizationSata} lengthOfStay={props.lengthOfStay} ></PaymentModeCard> 
            
            {
                props.data?.clientSlots.length>0  &&
                <>
                    <ParkingAvailibity clientSlots={props.data.clientSlots} ></ParkingAvailibity>
                </>
            }
        </>
    )
    
}

export default OverviewDetails