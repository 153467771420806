import React, { useEffect } from 'react'
import { Form, connect } from 'formik'
import { Button, Box } from '@material-ui/core'
import FormField from 'components/FormField'
import _ from 'lodash'
import CardBox from 'components/CardBox';

const UserDetails = (props) => {
    
    useEffect(()=>{
        if(props.user){
            let data ={}
            for(let key in props.user){
                data[key]  = props.user[key].value
            }
            console.log("new values",data)
            console.log("old values",props.formik.values)
            if(!_.isEqual(data,props.formik.values)){
                props.formik.setValues(data)
            }
        }
    },[props.user]) // note: run this only if props.user changes , 
                    // we can't add props.formik in dependency as it will change 
                    // when we chnage the form values
    
    return(
        <>
        { Object.keys(props.config).length >0 &&
            <CardBox styleName="col-12 p-0" >
            
                <Form>
                    {/* FIRST ROW */}            
                    <div className="row">
                        { props.config.map(field => (
                            <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{...field,...{name:field.key}}}/>
                            </div>
                        ))
                        }                 
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">   
                    <div className="col-lg-6 col-sm-12 col-12">                    
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" disabled={props.formik.isSubmitting}> Submit </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset" disabled={props.formik.isSubmitting}>Reset</Button></Box>
                        </Box>
                    </div> 
                    </div>
                </Form>
            </CardBox>
        }
        </>
    )
}

export default connect(UserDetails)