/*
 * File: FormFilter.js
 * Project: parkezdashboard
 * File Created: Thursday,  24th March 2022 10:57:52 am
 * Author: Smit (smit@valetez.com)
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, {useState,useEffect,useRef} from 'react'
import {FormControl,InputLabel,Select,Input,Chip,MenuItem,FormHelperText,FormGroup,FormControlLabel,Checkbox,Button,Box,TextField} from '@material-ui/core'
import { DatePicker } from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Field } from 'formik';
import dayjs from 'dayjs';

import { makeStyles } from '@material-ui/core/styles'

import _ from 'lodash'
import moment from 'moment'
import CustomDateTimePicker from 'components/customDateTimePicker/CustomDateTimePicker'

const useStyles = makeStyles( (theme) =>(
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight:'1em'
          },
        text: {
            color: theme.palette.secondary.main
          },
        label:{
            color: theme.palette.primary.main,
        },
        button:{
            backgroundColor:theme.palette.secondary.light,
            color:'white'
        },
        underline:{
            display:'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop:6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon:{
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15:{marginBottom:"15px"},
        md20:{marginBottom:"20px"},
        md30:{marginBottom:"30px"},
      }
))

const FormFilter =(props)=> {
    console.log("FormFilter",props)
    const classes = useStyles();
    
    const didMountRef = useRef(false)    
    
    const [isShowing, setIsShowing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)    
    
    const [input, setInput] = useState({
        location:props.locations && props.locations.length > 0 ? (props.flags.multiLocation ? [props.locations[0].id] : props.locations[0].id) : 0,
        details_show:false,
        from: props.maxToDate ? getRoundedDate(15,new Date(props.maxToDate)) : getRoundedDate(15,new Date()),
        to:props.maxToDate ? getRoundedDate(15,new Date(props.maxToDate),false) : getRoundedDate(15,new Date(),false),
        dateTime: getRoundedDate(15,new Date()),
        vehicle:props.locations && props.locations.length > 0 ? _.cloneDeep(props.locations[0].vehicle_types) : [],
        location_tag : props.tags && props.tags.length > 0 ? props.tags[0].id: 0,
        vendor:props.vendors && props.vendors.length > 0 ? (props.flags.multiVendor ? [props.vendors[0].id] : props.vendors[0].id) : 0,
        client:'',
        chargingLocation:props.charginglocations?.length > 0 ? (props.flags.multiChargingLoc ? [props.charginglocations[0].id] : props.charginglocations[0].id) : 0,
        subscription_type:props.subscription_type && props.subscription_type.length > 0 ? props.subscription_type[0] : '',
        amount:'',
        reason:'',
        parking_type:'Both',
        startDate: null,
        endDate: null,
        jobLocation: null,
        job: null
    })
 
    useEffect(() => {

        /**
         * Need to change the input location from string to array
         * depending on multiLocation flag
         */
        const setupLocation = () => {
            console.log('setupLocation',props.flags,input.location,input.jobLocation)
            if(!props.flags.multiLocation && Array.isArray(input.location)){
                setInput({...input,'location':input.location[0]})
            }else if(props.flags.multiLocation && !Array.isArray(input.location)){
                setInput({...input,'location':[input.location]})
            }
            
        }
        const setupVendor = () => {
            if(!props.flags.multiVendor && Array.isArray(input.vendor)){
                setInput({...input,'vendor':input.vendor[0]})
            }else if(props.flags.multiVendor && !Array.isArray(input.vendor)){
                setInput({...input,'vendor':[input.vendor]})
            }
            
        }
        
        if (didMountRef.current) {
            setupLocation()
            setupVendor()
        } else {
            console.log("ignore userEffect")
            didMountRef.current = true
        }
    },[props.flags,input])

    

    const closeDialog = () =>{
        setIsShowing(false)
        setIsLoading(false)
        if(props.onDialogClose) props.onDialogClose()        
    }

    const handleInputChange = (e,val) => {        
        const {name, value} = e.target
                
        console.log("name - "+name+ " value - "+value)
        // select manages the state internally 
        // we are doing this to store the status for the api calls
        if('details_show'===name){
            setInput({...input, 'details_show': e.target.checked})
        }else if('vehicle'===name){
            let vehicle = input.vehicle
            
            if(e.target.checked) vehicle.push(e.target.value)
            else _.remove(vehicle,(o) => {
                return o === e.target.value
            })
            setInput({...input, 'vehicle': vehicle})
        }else if ('location_tag' === name && props.flags.multiLocation){
            let locations = _.map(_.filter(props.locations, ((o) => { 
                if(o.tag) return _.includes(o.tag.split(','), value)
            })),'id')
            setInput({...input,'location':locations,[name]:value})
        }else{
            setInput({...input, [name]: value})
        }
    }

    const handleLocationChange = (e,value) => {
        if(value){
            if(!props.flags.multiLocation)
                setInput({...input, 'location': value.id,"vehicle":_.cloneDeep(value.vehicle_types),"client":''})
            else{
                let locations = value.map(a => a.id)
                console.log('locations',locations)
                setInput({...input, 'location': value.map(a => a.id),"client":''})
            }                   
        }            
    }
    const handleVendorChange = (e,value) => {
        if(value){
            if(!props.flags.multiVendor)
                setInput({...input, 'vendor': value.id})
            else{
                let vendors = value.map(a => a.id)
                setInput({...input, 'vendor': value.map(a => a.id)})
            }                   
        }           
    }
    const handleClientsChange = (e,value) => {
        if(value){
            if(!props.flags.multiVendor)
                setInput({...input, 'client': value.id})
            else{
                setInput({...input, 'client': value.map(a => a.id)})
            }                   
        }           
    }
    const handleChargingChange = (e,value)=>{
        if(value){
            if(!props.flags.multiChargingLoc)
                setInput({...input, 'chargingLocation': value.id})
            else{
                setInput({...input, 'chargingLocation': value.map(a => a.id)})
            }                   
        }
    }
    const handleVsaChange = (e,value) => {
        if(value){
            setInput({...input, 'vsa': value.id})
        }           
    }

    const resetForm = ()=>{
        setInput({
            location:props.locations && props.locations.length > 0 ? (props.flags.multiLocation ? [props.locations[0].id] : props.locations[0].id) : 0,
            details_show:false,
            from: props.maxToDate ? getRoundedDate(15,new Date(props.maxToDate)) : getRoundedDate(15,new Date()),
            to:props.maxToDate ? getRoundedDate(15,new Date(props.maxToDate),false) : getRoundedDate(15,new Date(),false),
            dateTime: getRoundedDate(15,new Date()),
            vehicle:props.locations && props.locations.length > 0 ? _.cloneDeep(props.locations[0].vehicle_types) : [],
            location_tag : props.tags && props.tags.length > 0 ? props.tags[0].id: 0,
            vendor:props.vendors && props.vendors.length > 0 ? (props.flags.multiVendor ? [props.vendors[0].id] : props.vendors[0].id) : 0,
            client:'',
            chargingLocation:props.charginglocations?.length > 0 ? (props.flags.multiChargingLoc ? [props.charginglocations[0].id] : props.charginglocations[0].id) : 0,
            subscription_type:props.subscription_type && props.subscription_type.length > 0 ? props.subscription_type[0] : '',
            amount:'',
            reason:'',
            parking_type:'Both',
            startDate: null,
            endDate: null,
            jobLocation: null,
            job: null
        })
    }
    return(
        <div>            
            {/* FIRST ROW */}            
            <div className="row">
                {/* LOCATION DROPDOWN */}
                {props.flags.location && props.locations.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiLocation ? "col-lg-8" : "col-lg-4") }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={ props.flags.multiLocation 
                                ? (Array.isArray(input.location) 
                                    ? _.filter(props.locations, ((o) => { 
                                        return _.includes(input.location, o.id);
                                    }))    // find the locations which have id in input.location array
                                    : [])
                                : _.find(props.locations,{id:input.location})
                            }
                            multiple={props.flags.multiLocation}
                            onChange={handleLocationChange}
                            id="location"
                            name="location"
                            style={{marginTop:0}}
                            options={props.locations}
                            getOptionLabel={(option) => option.name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                <Chip variant="outlined" size="small"  color="primary" key={value} 
                                    className={classes.chip} 
                                    label={option.name}                                      
                                    {...getTagProps({ index })}
                                />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField id="location-input" {...params} margin="normal" />
                              )}
                        >

                        </Autocomplete>
                        
                    </FormControl>
                </div>
                }
                 {/* JOBS LOCATION DROPDOWN */}
                 {props.flags.jobLocation && props.locations.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiLocation ? "col-lg-8" : "col-lg-4") }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={ input.jobLocation}
                            multiple={props.flags.multiLocation}
                            // onChange={handleLocationChange}
                            onChange={(e,val) => setInput((prev) => ({ ...prev, jobLocation: val,job: null }))}
                            id="jobLocation"
                            name="jobLocation"
                            style={{marginTop:0}}
                            options={props.locations}
                            getOptionLabel={(option) => option.location_name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                <Chip variant="outlined" size="small"  color="primary" key={value} 
                                    className={classes.chip} 
                                    label={option.location_name}                                      
                                    {...getTagProps({ index })}
                                />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField id="location-input" {...params} margin="normal" />
                              )}
                        >

                        </Autocomplete>
                        
                    </FormControl>
                </div>
                }
                {/* Charging Locations DROPDOWN */}
                {props.flags.chargingLoc && props.charginglocations.length > 0 && 
                    <div className={"col-sm-6 col-12 " + (props.flags.multiChargingLoc ? "col-lg-8" : "col-lg-4") }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="chargingLoc-input" className={classes.label}>Charging Location</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={ props.flags.multiChargingLoc 
                                ? (Array.isArray(input.chargingLocation) 
                                    ? _.filter(props.charginglocations, ((o) => { 
                                        return _.includes(input.chargingLocation, o.id);
                                    }))    // find the locations which have id in input.location array
                                    : [])
                                : _.find(props.charginglocations,{id:input.chargingLocation})
                            }
                            multiple={props.flags.multiChargingLoc}
                            onChange={handleChargingChange}
                            id="chargingLoc"
                            name="chargingLoc"
                            style={{marginTop:0}}
                            options={props.charginglocations}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="chargingLoc-input" {...params} margin="normal" />
                              )}
                        >

                        </Autocomplete>
                        
                    </FormControl>
                </div>
                }
                {/* CLIENTS DROPDOWN */}
                {props.flags.clients  && 
                    <div className={"col-sm-6 col-12 col-lg-4" }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Client Name</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={ props.flags.multiClients 
                                ? (Array.isArray(input.client) 
                                    ? _.filter(props.clients, ((o) => { 
                                        return _.includes(input.client, o.id);
                                    }))    // find the locations which have id in input.location array
                                    : [])
                                : _.find(_.filter(props.clients,{locId:input.location}),{id:input.client})
                            }
                            multiple={props.flags.multiClients}
                            onChange={handleClientsChange}
                            id="client"
                            name="client"
                            style={{marginTop:0}}
                            options={_.filter(props.clients,{locId:input.location})}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="vendor-input" {...params} margin="normal" />
                              )}
                        >
                        </Autocomplete>
                    </FormControl>
                    </div>
                }
                {/* JOB TITLE DROPDOWN */}
                {props.flags.jobs  && 
                    <div className={"col-sm-6 col-12 col-lg-4" }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Job Title</InputLabel>
                        <Autocomplete
                            value={input.job}
                            multiple={props.flags.multiClients}
                            onChange={(e,val) => setInput((prev) => ({ ...prev, job: val }))}
                            id="job"
                            name="job"
                            style={{marginTop:0}}
                            options={input.jobLocation?.jobs ?? []}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField id="vendor-input" {...params} margin="normal" />
                              )}
                        >
                        </Autocomplete>
                    </FormControl>
                    </div>
                }
                {/* SELECT DATE */}
                {
                    props.flags.selectDate &&
                    <div className="col-lg-4 col-md-6 col-sm-12">            
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Select Date</InputLabel>
                        <CustomDateTimePicker selectedDate={input.dateTime} minDate={props.minDate && !props.flags.noDate ? props.minDate : new Date('2016-01-01')} handleDateChange={handleInputChange} name='dateTime' minutes={props.minutesStep} />                
                    </div> 
                }
                {/* VENDOR DROPDOWN */}
                {props.flags.vendor && props.vendors.length > 0 && 
                    <div className={"col-sm-6 col-12 " + (props.flags.multiVendor ? "col-lg-8" : "col-lg-4") }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Vendor</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={ props.flags.multiVendor 
                                ? (Array.isArray(input.vendor) 
                                    ? _.filter(props.vendors, ((o) => { 
                                        return _.includes(input.vendor, o.id);
                                    }))    // find the locations which have id in input.location array
                                    : [])
                                : _.find(props.vendors,{id:input.vendor})
                            }
                            multiple={props.flags.multiVendor}
                            onChange={handleVendorChange}
                            id="vendor"
                            name="vendor"
                            style={{marginTop:0}}
                            options={props.vendors}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="vendor-input" {...params} margin="normal" />
                              )}
                        >

                        </Autocomplete>
                        
                    </FormControl>
                </div>
                }
                {/* VALET SERVICE AREAS DROPDOWN */}
                {props.flags.valetServiceAreas && props.valetServiceAreas.length > 0 && 
                    <div className={"col-sm-6 col-12 col-lg-4" }>
                    <FormControl className="w-100">
                        <InputLabel shrink={true} htmlFor="vsa-input" className={classes.label}>Service Area</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={  _.find(props.valetServiceAreas,{id:input.vsa})
                            }
                            onChange={handleVsaChange}
                            id="vsa"
                            name="vsa"
                            style={{marginTop:0}}
                            options={props.valetServiceAreas}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="vsa-input" {...params} margin="normal" />
                              )}
                        >

                        </Autocomplete>
                        
                    </FormControl>
                </div>
                }

                {/* SUBSCRIPTION TYPE DROPDOWN */}
                {props.flags.subscription_type && 
                    <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100" mr="50px">
                        <InputLabel htmlFor="subscription_type" className={classes.label}>Type</InputLabel>
                        <Select
                            value={input.subscription_type}
                            name='subscription_type'
                            onChange={handleInputChange}
                            input={<Input id="subscription_type"/>}                        
                        >
                            {props.subscription_type.map(subsc => (
                            <MenuItem
                                key={subsc}
                                value={subsc}
                                style={{
                                    fontWeight: props.subscription_type.indexOf(subsc) !== -1 ? '500' : '400',
                                }}
                            >
                                {subsc}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </div>}
            
            
                {/* TAGS */}
                {props.flags.multiLocation &&
                <div className="col-lg-4 col-sm-6 col-12">
                    <FormControl className="w-100">
                        <InputLabel htmlFor="location_tag" className={classes.label} >Location Tags</InputLabel>
                        <Select
                            value={input.location_tag}
                            onChange={handleInputChange}
                            name="location_tag"
                            input={<Input id="location_tag"/>}                        
                    >
                            {props.tags.map((item, i) => (
                            <MenuItem
                                key={"location_tag-"+item.id}
                                value={item.id}
                                data-id={item.id}
                            >
                                {item.name}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>}
                
            </div>

            {/* THIRD ROW */}
            <div className={"row  mt-2"}>
                {props.flags.showDate && 
                    <>
                        {/* START DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">            
                            <FormHelperText className={classes.label}>Start Date</FormHelperText>
                            <CustomDateTimePicker selectedDate={input.from} minDate={props.minFromDate && !props.flags.noMinDate ? props.minFromDate : new Date('2016-01-01')} handleDateChange={handleInputChange} name='from' minutes={props.minutesStep} />                
                        </div>   
                        
                        {/* END DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">
                            <FormHelperText className={classes.label}>End Date</FormHelperText>
                            <CustomDateTimePicker selectedDate={input.to} minDate={input.from} handleDateChange={handleInputChange} name='to' minutes={props.minutesStep} maxDate={props.flags.noMinDate ? new Date() : props.maxToDate ? props.maxToDate : moment(input.from).add(90, 'days')}/>
                        </div>         
                    </>         
                }
                {props.flags.date && 
                    <>
                        {/* START DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">            
                            <FormHelperText className={classes.label}>Start Date (Opt)</FormHelperText>
                             <DatePicker
                                fullWidth
                                format="DD/MM/YYYY"
                                name = "startDate"
                                // minDate={input.from}
                                // label="Select Date"
                                value={input.startDate}
                                onChange={(val) => setInput((prev) => ({ ...prev, startDate: val._d }))}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            {/* <CustomDateTimePicker selectedDate={input.from} minDate={props.minFromDate && !props.flags.noMinDate ? props.minFromDate : new Date('2016-01-01')} handleDateChange={handleInputChange} name='from' minutes={props.minutesStep} />                 */}
                        </div>   
                        
                        {/* END DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">
                            <FormHelperText className={classes.label}>End Date (Opt)</FormHelperText>
                            {/* <CustomDateTimePicker selectedDate={input.to} minDate={input.from} handleDateChange={handleInputChange} name='to' minutes={props.minutesStep} maxDate={props.flags.noMinDate ? new Date() : props.maxToDate ? props.maxToDate : moment(input.from).add(90, 'days')}/> */}
                            <DatePicker
                                fullWidth
                                format="DD/MM/YYYY"
                                name = "endDate"
                                // minDate={input.from}
                                // label="Select Date"
                                value={input.endDate}
                                onChange={(val) => setInput((prev) => ({ ...prev, endDate: val._d }))}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                        </div>         
                    </>         
                }
                {/* PARKING TYPE */}
                {props.flags.parkingType &&
                    <div className="col-lg-4 col-sm-6 col-12">
                        <FormControl className="w-100">
                            <InputLabel htmlFor="parking_type" className={classes.label} >Type</InputLabel>
                            <Select
                                value={input.parking_type}
                                onChange={handleInputChange}
                                name="parking_type"
                                input={<Input id="parking_type"/>}                        
                        >
                                {props.parking_types.map((item, i) => (
                                    <MenuItem
                                        key={"parking_type-"+i}
                                        value={item}
                                        data-id={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                }
                {
                    props.flags.amount &&
                    <div className="col-lg-4 col-sm-12 col-12">
                        {/* Amount */}
                        <FormControl className="w-100">            
                            <FormHelperText className={classes.label}>Amount</FormHelperText>
                            <TextField name={'amount'} type='number' onChange={handleInputChange} value={input.amount} />
                        </FormControl>
                    </div>
                }
                {
                    props.flags.reason &&
                    <div className="col-lg-4 col-sm-12 col-12">
                        {/* Amount */}
                        <FormControl className="w-100">            
                            <FormHelperText className={classes.label}>Reason</FormHelperText>
                            <TextField name={'reason'}  onChange={handleInputChange} value={input.reason} />
                        </FormControl>
                    </div>
                }
            </div>

            {/* FOURTH ROW */}

            {/* VEHICLE TYPES */}
            {props.flags.vehicleType && 
            <div className="row mt-2">   
                <div className="col-sm-12 col-12">
                    <FormControl className="w-100">            
                        <FormHelperText className={classes.label}>Vehicle Type</FormHelperText>
                        <FormGroup className="d-flex flex-row">
                        {_.findIndex(props.locations,{id:input.location}) > -1 &&
                        (_.find(props.locations,{id:input.location}).vehicle_types).map((item, i) => (
                            <FormControlLabel
                            key={'vehicleType-'+item}
                            control={
                                <Checkbox color="primary"                                    
                                    checked={input.vehicle.indexOf(item) !== -1}
                                    onChange={handleInputChange}
                                    name="vehicle"
                                    value={item}
                                />
                            }
                            label={item}
                            />
                        ))}                
                        </FormGroup>
                    </FormControl>
                </div>
            </div>}
        
            {/* BUTTONS */}
            <div className="row mt-2">   
                <div className="col-lg-6 col-sm-12 col-12 row">
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.onFormSubmit(input)}> {props.formSubmitText?props.formSubmitText:'Submit'} </Button></Box>
                    </Box>
                    {props.flags.resetForm && 
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" mr="5px" onClick={resetForm}> {'Reset'} </Button></Box>
                        </Box>
                    }
                </div> 
            </div>
        </div>
    )
}

const getRoundedDate = (minutes, d=new Date(),low=true) => {    
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date((Math.round(d.getTime() / ms) - (low ? 0 :-1)) * ms);

    return roundedDate
}

export default FormFilter