import React, {useEffect, useState} from 'react' 

import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core';
import {Table,TableBody,TableHead,TableRow,TablePagination,Box,IconButton,Button,FormControlLabel,Switch} from '@material-ui/core';
import CardBox from 'components/CardBox';
import MsgPopover from 'components/MsgPopover';
import {SwapHoriz,PhonelinkLock,LockOutlined} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon  from '@material-ui/icons/Clear';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SearchBox from './SearchBox';
import ToolTipIcon from 'components/ToolTipIcon';

const useStyles = makeStyles((theme) =>({
    root: {
        fontFamily:'Roboto'
    },
    header : {
        background : theme.palette.secondary.main,
        color:'white'
    },
    newItem : {
        backgroundColor:'rgb(2, 207, 137)',
        padding:'3px 10px',
        fontWeight:500,
        color:'rgb(255, 0, 0)'
    }
}))



const ActionsTable =(props)=> {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tBody,setTBody] = useState(props.table.body);

    useEffect(()=>{
        setTBody(props.table.body); 
    },[props.table])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    //filter table according to search
    const handleSearch = (searchValue)=>{
        console.log("handleSearch",props.table.body,searchValue)
        if(searchValue){
            var filter = [];
            props.table.body.map(o=>{
                console.log("loopkey",o);
                for(var key in o){
                    
                    if(!key.includes('hidden') && o[key] !=null && !Array.isArray(o[key]) && o[key].toString().toLowerCase().includes(searchValue.toLowerCase())){
                        filter.push(o);
                        break;
                    }
                }
            });
            console.log("filterdedArray",filter)
            setTBody(filter);
        }else{
            setTBody(props.table.body);
        }
    }
    const getHeaders = () => {
        return (
        <TableHead >
            <TableRow key={props.tableHeading.replace(' ','')+'table-head'}>
                {props.table.headers.map((n,index) => {
                    return <TableCell key={n+index} classes={{ head:classes.header,root:classes.root }} size="small">{n}</TableCell>
                })}
            </TableRow>
        </TableHead>
        )
    }
    const getTableBody = () =>{
        return(
            <TableBody>
                {
                    (rowsPerPage > 0
                        ? tBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tBody
                        ).map((n,index) => {
                            console.log('display row',n)
                    return (
                        <TableRow key={props.tableHeading.replace(' ','')+'table-row-'+index}>
                            {Object.keys(n).map((item, i) => (
                                item.includes("hidden") ?
                                null
                                : (item === 'images' ?
                                <TableCell key={item+i}>
                                    <div>
                                    <Button onClick={() => {props.handleImageDialog(n)}}  variant="contained" className="jr-btn bg-light-green text-white">
                                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                        <span>View</span>
                                    </Button>                                                            
                                    </div>
                                </TableCell> 
                                :
                                <TableCell key={item+i} classes={{ head:classes.header,root:classes.root }} style={{color:item=='transaction_type' ? (n[item] == 'in' ? 'green' : 'red' ) : 'black'}} size="small">{n[item]}</TableCell>
                            )))}
                            {props.actions == true &&
                            <TableCell align="left">
                                {props.onEdit && 
                                    <ToolTipIcon
                                        title="Edit"
                                        ariaLabel="edit"
                                        icon={<EditIcon />}
                                        onClick={() => props.onEdit(n)}
                                    />
                                } 
                                {props.staffStatus && 
                                    <ToolTipIcon
                                        title='Staff Status'
                                        ariaLabel= 'staffStatus'
                                        icon={<LockOutlined style={{color:(n.hidden_active==1)?'green':'red'}} />}
                                        onClick={() => props.staffStatus(n)}
                                    />
                                }
                                {props.onEdit && 
                                    <ToolTipIcon
                                        title="Reset"
                                        ariaLabel="reset"
                                        icon={<PhonelinkLock style={{}} />}
                                        onClick={() => props.resetMobile(n)}
                                    />
                                } 
                                {props.onEnable &&                        
                                    <FormControlLabel
                                        control={<Switch color="primary" name="enabled" 
                                        checked={parseInt(n.hidden_status)>0} onClick={(e) => props.onEnable(n, e.target.checked)}/>}
                                    />
                                }
                            </TableCell>
                            }
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }
    const getDineInTable = () =>{
        return(
            <TableBody>
                 {
                    (rowsPerPage > 0
                        ? tBody.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tBody
                        ).map((n,index) => {
                    return (
                        <TableRow  key={props.tableHeading.replace(' ','')+'table-row-'+index}>
                            {Object.keys(n).map((item, i) => (
                                item.includes("hidden") ?
                                null
                                : (item === 'status' ? <TableCell style={{color:(n[item] == 'pending' || n[item] == 'new'  ? 'rgb(255, 0, 0)' : (n[item] == 'accepted' ? '#00dd5e' : (n[item] == 'completed' ? '0092dd' : '#FF0000')))}} key={item+i} classes={{ head:classes.header,root:classes.root,body:classes.pending }} size="small">{n[item] == 'accepted' ? 'preparing' :(n[item] === 'completed' ? 'served' : n[item]) }</TableCell> :
                                <TableCell key={item+i} classes={{ head:classes.header,root:classes.root }} size="small">{n[item]}</TableCell>
                            )))}
                            <TableCell align="left">
                                {props.table.details.status == 'pending' && 
                                    <div>
                                    <IconButton aria-label="Reject" onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}>
                                        <ClearIcon  />
                                    </IconButton>
                                    </div>
                                }
                                {n['status'] == 'pending' && (props.table.details.status == 'active' || props.table.details.status == 'new items')  && 
                                    <div>
                                    <IconButton aria-label="Accept" onClick={() => props.updateOrderStatus('accept_item',n,props.tableHeading)}>
                                        <DoneIcon  />
                                    </IconButton>
                                    <IconButton aria-label="Reject" onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}>
                                        <ClearIcon  />
                                    </IconButton>
                                    </div>
                                }  
                                {(n['status'] == 'accepted') && 
                                    <div>
                                    <IconButton aria-label="Complete" onClick={() => props.updateOrderStatus('complete',n,props.tableHeading)}>
                                        <DoneAllIcon  />
                                    </IconButton>
                                    <IconButton aria-label="Reject" onClick={() => props.updateOrderStatus('reject',n,props.tableHeading)}>
                                        <ClearIcon  />
                                    </IconButton>
                                    </div>
                                }  
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }
    return(
        <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
            <React.Fragment>
                <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box>
                </MsgPopover>
            </React.Fragment>}>
            <SearchBox handleSearch={handleSearch} />
            <div className="table-responsive-material">
                <Table>
                    {getHeaders()}
                    {props.dineIn ? getDineInTable() : getTableBody()}
                </Table>
                <TablePagination 
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={tBody.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> 
            </div>
              
        </CardBox>
    )

}
export default ActionsTable

