/*
 * File: index.js
 * Project: parkez-dashboard
 * File Created: Wednesday, 8th April 2020 3:51:11 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Friday, 15th May 2020 4:44:05 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React from 'react';
import RowFormatting from 'components/ReportTable/RowFormatting';
import CardBox from 'components/CardBox';
import MsgPopover from 'components/MsgPopover'
import {Alert} from 'reactstrap';
import {SwapHoriz} from '@material-ui/icons'
import { Box, IconButton } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import _ from 'lodash'
import xlsx from "json-as-xlsx"
import ToolTipIcon from 'components/ToolTipIcon';
// add the actual table

const DownLoadCSV = (props) =>{

    const exportToExcel = () =>{
        let today =  new Date().toLocaleString('en-GB', {
            hour12: false,
          })
        var data = {
            sheet:"sheet 1",
            columns:[],
            content:[]
        }
        _.forEach(props.data,(row)=>{
            let newRow = {}
            _.forEach(row,(item,key)=>{
                if(!key.includes(["_hidden"]) || key=='phone_hidden' || key=='phone_no_hidden' || key=='vehicle_no_hidden')
                    newRow[key] = item
            })
            data['content'].push(newRow)
        })
        if(data['content'].length>0){

            let rowKeys = Object.keys(data['content'][0])
            data.columns = _.map(rowKeys,item=>{
                return {label:item,value:(row)=>row[item]}// formating columns
            })

        }
        let settings = {
            fileName: `${props.fileName}_${today}`, // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
            RTL: false, // Display the columns from right-to-left (the default value is false)
          }
          xlsx([data], settings)
    }
    return(
        <DownloadIcon style={{float:'right'}} onClick={()=>exportToExcel()}  /> 
    )
}

function ReportTable(props){
    console.log("ReportTable-propss",props)
    function addTable(key,data){
    console.log("addTable",key,data)
        if(data.table.length>0)
            return (
              <RowFormatting
                id={data.title}
                headers={data.headings}
                data={data.table}
                tableName={key}
                pagination={data.pagination}
                renewSubscription={props.renewSubscription}
                onEnable={props.onEnable}
                updteVehicleNo={props.updteVehicleNo}
                updateAmount={props.updateAmount}
                onEdit={props.onEdit}
                updateParkingAmount={(e, f) => props.updateParkingAmount(e, f)}
                downloadInvoice={props.downloadInvoice}
                downloadReceipt={props.downloadReceipt}
                deleteMaintenance={props.deleteMaintenance}
                downloadReport={props.downloadReport}
                closeBooking={props.closeBooking}
                tableClass={props.tableClass}
              />
            );
        else
            return(<Alert className="shadow-lg" color="primary">
                { data.noDataMsg ? data.noDataMsg :
                    'No data for this period !'
                } </Alert>) // fixme: this message should come from backend
    
    }
    
    // add the row , if needed with 2 columns
    function addRow(key,data,nextData=null){
        
        return(<div className="row mb-md-3" key={'table-'+key}>
            {addCard(key,data,(null==nextData))}
            {nextData && addCard(key,nextData,false)}
        </div>)
    }
    
    function addCard(key,data,isFull=true){
        return( <CardBox styleName={(data && data.length === 0 ) ? "col-md-3" : (!isFull) ? "col-md-6" : "col-md-12"} cardStyle="p-0" 
                    headerOutside
                    heading={
                    <React.Fragment>
                        {/*fixme: this is what it shud be <IntlMessages id={data.title}/> */}
                        {data.title}
                        {data.headings.length >4 && data.table.length>0 &&
                            <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                            <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" dark /></Box></MsgPopover>}
                        {(props.downloadIconBtn && data?.table?.length>0)&&
                            <ToolTipIcon
                                title="Download"
                                ariaLabel="download"
                                style={{float:'right'}}
                                icon={ <DownloadIcon /> }
                                onClick={()=>props.downloadIconBtn(key,data.table)}    
                            />
                        }
                        {(props.downloadCSVBtn && data?.table?.length>0)&&
                            <DownLoadCSV fileName = {data.title} headers={data.headings} data={data.table} />
                        }
                    </React.Fragment>}>        
                    {addTable(key,data)}                
                </CardBox>)
    }
    let keys = Object.keys(props.data)
    let table = []
    for(let i=0;i<keys.length;i++){  
        let keyName = keys[i]      
        let data = props.data[keys[i]]
        console.log('data---',data);
        let nextData = null
        if(i !== keys.length-1){
            nextData =  props.data[keys[i+1]]
            if(data.headings.length >4 || (nextData.headings != null && nextData.headings.length >4) ) 
                nextData =null
            else
                i++
        } else {
            i++
        }
        table.push(addRow(keyName,data,nextData))
    }

    return table
}

export default ReportTable;