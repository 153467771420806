
import {IconButton,Tooltip} from '@material-ui/core'
const ToolTipIcon = (props)=>(
    <Tooltip title={props.title?props.title:''}>
        <IconButton 
            className={props?.className || ''} 
            color={props?.color || ''}
            edge={props?.edge || ''}
            style={{...props?.style}}
            onClick={props.onClick}
            disabled={props?.disabled || false}
            aria-label={props?.ariaLabel || ''}
        >
            {props.icon}
        </IconButton>
    </Tooltip>
)
export default ToolTipIcon