import React, { useEffect, useState } from 'react'
import API from 'util/onboardApi'
import CardBox from 'components/CardBox'
import {Box} from '@material-ui/core'
// import { Formik } from 'formik'
import ActionsTable from "components/ActionsTable/TableList";
import ContainerHeader from 'components/ContainerHeader'
import SnackBar from 'components/SnackBar'
import * as Yup from "yup";
import _ from "lodash";
import { Formik, Form, Field } from 'formik';
import { FormControl, InputLabel, MenuItem, Button, TextField, Select } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, Slide } from '@material-ui/core';

import SlotForm from '../JobDetails/jobSlots'


const validations = Yup.object().shape({
    title: Yup.string().required('Required'),
    location_id: Yup.string().required('Please select a Location'),
    slots: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date()
          .required('End date is required')
          .min(Yup.ref('startDate'), 'End date must be after start date'),
        shift: Yup.object().shape({
          '10am-05pm': Yup.object().shape({
            vacancy: Yup.number()
              .min(0, 'Vacancy cannot be negative')
              .test('vacancy-required', 'Vacancy is required if payouts are entered', function (value) {
                const { payouts } = this.parent;
                return payouts > 0 ? value > 0 : true;
              }),
            payouts: Yup.number()
              .min(0, 'Payouts must be non-negative')
              .test('payouts-required', 'Payouts are required if vacancy is entered', function (value) {
                const { vacancy } = this.parent;
                return vacancy > 0 ? value > 0 : true;
              }),
          }),
          '02pm-10pm': Yup.object().shape({
            vacancy: Yup.number()
              .min(0, 'Vacancy cannot be negative')
              .test('vacancy-required', 'Vacancy is required if payouts are entered', function (value) {
                const { payouts } = this.parent;
                return payouts > 0 ? value > 0 : true;
              }),
            payouts: Yup.number()
              .min(0, 'Payouts must be non-negative')
              .test('payouts-required', 'Payouts are required if vacancy is entered', function (value) {
                const { vacancy } = this.parent;
                return vacancy > 0 ? value > 0 : true;
              }),
          }),
          '05pm-12am': Yup.object().shape({
            vacancy: Yup.number()
              .min(0, 'Vacancy cannot be negative')
              .test('vacancy-required', 'Vacancy is required if payouts are entered', function (value) {
                const { payouts } = this.parent;
                return payouts > 0 ? value > 0 : true;
              }),
            payouts: Yup.number()
              .min(0, 'Payouts must be non-negative')
              .test('payouts-required', 'Payouts are required if vacancy is entered', function (value) {
                const { vacancy } = this.parent;
                return vacancy > 0 ? value > 0 : true;
              }),
          }),
        }),
      })
    ).min(1, 'At least one slot configuration is required'),
});
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
    });
    
const JobList = (props) => {

    const [config, setConfig] = useState();
    const [values, setValues] = useState();
    const [locations, setLocations] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [dialog, setDialog] = useState({open:false, fullWidth:true, content:null, header:'', agreeBtnTxt:'', disAgreeBtnTxt:'', disableSubmit:false, type:'', callback:null});
    const [initialValues, setInitialValues] = useState({
        title: '',
        location_id: null,
        slots: [
            {
                startDate: null,
                endDate: null,
                shift: {
                    '10am-05pm': { vacancy: 0, payouts: 0 },
                    '02pm-10pm': { vacancy: 0, payouts: 0 },
                    '05pm-12am': { vacancy: 0, payouts: 0 },
                    // night: { vacancy: 0, payouts: 0 },           
                },
            },
        ],
    });
    const [jobList, setJobList] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    

    useEffect(()=>{
        getJobList();
    },[])


    const getJobList = ()=>{
        API.get('jobLists').then(res=>{
            setLocations(res?.data?.data?.locations);
            setJobList(res?.data?.data?.jobs)
        }).catch(err =>{
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            })
        })
    };

    // Handler functions to add/remove slots
    const handleAddSlot = (values, setFieldValue) => {
        const newSlot = {
            startDate: null,
            endDate: null,
            shift: {
                '10am-05pm': { vacancy: 0, payouts: 0 },
                '02pm-10pm': { vacancy: 0, payouts: 0 },
                '05pm-12am': { vacancy: 0, payouts: 0 },
                // night: { vacancy: 0, payouts: 0 },           
            },
        };
        setFieldValue('slots', [...values.slots, newSlot]);
    };

    const handleRemoveSlot = (index, values, setFieldValue) => {
        const newSlots = values.slots.filter((_, idx) => idx !== index);
        setFieldValue('slots', newSlots);
    };

    const editJob = (job)=>{
        let jobDetails = _.cloneDeep(job);
        setIsEdit(true);
        jobDetails.title = jobDetails?.title?.value;
        jobDetails.location_id = jobDetails?.location_id?.value?.value;
        jobDetails.job_id = jobDetails?.id?.value;
        const validShifts = ['10am-05pm', '02pm-10pm', '05pm-12am'];
        jobDetails.slots.forEach((slot) => {
            // Iterate over each valid shift to check if it exists
            validShifts.forEach((shiftKey) => {
                // Check if the shiftKey exists in the slot's shift object
                if (!(shiftKey in slot?.shift)) {
                    // If shiftKey is missing, add it with default values
                    slot.shift[shiftKey] = { vacancy: 0, payouts: 0 };
                } 
            });
        });
        setInitialValues(jobDetails);
    }
    const confirmDeleteJob = (job)=>{
        setDialog({
            open:true,
            header:`Delete Job`,
            content:`Are you sure you want to delete this job?`,
            submitBtnLabel:'Confirm',
            disAgreeBtnTxt:'Cancel',
            userData: job,
            callback:deleteJob,
        });
    }
    const deleteJob = (job)=>{
        setDialog({
            open:false,
        })
        API.put('deactivateJob',{job_id: job?.id?.value}).then(res=>{
            getJobList();
            setSnackBar({
                open: true,
                message: res?.data?.msg,
                type: "success",
            });
        }).catch(err =>{
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            });
        })
    }
    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            location:null,
            callback:null
        });
    }
    const submitJob = (values,resetForm,validateForm)=>{
        if(!isEdit){
            API.post('jobPost',values).then(res=>{
                resetForm();
                getJobList();
                setSnackBar({
                    open: true,
                    message: res?.data?.msg,
                    type: "success",
                });
            }).catch(err =>{
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg,
                    type: "error",
                });
            })
        }
        else{
            API.put('jobEdit',values).then(res=>{
                resetFormValues();
                getJobList();
                setIsEdit(false);
                setSnackBar({
                    open: true,
                    message: res?.data?.msg,
                    type: "success",
                });
            }).catch(err =>{
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg,
                    type: "error",
                });
            })
        }
    }

    const resetFormValues = (resetForm) => {
        setIsEdit(false);
        setInitialValues({
            title: '',
            location_id: null,
            slots: [
                {
                    startDate: null,
                    endDate: null,
                    shift: {
                        '10am-05pm': { vacancy: 0, payouts: 0 },
                        '02pm-10pm': { vacancy: 0, payouts: 0 },
                        '05pm-12am': { vacancy: 0, payouts: 0 },
                        // night: { vacancy: 0, payouts: 0 },           
                    },
                },
            ],
        });
    };
    return (
        <>
            <ContainerHeader title={`Job List`} />
            { true &&
                <CardBox styleName="col-12 p-0" >
                    <Formik
                        enableReinitialize={true} // Enable reinitialization on initialValues change
                        initialValues={initialValues}
                        validationSchema={validations} // Add validation schema if necessary
                        validateOnBlur={true}  
                        validateOnChange={true}
                        validateOnMount={true}  
                        onSubmit={(values,{resetForm}) => {
                            submitJob(values,resetForm);
                        }}
                    >
                        {({ values, handleChange, handleBlur, setFieldValue, errors, touched, isSubmitting, resetForm, validateForm, setFieldTouched }) => (
                            <Form>
                            {/* Title Input */}
                            <div className='row'>
                                <div className="col-md-4 col-12 mb-3">
                                    <InputLabel shrink={true} htmlFor="title">
                                    Title
                                    </InputLabel>
                                    <TextField
                                        name="title"
                                        margin="none"
                                        value={values.title}
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                        fullWidth
                                    />
                                    {errors.title && touched.title && <div style={{ color: 'red' }}>{errors.title}</div>}
                                </div>

                                {/* Dropdown Input */}
                                <div className="col-md-4 col-12 mb-3">
                                    <InputLabel shrink={true} htmlFor="dropdown">
                                    Select Location
                                    </InputLabel>
                                    <FormControl fullWidth>
                                        <Field
                                            name="location_id"
                                            as={Select}
                                            value={values.location_id}
                                            onChange={handleChange} 
                                            onBlur={handleBlur} 
                                        >
                                            {locations.map(location => (
                                                <MenuItem key={location.id} value={location.id}>
                                                    {location.location_name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                    {errors.location_id && touched.location_id && <div style={{ color: 'red' }}>{errors.location_id}</div>}
                                </div>
                            </div>

                        {/* Slots Form */}
                        <SlotForm
                            values={values}
                            handleAddSlot={() => handleAddSlot(values, setFieldValue)}
                            handleRemoveSlot={(index) => handleRemoveSlot(index, values, setFieldValue)}
                        />

                        <div className="col-lg-6 col-sm-12 col-12">                    
                            <Box mt="20px">
                                <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" disabled={isSubmitting} > Submit </Button></Box>
                                <Box mr="10px" display="inline"><Button variant="outlined" onClick={()=>resetFormValues(resetForm)} color="secondary" type="reset" disabled={isSubmitting}>Reset</Button></Box>
                            </Box>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </CardBox>
            }

            {jobList &&
                <div className="row">
                    <ActionsTable
						tableHeading={jobList.title}
						table={jobList}
                        // handleImageDialog={ShowImages}
						actions={true}
                        onEdit={editJob}
                        onDelete={confirmDeleteJob}
					/>
                </div>
                }

            <Dialog
				open={dialog.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth='md'
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<LinearProgress style={{visibility:props.showProgress ? 'visible' : 'hidden'}} color="primary" />
				<DialogTitle id="alert-dialog-slide-title" sx={{ borderBottom: "1px solid red" }} style={{ textAlign: "center" }}>{dialog.header}</DialogTitle>
				<DialogContent>
					{dialog.content}
				</DialogContent>
				<DialogActions>
					{dialog.submitBtnLabel && 
						<Button variant="outlined"  color="primary" onClick={() => { dialog.callback(dialog.userData)}} disabled={dialog.disableSubmit} > {dialog.submitBtnLabel} </Button>}						
					<Button variant="outlined"  color="secondary" onClick={handleDialogClose}> Close</Button>
				</DialogActions>
			</Dialog>
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )

}

export default JobList;