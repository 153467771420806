import React, {Component} from 'react';
import _ from 'lodash';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportFilter from 'components/ReportTable/ReportFilter'
import CardBox from 'components/CardBox';
import ContainerHeader from 'components/ContainerHeader';
import ReportTable from 'components/ReportTable/index';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { connect } from 'react-redux';

class historical extends Component {
    constructor(props) {
        super(props);  
        this.state = {
            match: props.match,
            table:{},
            locations: false,
            period:["Daily","Monthly","Annual"],
            reportTypes:['Pay and park','Subscription','Both'],
            appState:'loading',
            reportFlags : props.reportFlags!=undefined ? _.find(props.reportFlags,{'name':this.props.match.params.report}).flags : localStorage.removeItem("token"),
            requestStatus : {msg:"",success:true},
            tags: [{'id':0,'name':'Clear','tags':null}],
            errorMessage: '',
            isReportRequest: true,
            isDataRequest: this.props.match.params.report==='parking-detail'?true:false,
            vendors: {}
        }
        this.getData = this.getData.bind(this)
        this.sendReportData = this.sendReportData.bind(this)
        this.handleTagChange = this.handleTagChange.bind(this)
    }

    componentDidMount(){        
        API.get('location')
        .then((res) => {
            // received data        
            //console.log('rcvd data',res.data.data)
            this.setState({ locations: res.data.data ,appState : 'active', errorMessage: ""})
            this.allLocations = res.data.data
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please Try Again"});
        })
        API.get('tags').then((res) => {
            // received data        
            //console.log('rcvd data',res.data.data)
            let tags = this.state.tags
            for(var i=0;i<res.data.data.Tags.table.length;i++){
                tags.push(res.data.data.Tags.table[i]);
            }
            this.setState({ tags: tags, errorMessage: ""})
            API.get('vendors').then((res) => {
                // received data        
                //console.log('rcvd data',res.data.data)
                this.setState({ vendors: res.data.data ? res.data.data : [] ,appState : 'active', errorMessage: ""})
            })
            .catch((err) => {
                this.setState({errorMessage: "Please try again"});
            })
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please Try Again"});
        })
    }

    componentDidUpdate(prevProps, prevState) {
        /**
        * this is the initial render
        * without a previous prop change
        */
       if(prevProps === undefined) {            
            let report = _.find(this.props.reportFlags,{'name':this.props.match.params.report})
            this.setState({reportFlags:report.flags})
            console.log('flags ',report.flags)
            return false
       }

       /**
        * new report?
        */
    
        if(prevProps.match.params.report !== this.props.match.params.report){
            let report =_.find(this.props.reportFlags,{'name':this.props.match.params.report})
            this.setState({
              appState: "active",
              table: {},
              reportFlags: report.flags,
              errorMessage: "",
              requestStatus: { msg: "" },
              isDataRequest:
                this.props.match.params.report === "parking-detail"
                  ? true
                  : false,
            });                        
        }

   }


    getData(params, cb){
        this.setState({appState:'waiting'})
        params.type = this.props.match.params.report
        
        Object.keys(params).map(function(item,i) {
            if(item === 'from' || item === 'to')
                params[item] = new Date(params[item]).toISOString()
        });
        if(params.type==='parking-temp'){
            API.get('historical_report_temp?data='+JSON.stringify(params))
            .then((res)=> {
                this.setState({table:res.data.data,appState:'active', errorMessage: ""})
                cb()
            })
            .catch((err)=> {
                this.setState({errorMessage: "Please Try Again"});
                cb()
            })
        }else{
            API.get('historical_report?data='+JSON.stringify(params))
            .then((res)=> {
                // rcvd reports data
                //console.log('data ',res.data.data)
                this.setState({table:res.data.data,appState:'active', errorMessage: ""})
                cb()
            })
            .catch((err)=> {
                this.setState({errorMessage: "Please Try Again"});
                cb()
            })
        }
        
    }
    sendReportData(params, cb){
        params.type = this.props.match.params.report
        API.get('report?data='+JSON.stringify(params))
        .then((res)=> {
            // rcvd reports data
            this.setState({requestStatus: {msg: res.data.msg,success:true}})
            cb()
        })
        .catch((err)=> {
            this.setState({requestStatus: {
                msg: err?.response?.data?.msg?err.response.data.msg:"Unable to register your request,\n please try again in some time",
                success:false
            }});
            cb()
        })
    }

    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }

    handleTagChange(e){
        const value = e.target.value
        if(value === 0 && !_.isEqual(_.sortBy(this.state.locations), _.sortBy(this.allLocations))){
            this.setState({locations:this.allLocations})
        } else {
            let locations = []
            for(let i=0;i<this.allLocations.length;i++){
                if(this.allLocations[i].tag != null && (this.allLocations[i].tag.split(',').includes(value)))
                    locations.push(this.allLocations[i])
            }    
            this.setState({locations:locations})
        
        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    renderPage(){
        return(
            this.state.locations && 
            <div>
                <ContainerHeader title={_.find(this.props.reportFlags,{'name':this.props.match.params.report}).name.replace('-',' ')+' Reports'} match={this.props.match}/>    
                <div className="row">
                    <CardBox styleName="col-12">
                        <ReportFilter 
                            locations={this.state.locations} 
                            period={this.state.period} 
                            reportType = {this.state.reportTypes} 
                            getReportData={this.getData} 
                            sendReportData={this.sendReportData}
                            requestStatus = {this.state.requestStatus}
                            onDialogClose={() => {this.setState({requestStatus:{msg:"",success:true}})}}
                            tags = {this.state.tags} 
                            flags = {_.find(this.props.reportFlags,{'name':this.props.match.params.report}).flags}
                            handleTagChange={this.handleTagChange}
                            // minutesStep={15}
                            minFromDate={new Date(moment().subtract(6, "months").format('YYYY-MM-01 00:00:00'))}
                            maxToDate = {(this.props.match.params.report==='parking-temp')?undefined:new Date(moment().subtract(40, 'days'))}
                            isReportRequest = {this.state.isReportRequest}
                            isDataRequest = {this.state.isDataRequest}
                            vendors = {this.state.vendors}
                            />
                    </CardBox>
                    { this.state.errorMessage!=='' &&
                  <h3 className="error"> { this.state.errorMessage } </h3> }
                </div>
                {this.state.appState==='waiting' 
                ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                : <React.Fragment>{
                    Object.keys(this.state.table).length >0 &&
                    <ReportTable data = {this.state.table}/>
                    }</React.Fragment>
                }
                
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    try {
        return {reportFlags : state.auth.authUser.historicaldata}
    } catch (err) {
    }    
}

export default connect(mapStateToProps)(historical);