import { Button, Typography, TextField, Grid } from '@material-ui/core';
import { Field, ErrorMessage } from 'formik';
import React from 'react';
import { DatePicker } from 'formik-material-ui-pickers';
import dayjs from 'dayjs';

const SlotForm = ({ values, handleAddSlot, handleRemoveSlot }) => {
    return (
        <div>
            {/* Slot List */}
            {values.slots.map((slot, index) => (
                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                    {/* <Typography variant="h6">Slot {index + 1}</Typography> */}

                    {/* Date Fields */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                component={DatePicker}
                                name={`slots[${index}].startDate`}
                                label="Start Date"
                                format="DD/MM/YYYY"
                                minDate={dayjs().format('DD/MM/YYYY')}  // Restrict to today and future dates
                                fullWidth
                                margin="normal"
                            />
                            {/* <ErrorMessage name={`slots[${index}].startDate`} component="div" style={{ color: 'red' }} /> */}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Field
                                component={DatePicker}
                                name={`slots[${index}].endDate`}
                                label="End Date"
                                format="DD/MM/YYYY"
                                minDate={dayjs().format('DD/MM/YYYY')}  // Restrict to today and future dates
                                fullWidth
                                margin="normal"
                            />
                            {/* <ErrorMessage name={`slots[${index}].endDate`} component="div" style={{ color: 'red' }} /> */}
                        </Grid>
                    </Grid>

                    {/* Shift Fields */}
                    {['10am-05pm', '02pm-10pm', '05pm-12am'].map((shift, i) => (
                        <Grid container spacing={2} key={i} style={{ marginTop: '10px', alignItems: 'center' }}>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="subtitle1">{shift.charAt(0).toUpperCase() + shift.slice(1)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Field
                                    name={`slots[${index}].shift.${shift}.vacancy`}
                                    as={TextField}
                                    type="number"
                                    label="Vacancy"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                                <ErrorMessage name={`slots[${index}].shift.${shift}.vacancy`} component="div" style={{ color: 'red' }} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Field
                                    name={`slots[${index}].shift.${shift}.payouts`}
                                    as={TextField}
                                    type="number"
                                    label="Payouts"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                                <ErrorMessage name={`slots[${index}].shift.${shift}.payouts`} component="div" style={{ color: 'red' }} />
                            </Grid>
                        </Grid>
                    ))}

                    {/* Remove Slot Button */}
                    {values.slots.length > 1 && (
                        <Button
                            onClick={() => handleRemoveSlot(index)}
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: '10px' }}
                        >
                            Remove Slot
                        </Button>
                    )}
                </div>
            ))}

            {/* Add Slot Button */}
            {/* <Button onClick={handleAddSlot} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                Add Slot
            </Button> */}
        </div>
    );
};

export default SlotForm;
