import React, { useState,useEffect } from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,Typography} from '@material-ui/core'
import { Formik,Form,connect, useFormikContext } from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"
import API from "../../../../../../util/Api"

const CreateClientsConfigSchema =()=>{
    return Yup.lazy(values=>{
        return Yup.object().shape({
                name:Yup.string()
                    .min(5, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required("requried")
        })
    });
}
const ClientsForm = (props) => {
    const { isSubmitting } = useFormikContext();
    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])

  return (
    <Form>
      <DialogContent>
        <div className={"row"}>
            {props.config.map((field, index) => {
            return (
                <div
                className={`col-md-${field.size} col-12`}
                key={`rowField-${field.key} `}
                >
                <FormField
                    field={{
                    ...field,
                    ...{ name: field.key }
                    }}
                />
                </div>
            );
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          {"Close"}
        </Button>
        <Button variant="outlined" type="submit" color="primary" disabled={isSubmitting} >
          {"Save"}
        </Button>
      </DialogActions>
    </Form>
  );
};

const EZForm = connect(ClientsForm)
const EditClient = (props)=>{
    const [message,setMessage] = useState('')
    const [resetF,setResetF] = useState(false);
    const submitClients = (e, {setSubmitting}) =>{
        console.log("submitClients",e)
        setSubmitting(true);
        API.post('access_edit_client',JSON.stringify(e)).then(response=>{
            console.log("access_subscription",response.data)
            setMessage(response.data.msg)
            setTimeout(()=>{
                props.onClose(true);
            },200)
            setSubmitting(false);
        }).catch(error=>{
            setMessage('Failed to save')
            console.log(error)
            setSubmitting(false);
        })
    }
    return(
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'md'}
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{'Edit Clients '}</DialogTitle>
            <Typography color='primary' variant='h6' style={{marginLeft:30}} >{message}</Typography>
            <Formik
                onSubmit={submitClients}
                initialValues={props.initialValues}
                validationSchema={CreateClientsConfigSchema}
                resetInitalValue={true}
            >
                <EZForm onClose={props.onClose} config={props.config} resetFormik={resetF} setFalse={()=>{console.log('setResetF');setResetF(false)}} />
            </Formik>
      </Dialog>
    )
}
export default EditClient