import React, { useEffect, useState } from "react";
import EVChargingLocationDetails from "./EVLocationDetails";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import PopUpDialog from 'components/PopUpDialog';
import _ from 'lodash';
import * as Yup from "yup";
// import dummyData from "./dummy";

const EVChargingLocations = (props) => {
  const [values, setValues] = useState(null);
  const [config, setConfig] = useState(null);
  const [location, setLocation] = useState(null);
  const [chargingLocations, setChargingLocations] = useState(undefined);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const [dialogState, setDialogState] = useState({
    selectedLocation:{},
    isOpenConfirmDialog:false,
    dialogContent:'',
    dialogAgreeBtnTxt:'',
    dialogDisAgreeBtnTxt:'',
    dialogHeader:'',
    dialogType:'',
    callback:null
  })
  const validations = Yup.object().shape({
    name: Yup.string().required("Required"),
    address: Yup.string().required("Required"),
    opening_time: Yup.string().required("Required"),
    closing_time: Yup.string().required("Required"),
    city_id: Yup.object().required("Required"),
  });

  const saveChargingLocation = (data, form) => {
    API.post("chargingLocations", JSON.stringify(data))
      .then((res) => {
        form.setSubmitting(false);
        setLocation(null);
        form.resetForm();
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
        setChargingLocations(res.data.data.locations);
      })
      .catch((err) => {
        form.setSubmitting(false);
        setLocation(null);
        try {
          setSnackBar({
            open: true,
            msg: err.response.data.msg,
            type: "error",
          });
        } catch {
          setSnackBar({
            open: true,
            msg: "Something went wrong",
            type: "error",
          });
        }
      });
  };
  const fetchData=()=>{
    API.get("chargingLocations?crud=true")
      .then((res) => {
        // res.data=dummyData;
        setConfig(Object.values(res.data.data.config.sections));
        setChargingLocations(res.data.data.locations);
        let data = {};
        for (let section in res.data.data.config.sections) {
          for (let key in res.data.data.config.sections[section].fields) {
            data[res.data.data.config.sections[section].fields[key].key] =
              res.data.data.config.sections[section].fields[key].value;
          }
        }
        setValues(data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    fetchData()
  }, [])
  
  const handleConfirmDialogClose = () =>{
    setDialogState({selectedLocation:{}, isOpenConfirmDialog:false, dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:'', dialogType:'', callback:null})
  }

  const updateChargingLocStatus = () => {
    let params = dialogState.selectedLocation;
    API.put("updateEVChargingLocationStatus", JSON.stringify(params))
    .then((res) => {
      dialogState.callback(dialogState.selectedLocation.active);
      handleConfirmDialogClose();
    })
    .catch((err) => {
        console.log("error", err);
    });
  };
  
  const updateStatus = (id,checked, cb) => {
    let evChargingLocation = _.find(chargingLocations.body, (location) => location.id.value === id);
    var text = checked ? `Are you sure you want to activate ${evChargingLocation.name.value}?` : `Are you sure you want to deactivate ${evChargingLocation.name.value}?`
    var header = checked ? 'Activate Charging Location' : 'Deactivate Charging Location'
    setDialogState({selectedLocation:{id:id, active:checked},isOpenConfirmDialog:true,dialogAgreeBtnTxt:'Update',dialogDisAgreeBtnTxt:'Cancel',dialogContent:text,dialogHeader:header, callback:cb})
  };

  const handlePointEdit = (clickedLoc) => {
    setLocation(clickedLoc);
    // scroll to top
    document.body.style.height = "auto";
    setTimeout(function () {
      document.body.style.height = "100%";
    }, 50);
  };
  const rateModel = (clickedLoc) => {
    console.log("clicked loc", clickedLoc);
    props.history.push({
      pathname: `${props.match.url}/tariffs/${clickedLoc.name.value.replace(/\s+/g, "-")}`,
      state: clickedLoc,
    });
  };
  return (
    <>
      {config && values && (
        <Formik
          onSubmit={saveChargingLocation}
          initialValues={values}
          validationSchema={validations}
        >
          <>
            <EVChargingLocationDetails config={config} location={location} />
            {chargingLocations && (
              <ActionsTable
                tableHeading={chargingLocations.title}
                table={chargingLocations}
                updateStatus={updateStatus}
                onEdit={handlePointEdit}
                rateModel={rateModel}
                actions={true}
              />
            )}
          </>
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
      <PopUpDialog
        selectedLocation={dialogState.selectedLocation}
        open={dialogState.isOpenConfirmDialog}
        header={dialogState.dialogHeader}
        content ={dialogState.dialogContent}
        agreeBtnTxt ={dialogState.dialogAgreeBtnTxt}
        disAgreeBtnTxt={dialogState.dialogDisAgreeBtnTxt}
        agreeClicked ={updateChargingLocStatus}
        handleDialogClose={handleConfirmDialogClose}
      />
    </>
  );
};
export default EVChargingLocations;
