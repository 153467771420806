import React,{useEffect, Fragment} from 'react'
import { Form,connect } from 'formik'
import { Button,Box } from '@material-ui/core'
import FormField from 'components/FormField'





const HolidayDetail = (props) => {

    console.log("HolidayDetail",props)
    useEffect(()=>{
        let data ={}
        if(props.holiday){
            for(let key in props.holiday){
                data[key]  = props.holiday[key].value
            }
            console.log("useEffect",data);
            props.formik.setValues(data)  
        }
        //reset to inital on successfull submit
        if(props.holiday===null){
            props.formik.resetForm();
        }
    },[props.holiday]);
    useEffect(()=>{
        
        if(props.refresh===true){
            props.formik.resetForm();
            props.resetRefresh();
        }
    },[props.refresh])
    return(
        <Fragment>
            
            <Form>
                {/* FIRST ROW */}            
                <div className="row">
                    {props.initialConfig.map(field => (
                        <div className="col-md-3 col-12" key={`field-${field.key}`}>
                            <FormField field={{...field,...{name:field.key}}}/>
                        </div>
                    ))
                    }                
                </div>
                <div className="row mt-2">   
                <div className="col-lg-6 col-sm-12 col-12">                    
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                    </Box>
                </div> 
            </div>
            </Form>
        </Fragment>
    )
}

export default connect(HolidayDetail)