/*
 * File: SimpleTable.js
 * Project: parkezdashboard
 * File Created: Thursday, 21st May 2020 6:09:30 pm
 * Author: Binod (binod@valetez.com)
 * -----
 * Last Modified: Tuesday, 7th July 2020 1:05:00 pm
 * Modified By: Binod (binod@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React, {useState,useEffect, Fragment} from 'react'
import {Table,TableBody,TableCell,TableHead,TableRow,TablePagination,IconButton,Switch,Tooltip} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AutoRenewIcon from '@material-ui/icons/Autorenew'
import SearchBox from 'components/ActionsTable/SearchBox'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import SettingsIcon from '@material-ui/icons/Settings'
import ToolTipIcon from 'components/ToolTipIcon'
import UpdateIcon from '@material-ui/icons/Update';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Alert } from "reactstrap";
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import MapIcon from '@material-ui/icons/Map';

const EZSwitch = (props) =>{
    const [active, setActive] = useState(props.active);
    return(
        <Switch
            checked={active}
            onChange={(e)=>props.changeStatus(props.id,e.target.checked,(response)=>{
                console.log("Switch-response",response)
                setActive(response)
            })}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
        />
    )
}

const FloorMapIcon = (props)=>{
  const [coord, setCoord] = useState(props.data?.coordinates);

  return(
    <ToolTipIcon
      onClick={() => props.markSlot(props.data, setCoord)}
      icon={<MapIcon color={coord ? 'primary' : undefined} />}
      title={"Mark Slot in Map"}
    />
  )
}

const SimpleTable =(props)=> {
    console.log('simple-table-props',props)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tBody,setTBody] = useState(props.table.tbody);
    
    useEffect(()=>{
        setTBody(props.table.tbody); 
    },[props.table.tbody])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };
    //filter table according to search
    const handleSearch = (searchValue)=>{

        if(searchValue){
            var filter = [];
            props.table.tbody.map(o=>{
                for(var key in o){
                    console.log(o[key]);
                    if( typeof o[key] !=='object' && typeof o[key] !=='boolean' && o[key] !=null && !Array.isArray(o[key]) && o[key].toLowerCase().includes(searchValue.toLowerCase()) ){
                        filter.push(o);
                        break;
                    }
                }
             });
            setTBody(filter);
        }else{
            setTBody(props.table.tbody);
        }
    }
    return props.table && props.table.tbody.length > 0 ? (
      <Fragment>
        {
          !props.disableSearch&&
            <SearchBox style={{ marginBottom: 10 }} handleSearch={handleSearch} />
        }
        <div className="table-responsive-material">
          <Table>
            <TableHead>
              <TableRow>
                {props.table.headers.map((n, index) => {
                  return (
                    <TableCell
                      key={n + index}
                      style={{
                        backgroundColor: "#1d2232",
                        color: "white",
                        fontFamily: "Roboto",
                      }}
                      size="small"
                    >
                      {n}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? tBody.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : tBody
              ).map((row) => {
                return (
                  <TableRow key={row.id}>
                    {props.type &&
                    (props.type == "freeParking" ||   
                      props.type == "controller-v2" || 
                      props.type == "updateFasTag" || props.type=='guardApiThrottle' || props.type=='chargingRateModel' || props.type=='fastagError' || props.type=='accessClients' || props.type == 'invoice-tracking' || props.type=='deployment-history') ? (
                      <>
                        {Object.keys(row)
                          .filter((key) => {
                            if (key == "id" || key == "user_id" || key.includes("_hidden") || key == "active" ) {
                              return false;
                            } else return true;
                          })
                          ?.map((item, i) => {
                            return (
                              <TableCell
                                key={`key-${i}`}
                                style={
                                  (typeof row[item]==='object')?row[item]?.style
                                  :
                                  row[item] === "Offline"
                                    ? { color: "red" }
                                    : row[item] === "Online"
                                    ? { color: "green" }
                                    : {}
                                }
                              >
                                {(typeof row[item]==='object')?(row[item]==null)?'-':row[item]?.value:row[item]}
                              </TableCell>
                            );
                          })}
                        {props.type == "controller-v2" ? (
                          <TableCell align="center">
                            <ToolTipIcon
                              title="Controller Config"
                              ariaLabel="Setting"
                              icon={ <SettingsIcon /> }
                              onClick={() => props.onSetting(row)}
                            />
                            <ToolTipIcon
                              title="Edit"
                              ariaLabel="Edit"
                              icon={ <EditIcon /> }
                              onClick={() => props.onEdit(row)}
                            />
                            <ToolTipIcon
                              title="Send Command"
                              ariaLabel="Send-Command"
                              icon={ <AutoRenewIcon /> }
                              onClick={() => props.sendCommand(row)}
                            />
                            <ToolTipIcon
                              title="Update Firmware Version"
                              ariaLabel="update-firmware"
                              icon={ <SystemUpdateAltIcon /> }
                              onClick={() => props.firmwareVersion(row)}
                            />
                            <ToolTipIcon
                              title="Update Firmware V2"
                              ariaLabel="firmware"
                              icon={ <GetAppIcon /> }
                              onClick={() => props.updateFirmware(row)}
                            />
                            <EZSwitch 
                              active={row.active} 
                              id={row.id}
                              changeStatus={props.updateStatus}
                              key={`switch-${row.id}`}
                            />
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {props.onUpdate && (
                          <TableCell align="center">
                            <ToolTipIcon
                              onClick={() => props.onUpdate(row)}
                              icon={<EditIcon />}
                              title={props.onUpdateTitle?props.onUpdateTitle:"Update Tag"}
                            />
                          </TableCell>
                        )}
                      </>
                    ) : props.type && props.type == "controller" ? (
                      <>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.version}</TableCell>
                        <TableCell>{row.location_name}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="Edit"
                            onClick={() => props.onEdit(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </>
                    ) : props.type && props.type == "visitorSpasses" ? (
                      <>
                        <TableCell>{row.vehicle_no}</TableCell>
                        <TableCell>{row.end_time}</TableCell>
                        <TableCell>{row.type}</TableCell>
                        {row.tag_attached == 0 && (
                          <TableCell align="center">
                            <IconButton
                              aria-label="Edit"
                              onClick={() => props.onEdit(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </>
                    ) : props.type && props.type == "deviceUsers" ? (
                      <>
                        <TableCell>{row.location_name}</TableCell>
                        <TableCell>{row.device_name}</TableCell>
                        <TableCell>{row.guard_id}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.version}</TableCell>
                        <TableCell align="left">
                          <ToolTipIcon
                            onClick={() => props.updateApp(row)}
                            icon={<SystemUpdateIcon />}
                            title={`Update App`}
                          />
                        </TableCell>
                      </>
                    ) :props.type && props.type == "parkingTag" ? (
                      <>
                        <TableCell>{row.tag}</TableCell>
                        <TableCell>{row.tagType}</TableCell>
                        <TableCell>{row.vehicleNo}</TableCell>
                        <TableCell>{row.vehicleType}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.client}</TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>{row.updatedOn}</TableCell>
                        <TableCell style={{color: row.status == 'Active' ? 'green' : 'red'}}>{row.status}</TableCell>
                      </>
                    ):props.type && props.type == "accessTag" ? (
                      <>
                        <TableCell>{row.tag}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.client}</TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.updatedOn}</TableCell>
                        <TableCell>{row.createdOn}</TableCell>
                        <TableCell style={{color: row.tagStatus == 'Active' ? 'green' : 'red'}}>{row.tagStatus}</TableCell>
                        <TableCell style={{color: row.userStatus == 'Active' ? 'green' : 'red'}}>{row.userStatus}</TableCell>
                      </>
                    ):props.type && props.type == "parkingSlots" ? (
                      <>
                        <TableCell>{row.floor_no}</TableCell>
                        <TableCell>{row.area_name ?? '-'}</TableCell>
                        <TableCell>{row.slot_no}</TableCell>
                        <TableCell>
                        <ToolTipIcon
                            onClick={() => props.onEdit(row)}
                            icon={<EditIcon />}
                            title={"Edit"}
                          />
                          <FloorMapIcon markSlot = {props.markSlot} data = {row} />
                        </TableCell>
                      </>
                    ) : props.type && props.type == "ledConfig" ? (
                      <>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.device_id}</TableCell>
                        <TableCell>{row.ip}</TableCell>
                        {/* <TableCell>{row.led_version}</TableCell> */}
                        <TableCell>{row.location}</TableCell>
                        <TableCell align="start">
                          <ToolTipIcon
                            onClick={() => props.updateIP(row)}
                            icon={<UpdateIcon />}
                            title={"Update IP Address"}
                          />
                          
                          <ToolTipIcon
                            onClick={() => props.onEdit(row.id)}
                            icon={<EditIcon />}
                            title={"Edit"}
                          />
                          <EZSwitch
                            active={row.active}
                            id={row.id}
                            changeStatus={props.changeStatus}
                          />
                        </TableCell>
                      </>
                    ):(
                    <>
                    {Object.keys(row).map(key => (
                      <TableCell>{row[key] ?? '-'}</TableCell>
                    ))}
                    </>)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50,{ label: 'All', value: -1 }]}
            component="div"
            count={props.table.tbody.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Fragment>
    ) : (
      <Alert className="shadow-lg" color="primary">
        No data found !
      </Alert>
    );
}

export default SimpleTable