import React from "react";
import { Route, Switch } from "react-router-dom";

import JobList from "./routes/JobList";
import JobHistory from "./routes/JobHistory";

const Jobs = ({ match }) => (
    <div className="app-wrapper">
      <Switch>
          <Route path={`${match.url}/job-list`} component={JobList}/>
          <Route path={`${match.url}/job-history`} component={JobHistory}/>
          {/* <Route path={`${match.url}/payouts`} component={Payouts}/>
          <Route path={`${match.url}/documents`} component={Documents}/> */}
      </Switch>
    </div>
  );
  
  export default Jobs;