import React, { useEffect, useState, useRef } from "react";

const Canvas = (props) => {
    const [blink, setBlink] = useState(true);
    const [hoveredSlot, setHoveredSlot] = useState();
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [canvasStyle, setCanvasStyle] = useState({ height: '500px' });
    const [scale, setScale] = useState({x:1, y:1});
    const canvasRef = useRef();
    const floor = props.floor;
    

    useEffect(() => {
        if(props.blink){
            const intervalId = setInterval(() => {
                setBlink((prev) => !prev); // Toggle visibility
            }, 500); // Blink every 500ms
    
            return () => clearInterval(intervalId);
        }
    }, [])
    
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas && floor) {
            const context = canvas.getContext('2d');
            const image = new Image();

            image.src = floor.floor_map_url;

            image.onload = () => {
                context.reset();

                // Set canvas size to match image size
                canvas.width = image.width;
                canvas.height = image.height;

                // Draw the image on the canvas
                context.drawImage(image, 0, 0);
                for (const row of floor.slots) {
                    if (row.slot_coordinates) {
                        const slot = JSON.parse(row.slot_coordinates);
                        if (row.is_available == 1 && !blink) continue;
                        context.globalAlpha = 0.7;
                        context.fillStyle = row.is_available == 1 ? '#81C784' : "#EF5350";
                        context.fillRect(slot.x, slot.y, slot.w, slot.h);
                    }
                }
            };
        }
    }, [floor, blink]);

    const handleMouseMove = (e) => {
        const canvas = canvasRef.current;
        if (canvas && floor) {
            const rect = canvas.getBoundingClientRect();
            const scaleX = canvas.width / rect.width;
            const scaleY = canvas.height / rect.height;
            setScale({x:scaleX, y:scaleY});

            // Adjust mouse position for zoom
            const mouseX = (e.clientX - rect.left)* scaleX;
            const mouseY = (e.clientY - rect.top)* scaleY;

            // Find the slot under the mouse
            const hovered = floor.slots.find((row) => {
                if (row.slot_coordinates) {
                    const slot = JSON.parse(row.slot_coordinates);
                    
                    return (
                        mouseX >= slot.x &&
                        mouseX <= slot.x + slot.w &&
                        mouseY >= slot.y &&
                        mouseY <= slot.y + slot.h
                    );
                }
                return false;
            });

            if (hovered) {
                setHoveredSlot(hovered);
                setTooltipPosition({
                    x: mouseX/scaleX,
                    y: mouseY/scaleY,
                });
            } else {
                setHoveredSlot(null);
            }
        }
    };

    useEffect(() => {
        if(props.fullScreen) {
            setCanvasStyle({ height: '100vh' })
        } else {
            setCanvasStyle({ height: `${props.scale*500}px` })
        }
    },[props.fullScreen, props.scale])

    const handleMouseLeave = () => {
        setHoveredSlot(null);
    };

    return (
        <div style={{ position: "relative", display: "inline-block"}}>
            <canvas
                ref={canvasRef}
                style={{...canvasStyle}}
                height={1000}
                width={1000}
                {...(props.slotDetails && {
                    onMouseMove: handleMouseMove,
                    onMouseLeave: handleMouseLeave,
                })}
                zIndex={100}
            /> 
            {props.slotDetails && hoveredSlot && (
                <div
                    style={{
                        position:"absolute",
                        top: tooltipPosition.y,
                        left: tooltipPosition.x,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        minHeight: '150px',
                        minWidth: '150px',
                        maxWidth: '255px',
                        padding: "10px",
                        borderRadius: "3px",
                        pointerEvents: "none",
                        transform: `translate(7%, 0%)`,
                        zIndex:1000
                    }}
                >
                    <span><strong >Slot: {hoveredSlot.slot_no}</strong></span>
                    <p style={{ textAlign:'start', wordWrap:'break-word' }}>
                        Controller : {hoveredSlot.details.controller ?? 'N/A'} <br/>
                        Controller MAC : {hoveredSlot.details.controller_mac ?? 'N/A'} <br/>
                        Camera : {hoveredSlot.details.camera ?? 'N/A'} <br/>
                        Camera IP : {hoveredSlot.details.camera_ip ?? 'N/A'} <br/>
                        Channel : {hoveredSlot.details.channel ?? 'N/A'} <br/>
                        Device Address : {hoveredSlot.details.device_address ?? 'N/A'} <br/>
                    </p>
                </div>
            )}
        </div>
    )
}

export default Canvas;