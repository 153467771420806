import React, { useEffect, useState } from 'react'
import API from 'util/onboardApi'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, Slide, Box } from '@material-ui/core';
import ContainerHeader from 'components/ContainerHeader'
import SnackBar from 'components/SnackBar'
import { Formik } from 'formik'
import UserDetails from './UserDetails'
import ActionsTable from "components/ActionsTable/TableList";
import * as Yup from "yup";
import _ from "lodash";
import { LinearProgress as LinearProgres } from "@material-ui/core";
import Refresh from '@material-ui/icons/Refresh';
import CardBox from 'components/CardBox'
import ToolTipIcon from "components/ToolTipIcon";


const validations = Yup.object().shape({
    city: Yup.object()
        .required('Required'),
})
const Transition = React.forwardRef(function Transition(props, ref) {
return <Slide direction="up" ref={ref} {...props} />;
});

const ImageDialog = (images) => {
	return ( images.length > 0 ?
		<div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))", gap: "16px", padding: "16px" }} >
			{images.map((image, index) => (
				<div key={index} style={{ position: "relative", width:'100%', height:'100%'}}>
					<img
						src={image.document_url}
						alt={`Preview ${index + 1}`}
						style={{
							width: "100%",
							height:'200px',
							// maxHeight:'200px',
							borderRadius: "8px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
			))}
		</div>
	:
		<div style={{ display: "flex", flexDirection:'row', justifyContent:'center'}}>
			<Typography>No Image found!</Typography>
		</div>
	);
}

const ListUsers = (props) => {

    const [config,setConfig] = useState(null);
    const [values,setValues] = useState({});
    const [initialValues,setInitialValues] = useState([]);
    const [userList,setUserList] = useState(null);
    const [loading,setLoading] = useState(false);
    const [dialog, setDialog] = useState({open:false, fullWidth:true, content:null, header:'', agreeBtnTxt:'', disAgreeBtnTxt:'', disableSubmit:false, type:'', callback:null});
    const [user,setUser] = useState(null);
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    useEffect(()=>{
        getUsersList();
    },[])


    const getUsersList = ()=>{
        setLoading(true);
        API.get("onboardingUsers")
        .then((res) => {
            setConfig(Object.values(res.data.data.config.fields))
            setUserList(res.data.data.users);
            setInitialValues(res.data.data.users);
            setUser(null)
            let data = {}
            for(let key in res.data.data.config.fields){
                data[key]  = res.data.data.config.fields[key].value
            }
            setValues(data)
        })
        .catch((err) => {
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            })
        }).finally(()=>{
            setLoading(false)
        })
    }
    const resetCity = ()=>{
        setUser(null);
        setUserList(initialValues);
    }
	const ShowImages = (item) => {
		setDialog({open:true, fullWidth:true, header:`${item.name.value.toUpperCase()} Documents`, submitBtnLabel:'', type:'showImages', callback:null, content: ImageDialog(item.images.value),userData:null})
	}

    const filterCity = (e, {setSubmitting, resetForm})=>{

        let data = _.cloneDeep(initialValues);
        data.body = data.body?.filter(user => user?.city_id?.value === e?.id) || [];
        setUserList(data);
        setSubmitting(false);        
    }
    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            location:null,
            callback:null
        });
    }
    const verifyUser = (userData)=>{
        setDialog({
            open:false,
        })
        API.put("documentVerify",{user_id : userData?.id?.value})
        .then((res) => {
            setSnackBar({
                open: true,
                message: res?.data?.msg,
                type: "success",
            })
            getUsersList();
        })
        .catch((err) => {
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            })
        })
    }
    const confirmVerifyUser = (userData)=>{
        setDialog({
            open:true,
            header:`Verify User`,
            content:`Are you sure you want to verify this user?`,
            submitBtnLabel:'Confirm',
            disAgreeBtnTxt:'Cancel',
            userData: userData,
            callback:verifyUser,
            
        })
    }
    const uploadSelfDoc = (userData) => {
        // Create a hidden file input
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".pdf"; // Adjust allowed file types if needed
      
        // On file selection
        fileInput.onchange = async (event) => {
          const selectedFile = event.target.files[0];
          if (!selectedFile) return;
      
          try {
            // Prepare FormData
            const formData = new FormData();
            formData.append("document", selectedFile);
            formData.append("user_id", userData?.id?.value);
      
            // API request to upload the document
            API.post("documentUpload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then((res)=>{
                setSnackBar({
                    open: true,
                    message: res?.data?.msg,
                    type: "success",
                })
                getUsersList();
            });
          } catch (err) {
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            })          }
        };
      
        // Trigger the file picker
        fileInput.click();
      };
      
    return (
        <>
            <ContainerHeader title={`Overview Users List`} />
            { config && values &&
            <Formik
                onSubmit={filterCity}
                onReset={resetCity}
                initialValues={values}
                enableReinitialize
                validationSchema={validations}
            >
                <>
                <UserDetails
                    config={config}
                    user={user}
                />        
                {userList &&
                <div className="row">
                    { loading &&  <div className='w-100'><LinearProgres color="primary" /></div> }
                    <CardBox styleName="col-12" headerOutside
                            heading={
                                <React.Fragment>
                                    Onboard Users
                                    <Box style={{float:'right'}} >
                                        <ToolTipIcon
                                            title="Refresh the Users table"
                                            ariaLabel="refresh table"
                                            icon={ <Refresh /> }
                                            onClick={getUsersList}
                                        />
                                    </Box>
                                </React.Fragment>
                            }>
                        
                   
                    <ActionsTable
						tableHeading={''}
						table={userList}
                        handleImageDialog={ShowImages}
						actions={true}
                        verifyUser={confirmVerifyUser}
                        uploadSelfDoc={uploadSelfDoc}
					/>
                     </CardBox>
                </div>
                }
                </>
            </Formik>        
            }
            <Dialog
				open={dialog.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth='md'
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<LinearProgress style={{visibility:props.showProgress ? 'visible' : 'hidden'}} color="primary" />
				<DialogTitle id="alert-dialog-slide-title" sx={{ borderBottom: "1px solid red" }} style={{ textAlign: "center" }}>{dialog.header}</DialogTitle>
				<DialogContent>
					{dialog.content}
				</DialogContent>
				<DialogActions>
					{dialog.submitBtnLabel && 
						<Button variant="outlined"  color="primary" onClick={() => { dialog.callback(dialog.userData)}} disabled={dialog.disableSubmit} > {dialog.submitBtnLabel} </Button>}						
					<Button variant="outlined"  color="secondary" onClick={handleDialogClose}> Close</Button>
				</DialogActions>
			</Dialog>

            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )
}

export default ListUsers;