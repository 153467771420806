import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Alert } from "reactstrap";
import CardBox from "components/CardBox";
import data from "./dumbData";

let colorRange = [
  {
    from: 0,
    to: 25,
    name: 'low',
    color: '#999793'
  },
  {
    from: 26,
    to: 50,
    name: 'medium',
    color: '#faa719'
  },
  {
    from: 51,
    to: 75,
    name: 'high',
    color: '#198afa'
  },
  {
    from: 76,
    to: 100,
    name: 'extreme',
    color: '#19fa28'
  }
];

const splitRange = (start, end) => {
  const rangeSize = Math.ceil((end - start + 1) / 4);
  let result = [];
  let currentStart = start;

  for (let i = 0; i < 4; i++) {
    let currentEnd = currentStart + rangeSize - 1;
    result.push({
      from: currentStart,
      to: currentEnd,
      name: colorRange[i].name,
      color: colorRange[i].color

    });
    currentStart = currentEnd + 1;
  }
  return result;
}

const HeatMap = (props) => {
  console.log('HeatMap-props - ', props);
  const [series, setSeries] = useState(props.data);
  const options = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        radius: 10,
        useFillColorAsStroke: false,
        colorScale: {
          ranges: splitRange(props.min, props.max)
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      width: 2
    },
    title: {
      text: props.title
    },
  }
  console.log('HeatMap-options - ', options);

  useEffect(() => {
    setSeries(props.data);
  }, [props.data])

  return (
    series?.length ?
      <div className="row">
        <CardBox styleName="col-12">
          <div>
            <div id="chart">
              <Chart options={options} series={series} type="heatmap" height={series.length * 40} />
            </div>
            <div id="html-dist"></div>
          </div>
        </CardBox>
      </div> :
      <div><Alert className="shadow-lg" color="primary">No data for this period !</Alert></div>
  );
}

export default HeatMap;