import React, { useEffect, useState } from 'react'
import API from 'util/Api'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import CardBox from 'components/CardBox'
import MsgPopover from 'components/MsgPopover'
import {Box} from '@material-ui/core'
import {SwapHoriz} from '@material-ui/icons'
import ContainerHeader from 'components/ContainerHeader'
import SnackBar from 'components/SnackBar'



const Payouts = (props) => {

    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    return (
        <>
            <ContainerHeader title={`User Payouts`} />

            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )

}

export default Payouts;