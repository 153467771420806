import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { userSignIn } from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationContainer } from "react-notifications";
import Button from '@material-ui/core/Button'
import logov from "assets/images/logo_v.png"
import LockOutlined from "@material-ui/icons/LockOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import URLSearchParams from "url-search-params";

const SignIn = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const { loading, message } = useSelector(({ commonData }) => commonData);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [tokenLogin, setTokenLogin] = useState(true);
  
  const enterKeyPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13)
      dispatch(userSignIn({ email, password }))
  }

  useEffect(() => {
    if (token !== null) {      
      const params = new URLSearchParams(window.location.search);
      const referrer = params.get("referrer") || "/";
      props.history.push(referrer);
    }
  }, [token]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    if(params.has("secret")){
      const secret = params.get("secret");
      setTokenLogin(true);
      dispatch(userSignIn({ email, password, secret }))
    }else{
      setTokenLogin(false);
    }
  }, [props.location.search])

  return (
    !tokenLogin && 
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="login-content">
        <div className="login-header">
          <Link className="app-logo" to="/" title="ValetEZ">
            <img src={logov} alt="ValetEZ" title="ValetEZ" />
          </Link>
        </div>

        <div className="login-form">
          <form onSubmit={() => { dispatch(userSignIn({ email, password })); }}>
            <fieldset>
              <div className="form-group">
                <input name="email" id="email" className="form-control form-control-lg"
                  onKeyPress={enterKeyPressed} placeholder="Email" type="email" onChange={(event) => setEmail(event.target.value)} />
              </div>
              <div className="form-group passwordFieldContainer">
                <input name="password" id="password" className="form-control form-control-lg"
                  onKeyPress={enterKeyPressed} placeholder="Password" type={isPasswordVisible ? 'text' : 'password'} onChange={(event) => setPassword(event.target.value)} />
                <span onClick={() => setIsPasswordVisible(!isPasswordVisible)} style={{ cursor: 'pointer', marginRight: '10px' }}>
                  {isPasswordVisible ? <LockOpenOutlined alt="Toggle visibility" /> : <LockOutlined alt="Toggle visibility" />}
                </span>
              </div>
              <div className="form-group d-flex justify-content-between align-items-center">
                {/* <label className="custom-control custom-checkbox float-left">
                  <input type="checkbox" className="custom-control-input"/>
                  <span className="custom-control-indicator"/>
                  <span className="custom-control-description">Remember me</span>
                </label>

                <div>
                  <Link to="/app/app-module/forgot-password-1"
                        title="Reset Password"><IntlMessages id="appModule.forgotPassword"/></Link>
                </div> */}
                {message && <div>{message}</div>}
              </div>

              <Button onClick={() => { dispatch(userSignIn({ email, password })); }} className="btn jr-btn-rounded btn-primary btn-rounded"><IntlMessages
                id="appModule.signIn" /></Button>

            </fieldset>
          </form>
        </div>
      </div>
      {
        loading &&
        <div className="loader-view">
          <CircularProgress />
        </div>
      }
      <NotificationContainer />
    </div>
  );
};


export default SignIn;
