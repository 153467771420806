import React, { useEffect, useState } from 'react'
import API from 'util/onboardApi'
import CardBox from 'components/CardBox'
import ContainerHeader from 'components/ContainerHeader'
import SnackBar from 'components/SnackBar'
import ActionsTable from "components/ActionsTable/TableList";
import { Alert } from "reactstrap";

import FormFilter from "../../../../../components/FormFilter"


const JobHistory = (props) => {

    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const [locations,setLocations] = useState([]);
    const [appliedJobs,setAppliedJobs] = useState(null);
    const [inputData,setInputData] = useState(null);
    const requiredFields = {
        jobLocation: "Location is required",
        job: "Job is required",
      };
    const onSubmit = async (inputs)=>{
        for (const [field, message] of Object.entries(requiredFields)) {
            if (!inputs[field]) {
                setSnackBar({
                open: true,
                message,
                type: "error",
                });
                return;
            }
        }
        let data = {
            startDate : inputs.startDate ? new Date(inputs.startDate).getTime() : null,
            endData: inputs.endDate ? new Date(inputs.endDate).getTime() : null,
            locationId: inputs.jobLocation?.location_id,
            jobId: inputs.job?.id
        }
        setInputData(inputs)
        const queryParams = new URLSearchParams(data).toString();
        await API.get(`jobAppliedLists?${queryParams}`).then(res => {
            setAppliedJobs(res.data.data.appliedJobs)
        }).catch(err =>{
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            });
        })
    }

    const getLocationData = async ()=>{
        await API.get('jobAppliedData').then(res=>{
            setLocations(res?.data?.data?.valet_jobs);
        }).catch(err =>{
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            });
        })
    }
    const jobStatusUpdate =  async (job)=>{
        await API.put('jobStatusUpdate',job).then(res=>{
            onSubmit(inputData);
        }).catch(err =>{
            setSnackBar({
                open: true,
                message: err?.response?.data?.msg,
                type: "error",
            });
        })
    }

    useEffect(()=>{
        getLocationData();
    },[])
    
    return (
        <>
            <ContainerHeader title={`Job Histories`} />

            <div className="row">
            <CardBox styleName="col-12">
            {
                locations && locations.length > 0?
                <FormFilter 
                    locations={locations}
                    onFormSubmit={onSubmit}
                    formSubmitText={'Get Details'}
                    flags = {{
                        "location": false,
                        "type": false,
                        "noMinDate": true,
                        "showDate": false,
                        "amount":false,
                        "reason":false,
                        "clients":false,
                        "selectDate":false,
                        "jobs": true,
                        "date": true,
                        "jobLocation": true,
                        "multiLocation": false,
                        "resetForm" : true
                    }}
                />:<></>
            }     
            </CardBox>
        </div>

        {  
            appliedJobs && appliedJobs.body.length > 0 ?
                <div className="row">
                    <ActionsTable
						tableHeading={'Active Users'}
						table={appliedJobs}
						actions={true}
                        statusUpdate={jobStatusUpdate}
					/>
                </div>
                : 
                appliedJobs && appliedJobs.body.length === 0 &&
                <Alert className="shadow-lg" color="primary">
                     No data for this period !</Alert>
        }
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
        </>
    )

}

export default JobHistory;