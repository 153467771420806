import React from "react";
import { Route, Switch } from "react-router-dom";

import EZTvAds from "./EZTvAds"

const EZAds = ({ match }) => (
    <div className="app-wrapper">
      <Switch>
          <Route path={`${match.url}/client-ad`} component={EZTvAds}/>
      </Switch>
    </div>
  );
  
  export default EZAds;