import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography, Slide } from '@material-ui/core';
import { Formik, Form, connect, useFormikContext } from "formik";
import FormField from "components/FormField";
import ImgField from "./ImgField"
import CardBox from "components/CardBox";
import SnackBar from "components/SnackBar";
import ContainerHeader from "components/ContainerHeader";
import ActionsTable from "components/ActionsTable/TableList";
import API from "../../../../util/Api"
import moment from "moment";
import _ from "lodash";
import * as Yup from "yup";
import CreateVendor from "./CreateVendor";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getFieldValues = (fields) => {
    const values = Object.values(fields)?.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const FormConfig = connect((props)=> {
	const { isSubmitting } = useFormikContext();

	useEffect(() => {
		if (props.ezAd) {
		let data = {};
		for (let key in props.ezAd) {
			if(key != 'current_images'){
				data[key] = props.ezAd[key].value;
			}
		}
		if (!_.isEqual(data, props.formik.values)) {
			props.formik.setValues(data);
		}
		}
		if (props.ezAd === null) {
		props.formik.resetForm();
		}
	}, [props.ezAd]);


	return (
		<div className="row">
			<CardBox styleName="col-12" heading="">
				<Form>
					<div className="row">
						{Object.values(props.config.fields)?.map((field) => (	 
							<div className="col-md-3 col-12" key={props.key} >
								{field.type !== 'file' ?
									<FormField field={{ ...field, ...{ name: field.key } }} />
								: 
									<ImgField field={{...field, ...{name: field.key}, ...field.formik, ...{current_images: props.ezAd ? props.ezAd.current_images : []} }}  />
								}
							</div>
						))}
					</div>
					{/* BUTTONS */}
					<div className="row mt-2">
						<div className="col-lg-6 col-sm-12 col-12">
							<Box mt="20px">
								<Box mr="10px" display="inline">
									<Button
										variant="contained"
										color="primary"
										mr="5px"
										type="submit"
										disabled={isSubmitting}
									>
										{" "}
										Submit{" "}
									</Button>
								</Box>
								<Box mr="10px" display="inline">
									<Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting} >
										Reset
									</Button>
								</Box>
							</Box>
						</div>
					</div>
				</Form>
			</CardBox>
		</div>
	);
});

const VendorDialog = ({ formRef, config, initialValues, handleSubmit }) => {
    return (
        <CreateVendor
            ref={formRef}
            config={config}
            initialValues={initialValues}
			handleSubmit={handleSubmit}
        />
    );
};

const ImageDialog = (images) => {
	return ( images.length > 0 ?
		<div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))", gap: "16px", padding: "16px" }} >
			{images.map((image, index) => (
				<div key={index} style={{ position: "relative", width:'100%', height:'100%'}}>
					<img
						src={image.url}
						alt={`Preview ${index + 1}`}
						style={{
							width: "100%",
							height:'200px',
							// maxHeight:'200px',
							borderRadius: "8px",
							border: "1px solid #ccc",
						}}
					/>
				</div>
			))}
		</div>
	:
		<div style={{ display: "flex", flexDirection:'row', justifyContent:'center'}}>
			<Typography>No Image found!</Typography>
		</div>
	);
}

const EZTvAds = (props) => {
	const [config, setConfig] = useState();
	const [ezAd, setEzAd] = useState(null);
  	const [data, setData] = useState();
	const [initialValues, setInitialValues] = useState();
	const [vendorConfig, setVendorConfig] = useState();
	const [initialVendorValues, setInitialVendorValues] = useState();


	const [snackBar, setSnackBar] = useState({
		open: false,
		msg: "",
		type: "error",
	});
	const [dialog, setDialog] = useState({open:false, fullWidth:true, content:null, header:'', agreeBtnTxt:'', disAgreeBtnTxt:'', disableSubmit:false, type:'', callback:null});
	

	useEffect(() => {
		API.get("eztvAds")
		.then((res) => {
			console.log("ezAds_apiResponse", res);
			const values = getFieldValues(res.data.data.config.fields);
			const vendorValues = getFieldValues(res.data.data.vendorConfig.fields);

            setInitialValues(values);
			setConfig(res.data.data.config);
			setData(res.data.data.eztv_ads);

			setInitialVendorValues(vendorValues)
			setVendorConfig(res.data.data.vendorConfig);
			console.log("ezAds_apiResponse_table", res.data.data.eztv_ads);
		})
		.catch((err) => {
			// got an error
		});
	},[]);


	const validations = Yup.object().shape({
		name: Yup.string().required("Required"),
		description: Yup.string().required("Required"),
		parking_loc_id: Yup.object().required("Required"),
		vendor_id: Yup.object().required("Required"),
		start: Yup.date().required("Required"),
		end: Yup.date().required("Required"),
		interval: Yup.string().required("Required")
	});

	const handleVendorSubmit = (data, form ) => {
		setDialog((prevState) => ({...prevState, disableSubmit:true}))

		API.post("newVendorCreate", JSON.stringify(data))
		.then((res) => {
			setConfig((prevState) => {
				const newState = _.cloneDeep(prevState);
				_.set(newState, "fields.vendor_id.options", res.data.data.vendorList);
				return newState;
			});
			form.setSubmitting(false);
			form.resetForm();
			setSnackBar({ open: true, msg: res.data.msg, type: "success" });
			setDialog((prevState) => ({...prevState,  open:false, disableSubmit:false}));
		})
		.catch((err) => {
			form.setSubmitting(false);
			setSnackBar({
				open: true,
				msg: err?.response?.data?.msg ? err.response.data.msg : "Someting went wrong",
				type: "error",
			});
			setDialog((prevState) => ({...prevState, disableSubmit:false}))
		});
	}

	const handleEdit = (item) => {
		setEzAd(null);

		const tempItem = _.cloneDeep(item);

		tempItem.start.value = tempItem.start.value ? moment(tempItem.start.value, 'DD/MM/YY').utc().set({ hour: 18, minute: 30, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null; 
		tempItem.end.value = tempItem.end.value ? moment(tempItem.end.value, 'DD/MM/YY').utc().set({ hour: 18, minute: 30, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null;
		tempItem.current_images = tempItem.images.value;
		tempItem.images.value = [];

		setEzAd(tempItem);

		console.log("edit_eztvads_______", tempItem, initialValues,);

		document.body.style.height = "auto";
		setTimeout(function () {
			document.body.style.height = "100%";
		}, 50);		
	};

	const ShowImages = (item) => {
		setDialog({open:true, fullWidth:true, header:`${item.name.value.toUpperCase()} IMAGES`, submitBtnLabel:'', type:'showImages', callback:null, content: ImageDialog(item.images.value)})
	}

	
	const AddNewVendor = (config, initialValues) => {
		const formRef = React.createRef();
		setDialog({open:true, fullWidth:true, header:'ADD NEW VENDOR', submitBtnLabel:'Submit', type:'newVendor', callback:null, 
			formRef, content: <VendorDialog formRef={formRef} config={config} initialValues={initialValues} handleSubmit={handleVendorSubmit} />
		})
	}

	const handleSubmit = (data, form) => {
		data.start = data.start ? moment(data.start).utc().set({ hour: 18, minute: 30, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null; 
		data.end = data.end ? moment(data.end).utc().set({ hour: 18, minute: 30, second: 0, millisecond: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null; 
		
		const formData = new FormData();

		// convert data into formData
		Object.keys(data).forEach((key) => {
			if (Array.isArray(data[key])) {
				data[key].forEach((value, index) => {
					formData.append(`${key}[]`, value);
				});
			} else if (typeof data[key] === "object" && data[key] !== null) {
				formData.append(key, data[key].value);
			} else {
				formData.append(key, data[key]);
			}
		});

		API.post("eztvAds", formData)
		.then((res) => {
			form.setSubmitting(false);
			form.resetForm();
			setData(res.data.data.eztv_ads);
		}).catch((err) => {
			setSnackBar(prevState => ({...prevState,
				open: true,
				msg: err.response?.data?.msg ? err.response.data.msg  : 'Something went wrong',
				type: "error"
			}));
			form.setSubmitting(false);
			console.log("catch_____", err, err?.response?.data?.msg);
		});
	};


	const handleReset = (data, form) => {
		setEzAd(null);
	};



	const ProgressBar = connect(({ formik }) => {
		return ( formik.isSubmitting ? 
			<div className="col-12">
				<LinearProgress color="primary" />
				<LinearProgress color="primary" />
			</div>
		: <></> );
	});

	const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            type:'',
            location:null,
            callback:null
        });
    }


	return (
		<div>
			<ContainerHeader title={`EZ TV Ads`} match={props.match} />
			{/* <CircularProgress /> */}
			{config && (
				<Formik
					onSubmit={handleSubmit}
					onReset={handleReset}
					enableReinitialize 
					initialValues={initialValues}
					validationSchema={validations}
				>
					<>
						<FormConfig config={config} ezAd={ezAd} />
						<ProgressBar />
					</>
				</Formik>
			)}
			{config && <div className="row">
				<Box ml="15px" mb="25px">
					<Button variant="contained" color="primary" onClick={()=> AddNewVendor(vendorConfig, initialVendorValues) } > 
						Create New Vendor
					</Button>
				</Box>
				{data && (
					<ActionsTable
						tableHeading={data.title}
						table={data}
						onEdit={handleEdit}
						handleImageDialog={ShowImages}
						actions={true}
					/>
				)}
			</div>}
			<SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar(prevState => ({...prevState, open: false, msg: "" }));
                }}
                message={snackBar.msg}
                type={snackBar.type}
            />
			<Dialog
				open={dialog.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth='md'
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<LinearProgress style={{visibility:props.showProgress ? 'visible' : 'hidden'}} color="primary" />
				<DialogTitle id="alert-dialog-slide-title" sx={{ borderBottom: "1px solid red" }} style={{ textAlign: "center" }}>{dialog.header}</DialogTitle>
				<DialogContent>
					{dialog.content}
				</DialogContent>
				<DialogActions>
					{dialog.submitBtnLabel && 
						<Button variant="outlined"  color="primary" onClick={() => { dialog.formRef.current.submitForm()}} disabled={dialog.disableSubmit} > {dialog.submitBtnLabel} </Button>}						
					<Button variant="outlined"  color="secondary" onClick={handleDialogClose}> Close</Button>
				</DialogActions>
			</Dialog>
		
		</div>
	);
}

export default EZTvAds;