import React,{useEffect,useState} from 'react'
import { Formik,Form,connect, useFormikContext } from 'formik'
import { Button,Box } from '@material-ui/core'
import FormField from 'components/FormField'
import CardBox from 'components/CardBox';
import _ from "lodash";

const CheckinAppUserConfig = (props) => {

    const { isSubmitting } = useFormikContext();
    const [optionList,setOptionList] = useState({})
    const [userChanged,setUserChanged] = useState(false)
    // let userChanged =false
    // let optionList = {};
    const getUser = () => {
        let data ={}
        for(let key in props.user){
            data[key]  = props.user[key].value
        }
        console.log("new values",data)
        console.log("old values",props.formik.values)
        return ({isSame:_.isEqual(data,props.formik.values),data:data})
    }
    useEffect(()=>{
        if(props.user){            
            let user= getUser()
            if(!user.isSame){
                props.formik.setValues(user.data)                                
            }
        }                
    },[props.user]);

    const getLocOptions = (key) => {        
        var completeOptions = []
        var locID = props.formik.values.location_id
        if(locID){
            var allLocationData = _.find(config,{key:"location_id"})
            var options = _.find(allLocationData.options,{value:locID.value})[key]            
            var allOptions = _.find(config,{key:key})        
            completeOptions = allOptions.options.filter( o => options?.includes(o.value))
        }        
        console.log('getLocOptions- ',key," - ",completeOptions);
        return completeOptions;
    }

    const getVehicleOptions = (key) => {
        var locID = props.formik.values.location_id
        var vehIDs = props.formik.values.vehicle_type
        var options = []
        if(locID && vehIDs.length){
            var allData = _.find(config,{key:key}).options
            options = allData.filter(data => ((data.location_id === locID.value || !data.location_id) && (vehIDs.includes(data.vehicle_type_id || data.value))))
            if(key === 'default_view')
                options.push({'title':'previous','value':'previous'})
        }        
        return options;
    }
    const addHeaderInRates = ()=>{
        //adding header in rate models
        let selectedVehicleTypeIds = props.formik.values.vehicle_type;
        var selectedVehicleTypes = _.filter(optionList.vehicle_type,o=>selectedVehicleTypeIds.includes(o.value))
        var filteredrateModels = optionList['rate_model'];
        var rateModels = [];
        for(var i = 0; i<selectedVehicleTypes.length;i++){

            rateModels.push({value:'',title:(selectedVehicleTypes[i].title + ' Type').toUpperCase(),disabled:true});
            for(var j=0;j<filteredrateModels.length;j++){
                if(selectedVehicleTypes[i].value==filteredrateModels[j].vehicle_type_id){
                    rateModels.push(filteredrateModels[j]);
                }
            }

        }
        return rateModels;
    }

    var config = props.config;
    //console.log("config - ",config);
    //setting options for vehicle type on selct of location
    useEffect(()=>{
        // console.log("change in lcoation");
        let user = getUser();
        let keys = ["vehicle_type"]
        let newOptionList = optionList
        let values = props.formik.values;
        keys.map(key => {
            newOptionList[key] = getLocOptions(key)

            if(!user.isSame){
                props.formik.setFieldValue(key,'')
            }else{
                props.formik.setFieldValue(key,props.formik.values[key]);
            }
            
        });
        setOptionList(newOptionList)
        
    },[props.formik.values.location_id]);

    useEffect(()=>{
        // console.log("change in vehicle type");
        let user = getUser();
        console.log("change based on vehicleelse")
            let keys = ["rate_model"];
            let newOptionList = optionList
            keys.map(key => {
                newOptionList[key] = getVehicleOptions(key)
                if(!user.isSame ){
                    props.formik.setFieldValue(key,'')
                }else{
                    props.formik.setFieldValue(key,props.formik.values[key])
                }
            });
            var newRateModelOptions = addHeaderInRates();
            newOptionList['rate_model'] = newRateModelOptions
            setOptionList(newOptionList)
        
            
        },[props.formik.values.vehicle_type]);

        useEffect(()=>{
            if(props.resetFormik){
                props.formik.resetForm();
                props.setFalse();
            }
        },[props.resetFormik])
        
    return(
        <>
        { Object.keys(config).length >0 &&
            <CardBox styleName="col-12" heading='Checkin App User Config'>
            
                <Form>
                    {/* FIRST ROW */}            
                    <div className="row">
                        { config.map(field => {
                            return(
                                <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                    <FormField  
                                    field={{
                                        ...field,
                                        ...{name:field.key},
                                        ...{options: optionList[field.key] || field.options },
                                        ...{disabled: (!optionList[field.key] || optionList[field.key].length) ? false : true }
                                    }}/>
                                </div>
                            )
                        })
                        }                 
                    </div>
                    {/* BUTTONS */}
                    <div className="col-lg-6 col-sm-12 col-12">                    
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" disabled={isSubmitting} > Submit </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting} >Reset</Button></Box>
                        </Box>
                    </div>
                </Form>
            </CardBox>
        }
        </>
    )
}

export default connect(CheckinAppUserConfig)