import React, { useState, useEffect } from "react";
// import {getReports} from './data';
import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import API from "util/Api";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetClientsByLocation from "./GetClientsByLocation";
import { Typography } from "@material-ui/core";
import UploadSubscription from "./UploadSubscription";
import _ from "lodash";
import { saveAs } from "file-saver";
import SnackBar from "components/SnackBar";

const EVSubscriptions = (props) => {
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  console.log("create-ev-subsc-props", props);
  const dwnloadSamples = (key) => {
    var data = {
      location_id: selectedLoc.id,
      subscription_type: key,
    };
    API.get("subscriptionSampleExcel?data=" + JSON.stringify(data))
      .then((res) => {
        saveAs(res.data.data);
        // received data
        console.log("rcvd data", res.data.data);
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          msg: err?.response?.data?.msg?err.response.data.msg:"Something went wrong",
          type: "error",
        });
      });
  };
  const onSubmit = (loc) => {
    if (loc) {
      setSelectedLoc(_.find(props.data, { id: loc.id }));
    } else {
      setSnackBar({ open: true, msg: "Select Location", type: "error" });
    }
    console.log("onSubmit-clicked", loc);
  };
  const reset = () => {
    setSelectedLoc(null);
  };

  return (
    <>
      <ContainerHeader title="Create EV Subscriptions" match={props.match} />
      <div className="row">
        <CardBox styleName="col-12">
          <GetClientsByLocation
            reset={reset}
            data={props.data}
            onSubmit={onSubmit}
          />
        </CardBox>
        {selectedLoc && (
          <CardBox styleName="col-12">
            <>
              <Typography variant={"h5"} style={{ marginBottom: 25 }}>
                {selectedLoc.location_name}
              </Typography>
              <UploadSubscription
                selectedLoc={selectedLoc}
                dwnloadSample={dwnloadSamples}
                renderSnack={(snack) => {
                  setSnackBar(snack);
                }}
              />
            </>
          </CardBox>
        )}
        <SnackBar
          open={snackBar.open}
          handleClose={() => {
            setSnackBar({ open: false, msg: "" });
          }}
          message={snackBar.msg}
          type={snackBar.type}
        />
      </div>
    </>
  );
};

const CreateEVSubscriptions = (props) => {
  const [appState, setAppState] = useState("loading");
  const [data, setData] = useState([]);
  useEffect(() => {
    API.get("chargingLocations")
      .then((res) => {
        setAppState("active");
        setData(res.data.data);
        // received data
        console.log("rcvd data", res.data.data);
      })
      .catch((err) => {
        // got an error
      });
  }, []);
  switch (appState) {
    case "loading":
    default:
      return (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="secondary" size={50} />
        </div>
      );
    case "active":
      return (
        <EVSubscriptions
          match={props.match}
          data={data}
          setAppState={setAppState}
        />
      );
  }
};
export default CreateEVSubscriptions;
