import React, { useState, useEffect, Fragment } from "react";
import { Formik, Form, connect, useFormikContext } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";

const ChargingLocationSection = (props) => {
  const { isSubmitting } = useFormikContext();
  return (
    <CardBox styleName="col-12" heading={props.section.title}>
      <Form>
        {/* FIRST ROW */}
        <div className="row">
          {props.section.fields.map((field) => (
            <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField field={{ ...field, ...{ name: field.key } }} />
            </div>
          ))}
        </div>
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {" "}
                  Submit{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting} >
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
  );
};
const ChargingLocationDetails = (props) => {
  useEffect(() => {
    if (props.location) {
      let data = {};
      for (let key in props.location) {
        data[key] = props.location[key].value;
      }
      if (!_.isEqual(data, props.formik.values)) {
        props.formik.setValues(data);
      }
    }
    if (props.location === null) {
      props.formik.resetForm();
    }
  }, [props.location]);
  return (
    <>
      {props.config &&
        Object.keys(props.config).length > 0 &&
        props.config.map((section, index) => (
          <ChargingLocationSection section={section} key={`section-${index}`} />
        ))}
    </>
  );
};
export default connect(ChargingLocationDetails);
