import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik, useFormikContext } from "formik";
import SnackBar from "components/SnackBar";
import { Form } from "formik";
import { Button, Box, Grid, TextField, FormControl } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import SimpleTable from "components/ActionsTable/SimpleTable";
import { DialogBox } from "components/PopUpDialog";

const UpdateDialog = (props) => {
    console.log('UpdateDialog-props', props);
    const [data, setData] = useState({});

    useEffect(() => {
        setData(props.dialog.data);
    }, [props.dialog])

    return (
        <DialogBox open={props.dialog.open} header="Update App" onClose={() => { props.setDialog({ open: false }) }} onSubmit={() => { props.onSubmit(data) }} >
            <Grid container>
                <Grid item xs={6}>
                    <FormControl className="w-100">
                        <TextField
                            id="standard-basic"
                            label="New version to be updated"
                            name="version"
                            value={data?.version}
                            onChange={(e) => {
                                setData({ ...data, ['version']: e.target.value });
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </DialogBox>
    )
}

const FilterForm = (props) => {
    const { isSubmitting } = useFormikContext();
    console.log('FilterForm-props', props);

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {props.fields?.map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting} >
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
}

const DeviceUsers = (props) => {
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [devices, setDevices] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });
    const [dialog, setDialog] = useState({
        open: false,
        data: {}
    })
    const validations = Yup.object().shape({
        // location: Yup.object().required("Required"),
    });

    const getFieldValues = (fields) => {
        const values = fields.reduce((obj, item) => {
            return { ...obj, [item.key]: item.value };
        }, {});
        return values;
    }

    useEffect(() => {
        API.get("deviceUsers")
            .then((res) => {
                const values = getFieldValues(res.data.data.fields);
                setInitialValues(values);
                setConfig(res.data.data);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("deviceUsers", JSON.stringify(data))
            .then((res) => {
                setDevices(res.data.data);
                form.setSubmitting(false);
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    const handleDialogSubmit = (data) => {
        API.post("updateApp", JSON.stringify(data))
            .then((res) => {
                setDialog({ open: false })
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }

    return (
        config && initialValues &&
        <>
            <ContainerHeader title={config.title} match={props.match} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validations}
            >
                <FilterForm fields={config.fields} />
            </Formik>
            {devices &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <SimpleTable
                            type={'deviceUsers'}
                            tableHeading={devices.title}
                            table={devices}
                            updateApp={(row) => { setDialog({ open: true, data: row }); }}
                            actions={true}
                        />
                    </CardBox>
                </div>
            }
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false, message: "" });
                }}
            />
            {dialog && <UpdateDialog dialog={dialog} setDialog={setDialog} onSubmit={handleDialogSubmit} />}
        </>
    )
}
export default DeviceUsers;