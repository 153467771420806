import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import IntlMessages from 'util/IntlMessages';
import API from 'util/Api';
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ImageDialog from 'components/ReportTable/imageDialog'
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CardBox from 'components/CardBox';
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import SearchBox from 'components/ActionsTable/SearchBox';
import { Formik, Form } from "formik";
import FormField from "components/FormField";
import * as Yup from "yup";
import moment from "moment";
import { Alert } from "reactstrap";

class ViewRequests extends React.Component {
  constructor(props) {
      super(props);
      this.state={
            pendingTable: [],
            completedTable: [],
            errorMessage: '',
            appState:'loading',
            open:false,
            image:[],
            message:'',
            action:'',
            confirmAction:false,
            actionHeading:'',
            selectedParking:0,

      } 
      this.handleConfirmAction = this.handleConfirmAction.bind(this)
      this.handleFreeAction = this.handleFreeAction.bind(this)
      this.handleImageDialog = this.handleImageDialog.bind(this)
      this.handleImageClose = this.handleImageClose.bind(this)
      this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount(){
        API.get('freeReqConfig')
        .then((res) => {     
            this.setState({
              config: res.data.data,
              errorMessage: "",
              appState: "active",
            });
        })
        .catch((err) => {
            this.setState({errorMessage: "Please Try Again",appState : 'active'});
        })
        // API.get('freeRequests')
        // .then((res) => {
        //     // received data  
        //     var tableData =  {
        //         pendingTable:res.data.data.pending,
        //         completedTable: res.data.data.completed
        //     }     
        //     this.setState({ pendingTable: res.data.data.pending,completedTable: res.data.data.completed, errorMessage: "",appState : 'active',tableData:tableData})
        // })
        // .catch((err) => {
        //     this.setState({errorMessage: "Please Try Again",appState : 'active'});
        // })
    }
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    handleImageDialog(bills){
      this.setState({open:true,image:bills.images})
    }
    handleConfirmAction(){
      var params = {'id':this.state.selectedParking,'status':this.state.action};
      this.setState({appState:'waiting', errorMessage: ""})
      API.post('freeRequests', JSON.stringify(params))
        .then((res)=> {
            this.setState({'message':res.data.msg.msg,appState:'active'})
            if(!res.data.error){
              var self = this;
              API.get('freeRequests')
                .then((res) => {
                    // received data        
                    this.setState({ pendingTable: res.data.data.pending,completedTable: res.data.data.completed, errorMessage: "",appState : 'active'})
                
                })
                .catch((err) => {
                    this.setState({errorMessage: "Please Try Again",appState : 'active'});
                })
                this.setState({'confirmAction':false})

            } else {
                this.setState({errorMessage: "Please Try Again",appState : 'active'});

            }
          })
        .catch((err)=> {
            this.setState({errorMessage: "Please Try Again",appState : 'active'});


        })
    }
    handleFreeAction(id,action){
      var message = 'Are you sure you want to '+action+' free request?';  
      this.setState({'action': action,'actionHeading':message,selectedParking:id,'confirmAction':true});
    }  
  handleImageClose(){
    this.setState({'open':false,'image':[]})
  }
  handleSearch=(check,searchValue)=>{
      console.log("handleSearch",check,searchValue);
      if(searchValue){
        var filter = [];
        this.state.tableData[check].map(o=>{
            console.log("loopkey",o);
            for(var key in o){
                
                if(!key.includes('hidden') && o[key] !=null && !Array.isArray(o[key]) && o[key].toString().toLowerCase().includes(searchValue.toLowerCase())){
                    filter.push(o);
                    break;
                }
            }
        });
        console.log("filterdedArray",filter)
        this.setState({[check]:filter});
    }else{
        this.setState({[check]:this.state.tableData[check]});
    }
  }
  freeRequests(data,type) {

      return(
          data && data.length>0 ? 
          <div className="table-responsive-material">
            <SearchBox handleSearch={this.handleSearch.bind(this,type)} style={{margin:12}} />
              <Table size='small'>
                  <TableHead>
                      <TableRow>
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='name-header'>Name</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='vehicleNo-header'>Vehicle No</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='vehicleType-header'>Vehicle Type</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='entryTime-header'>Entry Time</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='reason-header'>Reason</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='bill-header'>Bill</TableCell>                          
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='time-header'>{type === 'pending' ? 'Requested At' : 'Approved At'}</TableCell>                            
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='action-header'>{type === 'pending' ? 'Action' : 'Approved By'}</TableCell>    
                          <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='bill-header'>Comment</TableCell>                             
                          {type === 'pendingTable' && <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}} key='status-header'>Status</TableCell>}  
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {data.map((n,index) => {
                          return(<TableRow key={n.id}>
                          <TableCell key='name'>{n.name}</TableCell>                          
                          <TableCell key='vehicleNo'>{n.vehicleNo}</TableCell>
                          <TableCell key='vehicleType'>{n.type}</TableCell>
                          <TableCell key='entryTime'>{n.entryTime}</TableCell>
                          <TableCell key='reason'>{n.description+' '+n.comments}</TableCell>
                            <TableCell key='bill'>
                                <div>
                                {n.images.length > 0 ? 
                                    <Button  onClick={() => {this.handleImageDialog(n)}}  variant="contained" className="jr-btn bg-light-green text-white">
                                        <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                        <span>View</span>
                                    </Button>    
                                    : <Button  disabled  variant="contained" className="jr-btn bg-light-green text-white">
                                    <span>No Bills</span>
                                </Button>}                            
                                </div>
                            </TableCell>
                            <TableCell key='time'>{type === 'pendingTable' ? n.createdOn : n.updatedOn}</TableCell>                            
                            <TableCell key='action'>{type === 'pendingTable' ? 
                              <div>
                                <Button  onClick={() => {this.handleFreeAction(n.id,'accept')}}  variant="contained" className="jr-btn bg-light-green text-white">
                                    <span>Accept</span>
                                </Button>    
                                   <Button  onClick={() => {this.handleFreeAction(n.id,'reject')}}  variant="contained" className="jr-btn text-white" color="primary">
                                    <span>Reject</span>
                                </Button>
                              </div> : n.approvedBy}</TableCell>       
                              <TableCell key='comment'>{n.comment ? n.comment : '-'}</TableCell>                     
                                {type === 'pendingTable' && <TableCell key='status'>{n.status}</TableCell>}  
                            
                          </TableRow>)                          
                      })}
                  </TableBody>
              </Table>
          </div>:
          <Alert className="shadow-lg" color="primary">
            No data for this period !</Alert>
      )
  }

  handleSubmit(values,e){
    console.log('submit',e)
    e.setSubmitting(true);
    API.get(`freeRequests?data=${JSON.stringify(values)}`)
    .then((res) => {
        // received data
        var tableData =  {
            pendingTable:res.data.data.pending,
            completedTable: res.data.data.completed
        }
        this.setState({ pendingTable: res.data.data.pending,completedTable: res.data.data.completed, errorMessage: "",appState : 'active',tableData:tableData})
        e.setSubmitting(false);
    })
    .catch((err) => {
        this.setState({errorMessage: "Please Try Again",appState : 'active'});
        e.setSubmitting(false);
    })
  }

  freeReqConfig(config){
    const initialValues = {
      ...config.values,
      ["start_date"]: moment(config.values.start_date),
      ["end_date"]: moment(config.values.end_date),
    };
    const validations = Yup.object().shape({
      start_date: Yup.string().required("Required"),
      end_date: Yup.string().required("Required"),
    });
    return (
      config && (
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
        >
          <div className="row">
            <CardBox styleName="col-12" heading="">
              <Form>
                <div className="row">
                  {Object.values(config.fields).map((field, index) => (
                    <div className="col-md-3 col-12">
                      <FormField field={{ ...field, ...{ name: field.key } }} />
                    </div>
                  ))}
                </div>
                {/* BUTTONS */}
                <div className="row mt-2">
                  <div className="col-lg-6 col-sm-12 col-12">
                    <Box mt="20px">
                      <Box mr="10px" display="inline">
                        <Button
                          variant="contained"
                          color="primary"
                          mr="5px"
                          type="submit"
                        >
                          {" "}
                          Submit{" "}
                        </Button>
                      </Box>
                      <Box mr="10px" display="inline">
                        <Button
                          variant="outlined"
                          color="secondary"
                          type="reset"
                        >
                          Reset
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Form>
            </CardBox>
          </div>
        </Formik>
      )
    );
  }
    renderPage() {
    return (
      <div>
        <ContainerHeader match={this.props.match} title={<IntlMessages id="dashboard.freeRequests"/>}/>
        {this.freeReqConfig(this.state.config)}
        <div>
        { this.state.errorMessage!=='' &&
                  <h3 className="row"> { this.state.errorMessage } </h3> }
        { this.state.message!=='' &&
                  <h3 className="row"> { this.state.message } </h3> }          
                {this.state.appState==='waiting' 
                ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                : this.state.tableData && <div className="row">
                <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
                    <React.Fragment>
                        {"Pending Requests"}
                        <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                        <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                    </React.Fragment>} >
                     {this.freeRequests(this.state.pendingTable,'pendingTable')}
                </CardBox>
                <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
                <React.Fragment>
                {"Completed Requests"}
                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                        <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                </React.Fragment>} >
                    {this.freeRequests(this.state.completedTable,'completedTable')}
                </CardBox>
                </div>
                }
                <ImageDialog open={this.state.open} image={this.state.image} handleImageClose={this.handleImageClose} />
                <Dialog open={this.state.confirmAction} onClose={() => {this.setState({'confirmAction':false})}}>
                    <DialogContent color="secondary">
                            <Typography variant={"body1"}> 
                                {this.state.actionHeading}
                            </Typography>                                                                                    
                    </DialogContent>
                    <DialogActions>
                        <Box display="inline-block">
                            <Button onClick={() => {this.handleConfirmAction()}}  variant="contained"  color="primary">
                                Yes
                            </Button>                                     
                        </Box>
                        <Box display="inline-block">
                            <Button onClick={() => {this.setState({'confirmAction':false})}} variant="outlined">
                                No
                            </Button>                                     
                        </Box>
                    </DialogActions>
                </Dialog>    
        </div>

      </div>
    );
  }
}

const mapStateToProps = function(state) {
    try {
        return {reportFlags : state.auth.authUser.freeRequests}
    } catch (err) {
    }
}
  
export default connect(mapStateToProps)(ViewRequests);