import React, { useState, useEffect } from 'react'
import {Typography} from '@material-ui/core'
import { Formik,Form } from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"


const CreateVendor = React.forwardRef(({ config, initialValues, handleSubmit }, ref) => {
    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
        description: Yup.string().required("Required")
    });

    return(
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validations}
            resetInitalValue={true}
            innerRef={ref}
        >
            <Form>
                <div className={"row"}>
                {Object.values(config.fields).map((field) => (
                    field &&
                    <div className="col-md-4 col-12" key={`field-${field.key}`}>
                        <FormField
                            field={{
                                ...field,
                                ...{ name: `${field.key}` },
                            }}
                        />
                    </div>
                 ))}
                </div>
            </Form> 
        </Formik>
    )
});

export default CreateVendor;