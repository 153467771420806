
import React,{useEffect,useState} from 'react'
import { Formik,Form,connect, useFormikContext } from 'formik'
import { Button,Box } from '@material-ui/core'
import FormField from 'components/FormField'
import CardBox from 'components/CardBox';
import _ from "lodash";

const ConfigField = (props) =>{
    let field = _.cloneDeep(props.field)
    let values = props.values
    console.log("ConfigField",props.field,' ---props.field-- ',props.field.key,' -----props.formik--- ',props.formik)
    if(field.dependency){
        let fieldValue = field.dependency ? values[field.dependency]:null
        console.log("dependencyValue",fieldValue)
        //condition when not to render the field
        if(!fieldValue  ||  !field.dependencyValue.includes(fieldValue) ){
            console.log("fieldMap--",field.value,'--',values,'--',field.key,'--',values[field.key],'--')
            let resetValue = field.value
            let currentValue = values[field.key]
            
            //reset the value if dependency do not match
            if(currentValue != resetValue){
                console.log("currentValue--",currentValue,'---',resetValue,'--condition-',currentValue != resetValue,'--key-- ',field.key)
                props.formik.setFieldValue(field.key,resetValue)
            } // check so that we donot enter a infinite loop
            
            
            return(null)
        }
    }
    
    return(
        <div className="col-md-3 col-12" key={`field-${props.field.key}`}>
            <FormField  
                field={{
                    ...field,
                    ...{name:field.key}
                }}
            />
        </div>
    )
}
const EZField = connect(ConfigField)

const ControllerDetails = (props) => {
    
    const { isSubmitting } = useFormikContext();
    const getController = () => {
        console.log("new values",props.controller)
        console.log("old values",props.formik.values)
        return ({isSame:_.isEqual(props.controller,props.formik.values),data:props.controller})
    }
    useEffect(()=>{
        if(props.controller){            
            let controller= getController()
            console.log("controller-useEffect",controller)
            if(!controller.isSame){
                props.formik.setValues(controller.data)                                
            }
        }                
    },[props.controller]);

    var config = props.config
    //console.log("config - ",config);
    //setting options for vehicle type,printer on selct of location

    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])
    console.log("config",config,props.formik)
    return(
        <>
        { Object.keys(config).length >0 &&
            <div className='row'>
                <CardBox styleName="col-12" heading={`${config.title}`}>
            
            <Form>
                {/* FIRST ROW */}            
                <div className="row">
                    { config.fields.map((field,index) => {
                            return(
                                <EZField
                                    key={`ky-${index}`}
                                    field={{
                                            ...field
                                        }}
                                    values={props.formik.values}
                                />                                   
                            )
                        })
                    }                 
                </div>
                {/* BUTTONS */}
                <div className="col-lg-6 col-sm-12 col-12">                    
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" disabled={isSubmitting} > Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset" disabled={isSubmitting} >Reset</Button></Box>
                    </Box>
                </div>
            </Form>
        </CardBox>
            </div>
        }
        </>
    )
}

export default connect(ControllerDetails)